/* eslint-disable */
import { Button, Input } from "antd";
import axios from "axios";
import { FC, useState, useEffect } from "react";
import fakePlaces from "./mockPlaces.json";
import "./styles.css";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface IProps {
  prefill: any;
  onChange: any;
  onChangeRestau: any;
}

const GooglePlaces: FC<IProps> = ({ onChange, prefill, onChangeRestau }) => {
  const [address, setAddress] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [placesArr, setPlacesArr] = useState<any>([]);
  const [mapView, setMapView] = useState<any>();
  const [view, setView] = useState(false);
  const [restListItem, setRestListItem] = useState(false);
  const { t } = useTranslation();

  function handleSelectRestaurant(id: string, restau?: any) {
    let idUtil = id;
    if (restau) {
      console.log("RESTAUUU", restau);
      idUtil = restau.place_id;
      // onChangeRestau(restau);
      axios
        .get(
          `https://proxy.dev.bookline.io/google_maps/maps/api/place/details/json?place_id=${idUtil}&key=AIzaSyCbZZmO5SFB8zZAVGZGb3FJantWYIRkqcM&fields=formatted_phone_number,formatted_address,name,current_opening_hours,formatted_phone_number,international_phone_number,opening_hours,secondary_opening_hours,website,geometry`
        )
        .then((res) => {
          let restauUtil = res.data.result;
          restauUtil["place_id"] = idUtil;
          // setPlacesArr(res.data.results);
          console.log("GMAPS PLACE Query", restauUtil);
          onChangeRestau(restauUtil);
        })
        .catch((err) => {
          // setPlacesArr(fakePlaces.results);

          console.log(err);
        });
    } else if (prefill?.google?.place_id !== id) {
      onChange(id);
    }

    console.log("Rest selected", idUtil);
    setView(true);

    setSelectedId(idUtil);

    setMapView(
      <iframe
        width="500"
        height="420"
        frameBorder="0"
        style={{ border: "0" }}
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA6CgW7T-7cK71lgjhVPdnXPAIi0Jj1zNQ&q=place_id:${idUtil}`}
        allowFullScreen
      />
    );
  }

  function searchPlace() {
    // handleSelectRestaurant("ChIJaaVi3xejpBIR9UiVDlRgHjk");
    setMapView(
      <iframe
        width="500"
        height="420"
        frameBorder="0"
        style={{ border: "0" }}
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA6CgW7T-7cK71lgjhVPdnXPAIi0Jj1zNQ&q=place_id:ChIJaaVi3xejpBIR9UiVDlRgHjk`}
        allowFullScreen
      />
    );
    setView(true);
    setRestListItem(false);

    setSelectedId("");
    let formatedQuery = address.replaceAll(" ", "+");
    if (address) {
      axios
        .get(
          `https://proxy.dev.bookline.io/google_maps/maps/api/place/textsearch/json?query=${formatedQuery}&inputtype=textquery&location=41.3853922%2C2.1698379&key=AIzaSyCbZZmO5SFB8zZAVGZGb3FJantWYIRkqcM&fields=formatted_phone_number,formatted_address,name,type`
        )
        .then((res) => {
          console.log("GMAPS Query", res.data.results);
          setPlacesArr(res.data.results);
        })
        .catch((err) => {
          // setPlacesArr(fakePlaces.results);

          console.log(err);
        });
    }
  }

  useEffect(() => {
    console.log("carga el mapa", prefill);

    if (prefill && prefill?.google?.place_id) {
      handleSelectRestaurant(prefill.google.place_id);
      setRestListItem(true);
    } else {
      setView(false);
      setRestListItem(false);
    }
  }, [prefill]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input className="mapInputSearch" type="text" placeholder={t("rest_placeholder")} onBlur={(e: any) => setAddress(e.target.value)} onChange={(e) => setAddress(e.target.value)} onPressEnter={() => searchPlace()} />
        <Button className="buttonSearchMap" type="primary" onClick={() => searchPlace()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="35" viewBox="0 0 28 28" fill="none">
            <path
              d="M22.0965 20.1475L28 26.0496L26.0496 28L20.1475 22.0965C17.9514 23.857 15.2199 24.8145 12.4052 24.8105C5.55755 24.8105 0 19.2529 0 12.4052C0 5.55755 5.55755 0 12.4052 0C19.2529 0 24.8105 5.55755 24.8105 12.4052C24.8145 15.2199 23.857 17.9514 22.0965 20.1475ZM19.3315 19.1247C21.0808 17.3258 22.0577 14.9144 22.0538 12.4052C22.0538 7.07512 17.7354 2.75672 12.4052 2.75672C7.07512 2.75672 2.75672 7.07512 2.75672 12.4052C2.75672 17.7354 7.07512 22.0538 12.4052 22.0538C14.9144 22.0577 17.3258 21.0808 19.1247 19.3315L19.3315 19.1247Z"
              fill="white"
            />
          </svg>
        </Button>
        <script async src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCbZZmO5SFB8zZAVGZGb3FJantWYIRkqcM&libraries=visualization&callback=initMap"></script>
      </div>
      {view && (
        <div className="mapAndList">
          {placesArr.length !== 0 && (
            <div className="addressSelectDiv">
              {/* <h5>Seleccionar restaurante</h5> */}
              {placesArr.map((restau: any) => (
                <div className={`mapListItemContainer ${selectedId === restau.place_id ? "selectedItem" : ""}`} key={restau.place_id} onClick={() => handleSelectRestaurant(restau.place_id, restau)}>
                  {restau?.name && <div className="mapListItem itemName">{restau.name}</div>}
                  {restau?.formatted_address && <div className="itemAddress">{restau.formatted_address}</div>}
                </div>
              ))}
            </div>
          )}
          {restListItem && placesArr.length == 0 && (
            <div className="addressSelectDiv">
              <div className={`mapListItemContainer selectedItem`} key={prefill?.google?.place_id}>
                {prefill?.google?.name && <div className="mapListItem itemName">{prefill?.google?.name}</div>}
                {prefill?.google?.formatted_address && <div className="itemAddress">{prefill?.google?.formatted_address}</div>}
              </div>
            </div>
          )}
          <div className="mapContainer">
            {mapView}
            {mapView && (
              <p className="removeMap" onClick={() => onChange("")}>
                Eliminar
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GooglePlaces;

/* eslint-disable */

import { Button, Input, Radio, Space, RadioChangeEvent, Steps, message, theme } from "antd";
import coverLogo from "@doar/shared/images/integrations/coverManagarFromFigma.svg";
import restooLogo from "@doar/shared/images/integrations/restooLogo.svg";
import theForkLogo from "@doar/shared/images/integrations/theFork.svg";

import { FC, useEffect, useState } from "react";
import "../styles.css";

import GooglePlaces from "src/components/google-places";
import { useTranslation } from "react-i18next";

interface IProps {
  prefill?: any;
  handleNext?: any;
  handleBack?: any;
  handleChange?: any;
}

export const RestClient0: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [notInList, setNotInList] = useState(false);
  const [wannaAddMore, setWannaAddMore] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (prefill?.address || prefill?.name) {
      setNotInList(true);
    }
    if (prefill?.other_restaurants) {
      setWannaAddMore(true);
    }
  }, [prefill]);
  return (
    <div className="stepContainer">
      <h2 className="businessH2">{t("ask_restaurant")}</h2>
      <p className="pUnderTitle">{t("search_restaurant")}</p>
      <div className="restSearchContainer contentContainer" style={{ marginTop: "70px" }}>
        <GooglePlaces
          onChangeRestau={(e: any) => {
            let configUtil = JSON.parse(JSON.stringify(prefill));
            if (configUtil?.address) {
              delete configUtil.address;
            }
            if (configUtil?.name) {
              delete configUtil.name;
            }
            configUtil["google"] = e;
            configUtil["name"] = e?.name;
            configUtil["address"] = e?.formatted_address;
            configUtil["phone"] = e?.international_phone_number;

            handleChange(null, configUtil);
          }}
          prefill={prefill}
          onChange={(e: any) => {
            let configUtil = JSON.parse(JSON.stringify(prefill));
            configUtil["google"] = "";
            configUtil["name"] = "";
            configUtil["address"] = "";
            configUtil["phone"] = "";
            handleChange(null, configUtil);
          }}
        />

        <div>
          <p
            className="notInList"
            onClick={() => {
              let configUtil = JSON.parse(JSON.stringify(prefill));
              configUtil["google"] = "";
              configUtil["name"] = "";
              configUtil["address"] = "";
              configUtil["phone"] = "";
              handleChange(null, configUtil);
              handleNext("rest-client-0", "rest-client-0-bis");
            }}
          >
            {t("cant_find")}
          </p>
        </div>

        <div className="buttonsContainer">
          <div className="backButton">
            <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/ 2000/svg">
              <path
                d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
                fill="#333F64"
                stroke="#333F64"
                strokeWidth="1.15217"
              />
              <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
            </svg>
          </div>
          <div className="nextButton">
            <Button type="primary" disabled={!prefill?.google?.place_id && !prefill?.address} onClick={() => handleNext("rest-client-0", "rest-client-0-bis")}>
              {t("next")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export const RestClient0Bis: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [notInList, setNotInList] = useState(false);
  const [wannaAddMore, setWannaAddMore] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (prefill?.other_restaurants) {
      setWannaAddMore(true);
    }
  }, [prefill]);
  return (
    <div className="stepContainer">
      <h2 className="businessH2">
        {t("confirm_data")}
        {/* Confirma los datos del restaurante */}
      </h2>
      <p className="pUnderTitle">
        {t("verify")}
        {/* Verifica que toda la información sea correcta antes de continuar */}
      </p>
      <div className="restSearchContainer contentContainer">
        <div>
          <div>
            <strong>{t("name")}:</strong>
            <input className="notInListInput" type="text" placeholder="Nombre" value={prefill?.name} onChange={(e: any) => handleChange("name", e.target.value)} />
            <strong>{t("address")}:</strong>
            <input className="notInListInput" type="text" placeholder="Dirección" value={prefill?.address} onChange={(e: any) => handleChange("address", e.target.value)} />
            <strong>{t("tel")}:</strong>
            <input className="notInListInput" type="text" placeholder="Teléfono" value={prefill?.phone} onChange={(e: any) => handleChange("phone", e.target.value)} />
          </div>
        </div>
        <p className="notInList" onClick={() => setWannaAddMore(!wannaAddMore)}>
          {t("other_rests")}
          {/* ¿Tienes otros restaurantes que te gustaría añadir? */}
        </p>
        {wannaAddMore && (
          <div>
            <p>
              {" "}
              {t("add_others")}
              {/* Por favor escribe los nombres separados por una coma */}
            </p>
            <Input style={{ width: "50%", marginBottom: "50px" }} placeholder="Carlitos, Chalito, La tranquera" defaultValue={prefill?.other_restaurants} onBlur={(e: any) => handleChange("other_restaurants", e.target.value)} />
          </div>
        )}
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/ 2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button
            type="primary"
            disabled={!prefill?.address || !prefill?.name || !prefill?.phone}
            onClick={() => {
              handleNext("rest-client-0-bis", null);
            }}
          >
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestClient1: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("need", e.target.value);
  };

  useEffect(() => {
    setValue(prefill?.need);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Que opción se ajusta más a tu necesidad actual?</h2>
      <p className="pUnderTitle">Desesperados por obtener ayuda o simplemente explorando opciones, nos gustaría adaptar nuestra solución a sus necesidades de la mejor manera posible.</p>
      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Radio value="needNow">Lo necesito implementar cuanto antes</Radio>
          <Radio value="wannaTry">Quiero ver como funciona y valorar si me encaja</Radio>
          <Radio value="later">Quiero tenerlo para una fecha concreta pero no inmediata</Radio>
          {value === "later" && <Input className="otherRadioInput" placeholder="Dinos aproximadamente para cuando lo querrías" value={prefill?.want_for_later} onChange={(e: any) => handleChange("want_for_later", e.target.value)} />}
        </Space>
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.need || (prefill?.need === "later" && !prefill?.want_for_later)} onClick={() => handleNext("rest-client-1", "rest-client-2")}>
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestClient2: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("booking_manager", e.target.value);
  };
  useEffect(() => {
    setValue(prefill?.booking_manager);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">{t("ask_bm")}</h2>
      <p className="pUnderTitle">{t("ask_bm2")}</p>
      <div className="contentContainer">
        <div>
          <Radio.Group className="radioGroupStyle bookingManagerRadio" onChange={onChangeRadio} value={value}>
            <Space direction="vertical">
              <Radio value="cover">
                <img src={coverLogo} alt="Cover Manager" />
              </Radio>
              <Radio value="fork">
                <img height="50px" src={theForkLogo} alt="the Fork" />
              </Radio>
              <Radio value="restoo">
                <img height="30px" src={restooLogo} alt="Restoo" />
              </Radio>
              <Radio value="none">Ninguno</Radio>
              <Radio value="other">Otro</Radio>
              {value === "other" ? <Input className="otherRadioInput" placeholder="Dinos el nombre de tu libro de reservas" value={prefill?.other_booking_manager} onChange={(e: any) => handleChange("other_booking_manager", e.target.value)} /> : null}
            </Space>
          </Radio.Group>
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.booking_manager || (prefill?.booking_manager === "other" && !prefill?.other_booking_manager)} onClick={() => handleNext("rest-client-2", null)}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("selectedLanguage") || "es",
  fallbackLng: localStorage.getItem("selectedLanguage") || "es",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        delete_photo: "Delete photo",
        select_photo: "Select or drop photo to upload as a profile picture",
        industry: "Industry",
        address: "Address",
        description: "Description",
        about: "About",
        no_wa_linked: "No WhatsApp linked to this number",
        ready_for_onboarding: "Ready for 360Dialog setup",
        clients: "Clients",
        welcome_to_bookline: "Welcome to Bookline, ",
        assistant_setup_intro: "To ensure that the assistant is perfectly tailored and configured to your specific preferences and needs, we ask that you take a few minutes to complete this questionnaire.",
        assistant_setup_intro_continued: "We understand that you may be busy; so, if you can't finish it now, no problem. You can pause and resume the process where you left off at any time.",
        start: "Start",
        write_here: "Write here",
        ask_conf_notif: "Do you want to send a reservation confirmation to the customer?",
        yes_mkt: "Yes, with WhatsApp with bookline advertising",
        free: "free",
        yes_paid: "Yes, with WhatsApp without advertising",
        yes_sms: "Yes, with SMS",
        dont_want: "I don't want",
        anything_else: "Anything else you want to tell us?",
        anything_else2: "If you are not sure, you can indicate it later",
        ask_warranty: "Do you want to indicate the reservation guarantee time?",
        warranty_placeholder: "Indicate the warranty time in minutes",
        extra_q: "Do you want the assistant to ask or say something else?",
        extra_q2: "If you are not sure, you can indicate it later",
        alergies: "Any additional comments such as allergies, intolerances, or special requests you want to indicate?",
        extra_highchair: "If you are coming with children and need highchairs, please specify how many you need.",
        ask_how_know_us: "How did you hear about us?",
        ask_disca: "Is there anyone with reduced mobility coming?",
        extra_placeholder: "Indicate what else you want the assistant to say. You can add multiple phrases or questions.",
        ask_warranty_time: "Indicate the warranty time in minutes",
        ask_formality: "What formality do you prefer to use with the customer?",
        you: "You (informal)",
        you2: "You (formal)",
        assistant_title: "Now let's configure your assistant, choose the languages you want your assistant to speak",
        assistant_title2: "You can select the default language later",
        spanish: "Spanish",
        english: "English",
        catalan: "Catalan",
        french: "French",
        italian: "Italian",
        german: "German",
        portuguese: "Portuguese",
        lang_detection: "How do you want the language detection to be done?",
        by_prefix: "By customer's phone prefix",
        by_command: "Explicitly telling the assistant to speak in a certain language",
        default_lang: "Choose the default language of your assistant",
        default_lang2: "For this language, you can configure the type of voice",
        assistant_voice: "Choose your assistant's voice",
        assistant_voice2: "Customize the auditory experience for your customers",
        phone_placeholder: "Enter your phone number",

        ask_centralita: "Do you have a switchboard?",
        ask_centralita2: "Indicate if you have an integrated communication system, otherwise indicate your telephone company",
        ask_phone_provider: "Select your phone provider",
        ask_centralita_provider: "Select your provider",
        idk: "I don't know",
        when_answer: "When do you want the assistant to start?",
        ask_2nd_line: "Please provide a second line to transfer the call to the restaurant. If you do not have one, leave the box blank and an agent will contact you later to offer a solution.",
        always: "Always",
        if_no_answer: "If you do not answer at the restaurant",
        franjas: "Time slots",
        clinic: "Clinic",
        taxi: "Taxi station",
        club: "Sports club",
        select_b: "Select your business",
        ask_restaurant: "Which is your restaurant?",
        search_restaurant: "Let's find your restaurant",
        cant_find: "Can't find it?",
        rest_placeholder: "Write the name of your restaurant",

        confirm_data: "Confirm the restaurant data",
        verify: "Make sure all the information is correct before continuing",
        tel: "Phone",
        other_rests: "Do you have other restaurants you would like to add?",
        add_others: "Please write the names separated by a comma",
        ask_bm: "What booking system do you use?",
        ask_bm2: "Managing reservations is much easier with a dedicated platform. However, if you don't have one, it's not a problem, we know how to work without it!",
        title: "Multi-language app",
        venues: "Venues",
        diversion_instructions: "Diversion instructions",
        show_stats: "Show stats",
        edit_bot: "Edit bot",
        last_call: "Last call was",
        phone_settings: "Phone Settings",
        close: "Close",
        billing: "Billing",
        account: "Account",
        create_account: "Create account",
        create_new_account: "Create new account",
        legal_information: "Legal information",
        company_name: "Company name",
        tax_id: "Tax ID",
        partner_name: "Business partner's name",
        partner_id: "Business partner's ID",
        email: "Email",
        tax_address: "Tax address",
        city: "City",
        postal_code: "Postal code",
        payment_method: "Payment method",
        payment_type: "Payment type",
        direct_debit: "Direct debit",
        bank: "Bank",
        account_holder: "Account holder name",
        account_number: "Account number",
        currency: "Currency",
        update_payment_method: "Update payment method",
        payment_settings: "Set up your payment method by configuring Direct Debit through",
        cancel: "Cancel",
        field_mandatory: "All fields are required",
        not_valid_email: "Email not valid",
        team: "Team",
        invite_user: "Invite user",
        name: "Name",
        phone: "Phone number",
        role: "Role",
        copy_link: "Copy link",
        send: "Send",
        assistants: "Assistants",
        add_new_bot: "Add new bot",
        restaurant: "Restaurant",
        online: "Online",
        offline: "Offline",
        calls: "Calls",
        save: "Save",
        share: "Share",
        add_new_ticket: "Add new ticket",
        notifications: "Notifications",
        whats_description: "Mobile Messaging and Mobile Automation Solutions and API · WhatsApp Messaging",
        reservations: "Reservations",
        cover_description: "Automate your customer billing with a table payment system. Save time and increase your business's profitability.",
        restoo_description: "Your Smart Reservation Book. No commissions. Manage your reservations and plan services easily. Know the preferences of all your customers and automatically build their loyalty.",
        dentalink_description: "Dentalink is the ideal software for managing dental offices. It simplifies your daily tasks, strengthens the relationship with your patients, and increases your clinic's profitability. Say goodbye to paper!",
        colzent_description: "Comprehensive vehicle fleet management system with driver, reservation and service management, real-time geolocation of vehicles and services, voice services, assistance to drivers and/or clients, and much more...",
        golf_description: "Golfmanager is the only 100% cloud-based software that allows managing all operational needs of a golf club in a single program. Modern design and an unparalleled booking experience.",
        geolocation: "Geolocation",
        google_description: "Navigate the world more easily and quickly with Google Maps. There are maps of over 220 countries and territories with millions of businesses and places marked.",
        netelip_description: "IP telephony for businesses from netelip. Leading IP Voice or VoIP operator in Spain and Latin America with a wide range of solutions.",
        twilio_description: "Twilio powers personalized interactions and reliable global communications to connect you with customers.",
        sendgrid_description: "SendGrid delivers your transactional and marketing emails through the world's largest cloud-based email delivery platform. Send with confidence.",
        hubspot_description: "The HubSpot CRM platform includes all the tools and integrations you need for marketing, sales, content management, customer service, and operations. Each product is powerful on its own; together, they are unmatched.",
        select_customer: "Please select a customer",
        install: "Install",
        already_installed: "This is already installed",
        integrations: "Integrations",
        installed: "Installed",
        add_new_integration: "Add new integration",
        company: "Company",
        extra_data: "Extra data",
        user: "User",
        list: "List",
        new_notification: "New notification",
        frequency: "Frequency",
        general: "General",
        conversations: "Conversations",
        conversation: "Conversation",
        chats: "Chats",
        marketplace: "Marketplace",
        settings: "Settings",
        search_number: "Search for an exact number",
        show: "Show",
        previous: "Previous",
        page: "Page",
        of: "of",
        filter: "Filter",
        clean: "Clean",
        no_calls: "No calls to show",
        assistant: "Assistant",
        client: "Client",
        today: "Today",
        yesterday: "Yesterday",
        more: "More",
        no_number: "The number you are searching does not exist",
        next: "Next",
        no_chats: "No chats to show",
        select_whats: "Please select your WhatsApp Business account",
        send_whats: "Send a Whatsapp",
        call: "Call",
        search: "Search",
        help_support: "Help Support Center",
        search_help: "Search for any help questions or topics",
        getting_started: "Getting started",
        download: "Download",
        installation: "Installation",
        accessibility: "Accessibility",
        supported_devices: "Supported Devices",
        npm_installation: "NPM Installation",
        common_errors: "Common errors when intalling",
        npm_windows: "NPM for Windows",
        cannot_install: "Cannot install npm in mac",
        not_recognize: "NPM does not recognize in local",
        npm_version: "NPM version not showing when doing command",
        bootstrap_ins: "Bootstrap Installation",
        migrating: "Migrating from Bootstrap 3",
        webpack: "Bootstrap using Webpack",
        react_components: "Bootstrap react components",
        example_bootstrap: "Example of bootsrap page using navbar",
        sass_custom: "SASS Customization",
        min_sass: "Minimize sass to css without bootstrap",
        more_sass: "Adding more utilities classes in sass",
        pricing_billing: "Pricing & Billing",
        features: "Features",
        search_people: "Search people",
        help_center: "Help center",
        find_integrations: "Discover our integrations",
        preferences: "Preferences",
        language: "Language",
        select_or_drop: "Select or drop photo to upload",
        select_venue: "Select a venue",
        business_details: "Business Details",
        channel_setup: "Channels",
        subscriptions: "Subscriptions",
        active: "Active",
        phone_lines: "Phone lines",
        primary_line: "Primary Line",
        this_is_main_line: "This is your main restaurant phone line for customers to get directly in touch",
        number: "Number",
        provider: "Provider",
        type: "Type",
        bot_line: "Bot Line",
        number_asigned_bot: "This is the phone number assigned to the bot",
        handover_line: "Handover Line",
        handover_description: "This is the phone used in case the client wants to be transfered to the restaurant",
        label: "Label",
        call_routing: "Call Routing",
        call_routing_rule: "These are the call routing rules",
        last_call_was: "Last call was",
        transfer_to_bot: "Transfer to bot",
        call_assigned_bot: "Call is assigned to this bot",
        trigger: "Trigger",
        when_bot_activates: "When bot is triggered",
        try_it: "Try it",
        inactive: "Inactive",
        whatsapp_settings_description: "In order to have your assistant up and running, we need to set up your communication channels. Start setting it up here, or get in touch with your support agent if unsure.",
        copy_settings: "Copy settings",
        setup: "Set up",
        basic_info: "Basic info",
        info_from_google: "This information is taken from Google",
        website: "Website",
        timezone: "Timezone",
        booking_software: "Booking software",
        schedules: "Schedules",
        sync_google: "Sync with Google Maps",
        updates_at_1pm: "The schedule gets updated every day at 1pm.",
        closed: "Closed",
        same_every_day: "Same every day",
        apply_to_all: "Apply to all",
        add_slot: "Add slot",
        all_days: "All days",
        media: "Media",
        upload_pic: "Upload your restaurant’s photo and use it as a profile photo or the WhatsApp photo",
        use_profile: "Use as profile photo",
        use_whatsapp: "Use as WhatsApp photo",
        contact_channels: "Contact channels",
        info_send_notifications: "We use this information to send notifications",
        alternative_phone_number: "Alternative phone number",
        social_networks: "Social networks",
        news_email: "Receive newsletter via e-mail",
        marketing_whatsapp: "Receive bookline marketing messages via WhatsApp",
        advanced: "Advanced",
        personalized_exp: "Providing this extra info will help improve personalized experience",
        options_restaurant: "Select options that apply to your restaurant",
        food_tolerance: "Food prefference & tolerance",
        vegetarian_opt: "We offer vegetarian options",
        vegan_opt: "We offer vegan options",
        lactose_opt: "We offer lactose-free options",
        gluten_opt: "We have gluten-free options",
        parking: "Parking",
        own_parking: "Have own parking",
        parking_nearby: "Parking nearby",
        wallet_parking: "Have parking wallet",
        easy_parking: "Easy to park?",
        wheel_access: "We provide wheelchair access",
        pet_friendly: "We are pet-friendly",
        delivery: "Delivery",
        deliver_home: "We deliver at home",
        update: "Update",
        plan_name: "Plan name",
        voice_assistant: "Voice assistant",
        plan_cost: "Plan cost",
        date_activated: "Date activated",
        add_on: "Add-on",
        survey: "Survey",
        booking_reminder: "Booking Reminder",
        search_name: "Search a name",
        voice: "Voice",
        phone_number: "Phone number",
        edit: "Edit",
        add: "Add",
        call_to: "Call to",
        group_booking: "Group Booking",
        open: "Open",
        to: "to",
        type_number_here: "type number here",
        type_link_here: "type link here",
        day: "day",
        schedule: "schedule",
        voice_channel_setup: "Voice Channel Setup",
        phone_provider: "Phone provider",
        step1_description1: "To implement a voice bot seamlessly into your business operations, a crucial initial step involves establishing integration with your phone provider.",
        step1_description2: "If you can’t find your provider in the list or are unsure, we will figure it out for you.",
        select_phone_provider: "Select your phone provider",
        provider_no_centralita: "Some provider with no centralita option",
        other: "Other",
        dont_have_provider: "I don´t have a provider yet",
        use_centralita: "Do you use their centralita?",
        yes: "Yes",
        no: "No",
        unsure: "Unsure",
        provide_company_details: "Please provide company details",
        type_name: "type name",
        contact_person: "Contact person",
        contact_number: "Contact number",
        type_number: "type number",
        type_email: "type email",
        step2_description: "Route calls to the appropriate resource, whether it be a customer support representative, a specific department, or an automated system. ",
        when_trigger_bot: "When do you want the bot to be triggered?",
        when_unavailable: "When unavailable",
        when_line_busy: "When the line is busy or the restaurant doesn’t reply ",
        by_schedules: "By schedules",
        by_ivr: "After a selection on the current IVR",
        step3_description: "Route calls to the appropriate resource, whether it be a customer support representative, a specific department, or an automated system. ",
        where_clients_call: "Where do you want your clients to call you?",
        bot_number: " Bot number",
        recommended: "Recommended",
        restaurant_number: "Restaurant number",
        important: "Important!",
        further_setup: "This option might require further setup for the handover line.",
        another_number: "My own number",
        // another_number: "Another number",
        handover: "Handover",
        handover_step_description: "The call can’t be transferred back to the number client is calling. We need to deploy a different line to avoid creating endless loops",
        where_transfer: "If a client asks to, where do you want the call to be transferred?",
        different_number: "To a different number",
        add_destinations: "Add destinations",
        queue: "Queue",
        add_more: "Add more",
        never_transfer_calls: "Never transfer calls",
        some_provider: "Some provider",
        no_provider: "No provider",
        setup_summary: "Setup Summary",
        summary_step_description: "Once you submit you can still edit later on from the client portal. ",
        below_info_provided: "Below is a summary of the info you provided",
        setup_successful: "Setup successful",
        pending_tech_review: "Pending for tech team review.",
        tech_review_1: "A customer support agent will get in touch asap with next steps. If you have any doubts or questions, ",

        tech_review_description: "A customer support agent will get in touch when the voice channel is active.",
        centralita: "Centralita",
        direct: "Direct",
        go_to_portal: "Go to portal",
        all_channels: "All channels",
        pending: "Pending",
        accepted: "Accepted",
        rejected: "Rejected",
        archived: "Archived",
        booking: "Booking",
        requests: "Requests",
        all: "All",
        custom: "Custom",
        filter_by: "Filter by",
        channel: "Channel",
        status: "Status",
        request_type: "Request type",
        clear: "Clear",
        order_by: "Order by",
        booking_type: "Booking type",
        from: "From",
        pax: "Pax",
        time: "Time",
        received_on: "Received on",
        restaurant_name: "Restaurant name",
        client_details: "Client details",
        see_client_record: "See client record",
        request_details: "Request details",
        comments: "Comments",
        accept: "Accept",
        reject: "Reject",
        call_details: "Call details",
        summary: "Summary",
        timeline: "Timeline",
        transcript: "Transcript",
        call_start: "Call start",
        call_end: "Call end",
        call_duration: "Call duration",
        reason_identified: "Reason identified",
        booking_details: "Booking details",
        confirmation: "Confirmation",
        sent: "Sent",
        incoming_call: "Incoming call",
        call_ended: "Call ended",
        search_by_phone_number: "Search by phone number",
        in_review: "In review",
        cross_selling: "Cross-selling",
        reminders: "Reminders",
        products: "Products",
        add_new: "Add new",
        my_products: "My products",
        search_by_name: "Search assistant by name",
        booking_management: "Booking management",
        date: "Date",
        booking_confirmed: "Booking confirmed",
        main: "Main",
        add_ons: "Add-ons",
        collapse_all: "Collapse all",
        expand_all: "Expand all",
        personality: "Personality",
        personality_description: "Give it some personality with a name, traits and a face to it",
        avatar: "Avatar",
        avatar_description: "Choose an avatar photo for your assistant or upload yours",
        traits: "Traits",
        traits_description: "Choose a few personality traits you’d like your assistant to have",
        playful: "playful",
        serious: "serious",
        funny: "funny",
        friendly: "friendly",
        calm: "calm",
        sarcastic: "sarcastic",
        rude: "rude",
        kinky: "kinky",
        gentle: "gentle",
        sonofagun: "sonofagun",
        excited: "excited",
        languages_and_voice: "Languages & Voice",
        languages_description: "Make it speak in the languages you want and the voice you like",
        default_language: "default language",
        default_voice: "default voice",
        activate_language_detention: "Activate language detection",
        how_detect_language: "How do you want the language to be detected?",
        by_phone_prefix: "By phone prefix",
        by_telling_assistant: "Telling the assistant explicitly that they want to speak in a certain language.",
        add_more_languages: "Add more languages",
        greeting: "Greeting",
        greeting_description: "The first contact will make an impression",
        turn_greeting_options: "Turn on greeting options that should apply to your restaurant",
        different_greeting: "I would like the assistant to use a different greeting for each time of the day",
        formal_greeting: "I want my assistant to address in a formal way",
        name_greeting: "I would like my assistant to greet the clients by their name",
        introduce_greeting: "I want my assistant to introduce her/himself",
        preview: "Preview",
        reservation_policy: "Reservation policy",
        diners_policy: "Diners policy",
        diners_description: "Manage bookings differently depending on the number of diners",
        max_diners: "Max diners per booking",
        transfer_calls_more_diners: "Transfer calls if there are more diners",
        transfer_to: "transfer to",
        custom_message_transfer: "custom message for transfer",
        bc_number_diners_transfer: "Due to the number of diners, I will put you through to an agent",
        contract_group_booking: "Contract group booking management",
        contract_group_booking_description: "Contract group booking add-on to facilitate bookings for large groups, without having to transfer to the restaurant.",
        time_policy: "Time policy",
        time_policy_description: "Keep client informed about the time policy to avoid misunderstandings",
        booking_warranty: "Booking warranty time (min)",
        notify_about_warranty: "Notify about booking warranty time",
        custom_message_warranty: "custom message for warranty time",
        coming_late_no_guarantee: "Note that if you are coming later than 15 minutes, we can no longer guarantee the table for you.",
        table_availability: "table availability time (min)",
        notify_about_availability: "Notify about table availability time",
        custom_message_availability: "custom message for table availability time",
        enjoy_table_limit_time: "We kindly remind you that you can enjoy the table for no longer than 1h30.",
        others: "Others",
        other_possible_settings: "Other possible settings",
        zones: "zones",
        select_zones_venue: "Select zones that apply to your venue",
        inside: "Inside",
        terrace: "Terrace",
        patio: "Patio",
        garden: "Garden",
        bar: "Bar",
        other_zones: "other zones",
        type_more_zones: "Type more zones, e.g. VIP room, basement",
        credit_card_policy: "Credit card policy",
        have_credit_card_policy: "I have a credit card policy",
        first_contact_make_impression: "The first contact will make an impression",
        enable_cross_selling: "Enable cross-selling",
        venues_recommend_ifnot_available: "Select the venues that you’d like to recommend in case the initial one is not available.",
        enable_group_bookings: "Enable group bookings",
        max_days_advanced: "max days in advanced",
        what_else: "what else?",
        group_bookings: "Group booking",
        input_example_diners: "Due to the number of diners, I will put you through to an agent that can manage your booking from here onwards.",
        handover_schedule: "Handover Schedule",
        handover_schedule_description: "Schedules when calls are transferred to this number",
        whatsapp_channel_setup: "whatsapp channel setup",
        venue: "Venue",
        apply_same_to_all_venues: "You can apply the same WhatsApp account to several venues.",
        specific_or_multiple_venues: "Is the WhatsApp account for a specific or multiple venues?",
        specific: "Specific",
        select_a_venue: "Select venue",
        multiple: "Multiple",
        select_venues_that_apply: "Select venues that apply",
        whatsapp_step2_description: "Use the same name you use for clients to call your restaurant or use a different one. Either way, we can set it up for you.",
        which_phone_number_use: "Which phone number would you like to use?",
        new_number: "New number",
        we_provide_and_assign: "We provide and assign you a WhatsApp number",
        use_same_number_as_bot: "You can use the same number as the voice bot",
        venue_number: "Venue number",
        taken_from_google: "taken from Google",
        type_phone_number: "Type phone number",
        whatsapp_account: "Whatsapp account",
        whatsapp_step3_description: "In order to be able to deploy a WhatsApp assistant you need to use WhatsApp API. Choose the option that applies and we can guide you through each step of the process.",
        does_number_have_account: "Does this number already have a WhatsApp account?",
        whatsapp_personal_use: "Yes, WhatsApp App (for personal use)",
        personal_app: "WhatsApp is the app for private users, the one we have always had.",
        whatsapp_business: "Yes, WhatsApp Business App",
        business_app: "WhatsApp Business es la aplicación oficial para negocios pequeños sin complejidad excesiva",
        whats_business_api: "Yes, WhatsApp Business API",
        business_api_description: "WhatsApp Business API is the interface that allows for the creation of complex experiences and services such as the chatbot.",
        no_whats_this_number: "I have no WhatsApp linked this number",
        not_sure_need_guide: "I am not sure",
        // not_sure_need_guide: "I am not sure, can you guide me?",
        book_a_meeting: "Book a meeting",
        with_support_team: "with our support team.",
        api_provider: "API Provider",
        whats_step_4_description: "In order to be able to deploy a WhatsApp assistant you need to use WhatsApp API. Choose the option that applies and we can guide you through each step of the process.",
        whats_your_provider: "What is your provider?",
        whats_summary_step_description: "Your request was submitted ant the support team will get in touch with you with further information",
        request_submitted: "Request submitted",
        can_receive_sms: "I can receive SMS or phone calls at this number.",
        whats_step_4_bis_description: "In order to be able to deploy a WhatsApp assistant you need to use WhatsApp API. Choose the option that applies and we can guide you through each step of the process.",
        need_delete_account: "You need to delete your WhatsApp Account",
        if_delete_number: "Note that if you delete your number you won’t be able to",
        send_receive_messages: "send or receive new messages in the app",
        cant_see_chat_history: "can’t see the chat history",
        ready_to_reset: "Got it, I am ready to reset it",
        not_sure_need_help: "I am not sure, can you guide me?",
        personal: "Personal",
        no_wa_lnked: "No WhatsApp linked to this number",
        ready_api_onb: "Ready for API onboarding ",
        proceed_api_onb: "You can now proceed to the API onboarding by connecting to 360Dialog.",
        book_meeting_support_team: "Do you have doubts? Book a meeting with our support team.",
        agent_get_in_touch: "A customer support agent will get in touch asap with next steps.",
        type_provider_name: "Type in provider name",
        type_name_here: "Type name here",
        venues_descrip: "These configurations apply to the selected venues",
        add_another_venue: "Add another venue",
        whats_number_descrip: "This is the phone number assigned to your WhatsApp assistant",
        whatsapp_number: "WhatsApp number",
        done: "Done",
        activity: "Activity",
        campaigns: "Campaigns",
        account_settings: "Settings",
        surveys: "Surveys",
        support: "Support",
        live_calls: "Live Calls",

        summary_description: "This is a summary of the information you have provided, make sure everything is correct before creating your assistant or you can go back and make the changes you need.",
        customer: "Customer",

        booking_manager: "Booking Manager",
        add_other_restaurants: "Do you want to add other restaurants?",

        do_you_have_switchboard: "Do you have a switchboard?",
        when_should_assistant_start: "When do you want the assistant to start?",

        selected_languages: "Selected languages",
        and: " and ",

        assistant_gender: "Assistant's gender",
        female: "Female",
        male: "Male",

        formality_preference: "What formality do you prefer to use with the customer?",

        notify_warranty_time: "Do you want to notify the reservation warranty time?",

        minutes: "minutes",
        ask_anything_else: "Do you want the assistant to ask anything else?",
        send_confirmation: "Do you want to send a reservation confirmation?",
        add_anything_else: "Do you want to add anything else?",
        create: "Create",
        business_experience_start: "The experience at your business starts at",
        first_contact: "the first contact.",
      },
    },
    es: {
      translation: {
        tech_review_1: "Un agente de soporte se pondrá en contacto lo antes posible con los siguientes pasos. Si tienes alguna duda o pregunta, ",
        delete_photo: "Eliminar foto",
        select_photo: "Selecciona o arrastra la foto para subir como foto de perfil",
        industry: "Industria",
        address: "Dirección",
        description: "Descripción",
        about: "Acerca de",
        support: "Soporte",
        no_wa_linked: "No hay WhatsApp vinculado a este número",
        done: "Finalizar",
        ready_for_onboarding: "Listo para configurar 360Dialog",
        whats_number_descrip: "Éste es el numero asignado a tu asistente de Whatsapp",
        add_another_venue: "Agregar local",
        venues_descrip: "Ésta configuración se aplica a los locales seleccionados",
        whatsapp_number: "Número de WhatsApp",
        clients: "Clientes",
        live_calls: "Llamadas en vivo",
        activity: "Actividad",
        business_experience_start: "La experiencia en tu negocio empieza en",
        first_contact: "el primer contacto.",
        welcome_to_bookline: "¡Bienvenido a Bookline, ",
        assistant_setup_intro: "Para asegurarnos de que el asistente esté perfectamente adaptado y configurado según tus preferencias y necesidades específicas, te pedimos que dediques unos minutos a completar este cuestionario.",
        assistant_setup_intro_continued: "Entendemos que puedas estar ocupado; así que, si no logras terminarlo ahora, no hay problema. Puedes pausar y retomar el proceso donde lo dejaste en cualquier momento.",
        start: "Empezar",
        write_here: "Escribe aquí",
        ask_conf_notif: "¿Quieres enviarle una confirmación de la reserva al cliente?",
        yes_mkt: "Sí, con WhatsApp con publicidad de bookline",
        free: "gratuito",
        yes_paid: "Sí, con WhatsApp sin publicidad",
        yes_sms: "Sí, con SMS",
        dont_want: "No quiero",
        anything_else: "¿Algo más que nos quieras indicar?",
        anything_else2: "Si no estás seguro podrás indicarlo más adelante",
        ask_warranty: "¿Quieres indicar el tiempo de garantía de reserva?",
        warranty_placeholder: "Indica el tiempo de garantía en minutos",
        extra_q: "¿Quieres que el asistente pregunte o diga algo más?",
        extra_q2: "Si no estás seguro podrás indicarlo más adelante",
        alergies: "¿Algún comentario adicional como alergias, intolerancias o peticiones especiales que quieras indicar?",
        extra_highchair: "En caso de venir con niños y necesitar tronas, por favor especifique cuántas necesitas.",
        ask_how_know_us: "¿Cómo nos ha conocido?",
        ask_disca: "¿Viene alguna persona con movilidad reducida?",
        extra_placeholder: "Indica qué más quieres que diga el asistente. Puedes agregar varias frases o preguntas.",
        ask_warranty_time: "Indica el tiempo de garantía en minutos",
        ask_formality: "¿Qué trato prefieres darle al cliente?",
        you: "Tú",
        you2: "Usted",
        assistant_title: "Ara configurem el teu assistent, tria els idiomes que vols que parli el teu assistent",
        assistant_title2: "Pots seleccionar l'idioma predeterminat més endavant",
        spanish: "Espanyol",
        english: "Anglès",
        catalan: "Català",
        french: "Francès",
        italian: "Italià",
        german: "Alemany",
        portuguese: "Portuguès",
        lang_detection: "Com vols que es faci la detecció de l'idioma?",
        by_prefix: "Per prefix telefònic del client",
        by_command: "Dient explícitament a l'assistent que vols parlar en un idioma determinat",
        default_lang: "Tria l'idioma predeterminat del teu assistent",
        default_lang2: "Per a aquest idioma podràs configurar el tipus de veu",
        assistant_voice: "Tria la veu del teu assistent",
        assistant_voice2: "Personalitza l'experiència auditiva per als teus clients",
        phone_placeholder: "Ingrese su número de teléfono",
        ask_centralita: "¿Tienes centralita?",
        ask_centralita2: "Indica si cuentas con un sistema de comunicación integrado, en caso contrario indica tu compañía telefónica",
        ask_phone_provider: "Selecciona tu proveedor de telefonía",
        ask_centralita_provider: "Selecciona tu proveedor",
        idk: "No lo sé",
        when_answer: "¿Cuando quieres que salte el asistente?",
        ask_2nd_line: "Por favor indíquenos una segunda línea para transferir la llamada al restaurante. En caso de no contar con una, deje el casillero en blanco y un agente le contactará luego para ofrecerle una solucíon a éste tema.",
        always: "Siempre",
        if_no_answer: "Si no contestáis en el restaurante",
        franjas: "Franjas horarias",
        confirm_data: "Confirma los datos del restaurante",
        verify: "Verifica que toda la información sea correcta antes de continuar",
        tel: "Teléfono",
        other_rests: "¿Tienes otros restaurantes que te gustaría añadir?",
        add_others: "Por favor escribe los nombres separados por una coma",
        ask_bm: "¿Qué libro de reservas usas?",
        ask_bm2: "La gestión de las reservas es mucho más sencilla con una plataforma dedicada. Aunque si no dispones de una, no es un inconveniente, ¡sabemos trabajar sin ella!",
        account_settings: "Ajustes",
        clinic: "Clínica",
        taxi: "Emisora de taxi",
        club: "Club deportivo",
        select_b: "Seleccione su tipo de negocio",

        ask_restaurant: "¿Cuál es tu restaurante?",
        search_restaurant: "Busquemos tu restaurante",
        cant_find: "¿No lo encuentras?",
        rest_placeholder: "Escribe el nombre de tu restaurante",

        title: "Aplicación en varios idiomas",
        venues: "Locales",
        diversion_instructions: "Instrucciones de desvío",
        show_stats: "Mostrar estadísticas",
        edit_bot: "Editar Bot",
        last_call: "Última llamada fue",
        phone_settings: "Configuración del teléfono",
        close: "Cerrar",
        billing: "Facturación",
        account: "Cuenta",
        create_account: "Crear cuenta",
        create_new_account: "Crear una cuenta nueva",
        legal_information: "Información legal",
        company_name: "Nombre de la empresa",
        tax_id: "CIF",
        partner_name: "Nombre del socio",
        partner_id: "NIF del socio",
        email: "Correo electrónico",
        tax_address: "Domicilio fiscal",
        city: "Ciudad",
        postal_code: "Código postal",
        payment_method: "Método de pago",
        payment_type: "Tipo de pago",
        direct_debit: "Débito directo",
        bank: "Banco",
        account_holder: "Nombre del titular de la cuenta",
        account_number: "Número de cuenta",
        currency: "Moneda",
        update_payment_method: "Actualizar método de pago",
        payment_settings: "Configure su método de pago configurando el Débito Directo a través de",
        cancel: "Cancelar",
        field_mandatory: "Todos los campos son obligatorios",
        not_valid_email: "Correo no válido",
        team: "Equipo",
        invite_user: "Invitar usuario",
        name: "Nombre",
        phone: "Número de teléfono",
        role: "Rol",
        copy_link: "Copiar enlace",
        send: "Enviar",
        assistants: "Asistentes",
        add_new_bot: "Añadir nuevo bot",
        restaurant: "Restaurante",
        online: "En línea",
        offline: "Fuera de línea",
        calls: "Llamadas",
        save: "Guardar",
        share: "Compartir",
        add_new_ticket: "Añadir nuevo ticket",
        notifications: "Notificaciones",
        whats_description: "Soluciones de Mensajería Móvil y Automatización Móvil y API · Mensajería WhatsApp",
        reservations: "Reservas",
        cover_description: "Automatiza la facturación de tus clientes con un sistema de pago en mesa. Ahorra tiempo y aumenta la rentabilidad de tu negocio.",
        restoo_description: "Tu Libro de Reservas Inteligente. Sin comisiones. Gestiona tus reservas y planifica servicios fácilmente. Conoce las preferencias de todos tus clientes y construye su fidelidad automáticamente.",
        dentalink_description: "Dentalink es el software ideal para la gestión de consultorios dentales. Simplifica tus tareas diarias, fortalece la relación con tus pacientes e incrementa la rentabilidad de tu clínica. ¡Di adiós al papel!",
        colzent_description:
          "Sistema integral de gestión de flotas de vehículos con gestión de conductores, reservas y servicios, geolocalización en tiempo real de vehículos y servicios, servicios de voz, asistencia a conductores y/o clientes y mucho más...",
        golf_description: "Golfmanager es el único software 100% basado en la nube que permite gestionar todas las necesidades operativas de un club de golf en un solo programa. Diseño moderno y una experiencia de reserva sin igual.",
        geolocation: "Geolocalización",
        google_description: "Navega por el mundo más fácil y rápidamente con Google Maps. Hay mapas de más de 220 países y territorios con millones de negocios y lugares marcados.",
        netelip_description: "Telefonía IP para empresas de netelip. Operador líder de Voz IP o VoIP en España y Latinoamérica con una amplia gama de soluciones.",
        twilio_description: "Twilio potencia interacciones personalizadas y comunicaciones globales confiables para conectarte con los clientes.",
        sendgrid_description: "SendGrid entrega tus correos electrónicos transaccionales y de marketing a través de la plataforma de entrega de correo electrónico basada en la nube más grande del mundo. Envía con confianza.",
        hubspot_description:
          "La plataforma CRM de HubSpot incluye todas las herramientas e integraciones que necesitas para marketing, ventas, gestión de contenidos, servicio al cliente y operaciones. Cada producto es potente por sí solo; juntos, son inigualables.",
        select_customer: "Por favor, selecciona un cliente",
        install: "Instalar",
        already_installed: "Esto ya está instalado",
        integrations: "Integraciones",
        installed: "Instalado",
        add_new_integration: "Agregar nueva integración",
        company: "Empresa",
        extra_data: "Datos adicionales",
        user: "Usuario",
        list: "Lista",
        new_notification: "Nueva notificación",
        frequency: "Frecuencia",
        general: "General",
        conversations: "Conversaciones",
        conversation: "Conversación",
        chats: "Chats",
        marketplace: "Mercado",
        settings: "Configuración",
        search_number: "Buscar un número exacto",
        show: "Mostrar",
        previous: "Anterior",
        page: "Página",
        of: "de",
        filter: "Filtrar",
        clean: "Limpiar",
        no_calls: "No hay llamadas para mostrar",
        assistant: "Asistente",
        client: "Cliente",
        today: "Hoy",
        yesterday: "Ayer",
        more: "Más",
        no_number: "El número que estás buscando no existe",
        next: "Siguiente",
        no_chats: "No hay chats para mostrar",
        select_whats: "Por favor, selecciona tu cuenta de WhatsApp Business",
        send_whats: "Enviar un WhatsApp",
        call: "Llamada",
        search: "Buscar",
        help_support: "Centro de Soporte de Ayuda",
        search_help: "Buscar cualquier pregunta o tema de ayuda",
        getting_started: "Primeros Pasos",
        download: "Descarga",
        installation: "Instalación",
        accessibility: "Accesibilidad",
        supported_devices: "Dispositivos Compatibles",
        npm_installation: "Instalación de NPM",
        common_errors: "Errores Comunes al Instalar",
        npm_windows: "NPM para Windows",
        cannot_install: "No se puede instalar NPM en Mac",
        not_recognize: "NPM no se reconoce en local",
        npm_version: "La versión de NPM no aparece al ejecutar el comando",
        bootstrap_ins: "Instalación de Bootstrap",
        migrating: "Migración de Bootstrap 3",
        webpack: "Bootstrap utilizando Webpack",
        react_components: "Componentes de Bootstrap para React",
        example_bootstrap: "Ejemplo de página Bootstrap con barra de navegación",
        sass_custom: "Personalización de SASS",
        min_sass: "Minimizar SASS a CSS sin Bootstrap",
        more_sass: "Agregar clases de utilidad adicionales en SASS",
        pricing_billing: "Precios y Facturación",
        features: "Características",
        search_people: "Buscar personas",
        help_center: "Centro de Ayuda",
        find_integrations: "Descubre nuestras integraciones",
        preferences: "Preferencias",
        language: "Idioma",
        select_or_drop: "Selecciona o arrastra una foto para subirla",
        select_venue: "Selecciona un local",
        business_details: "Detalles del Negocio",
        channel_setup: "Canales",
        subscriptions: "Suscripciones",
        active: "Activo",
        phone_lines: "Líneas Telefónicas",
        primary_line: "Línea Principal",
        this_is_main_line: "Esta es su línea telefónica principal del restaurante para que los clientes se pongan en contacto directamente",
        number: "Número",
        provider: "Proveedor",
        type: "Tipo",
        bot_line: "Línea de Bot",
        number_asigned_bot: "Este es el número de teléfono asignado al bot",
        handover_line: "Línea de Transferencia",
        handover_description: "Este es el teléfono utilizado en caso de que el cliente quiera ser transferido al restaurante",
        label: "Etiqueta",
        call_routing: "Enrutamiento de Llamadas",
        call_routing_rule: "Estas son las reglas de enrutamiento de llamadas",
        last_call_was: "La última llamada fue hace",
        transfer_to_bot: "Transferir al bot",
        call_assigned_bot: "La llamada es asignada a este bot",
        trigger: "Disparador",
        when_bot_activates: "Cuando se activa el bot",
        try_it: "Pruébalo",
        inactive: "Inactivo",
        whatsapp_settings_description: "Para tener su asistente en funcionamiento, necesitamos configurar sus canales de comunicación. Comience a configurarlo aquí, o póngase en contacto con su agente de soporte si no está seguro.",
        copy_settings: "Copiar configuraciones",
        setup: "Configurar",
        basic_info: "Información Básica",
        info_from_google: "Esta información se toma de Google",
        website: "Sitio Web",
        timezone: "Zona Horaria",
        booking_software: "Software de Reservas",
        schedules: "Horarios",
        sync_google: "Sincronizar con Google Maps",
        updates_at_1pm: "El horario se actualiza todos los días a la 1pm.",
        closed: "Cerrado",
        same_every_day: "Igual todos los días",
        apply_to_all: "Aplicar a todos",
        add_slot: "Añadir intervalo",
        all_days: "Todos los días",
        media: "Medios",
        upload_pic: "Suba la foto de su restaurante y úsela como foto de perfil o la foto de WhatsApp",
        use_profile: "Usar como foto de perfil",
        use_whatsapp: "Usar como foto de WhatsApp",
        contact_channels: "Canales de Contacto",
        info_send_notifications: "Utilizamos esta información para enviar notificaciones",
        alternative_phone_number: "Número de teléfono alternativo",
        social_networks: "Redes Sociales",
        news_email: "Recibir noticias por correo electrónico",
        marketing_whatsapp: "Recibir mensajes de marketing de bookline vía WhatsApp",
        advanced: "Avanzado",
        personalized_exp: "Proporcionar esta información adicional ayudará a mejorar la experiencia personalizada",
        options_restaurant: "Seleccione opciones que apliquen a su restaurante",
        food_tolerance: "Preferencias y tolerancias alimentarias",
        vegetarian_opt: "Ofrecemos opciones vegetarianas",
        vegan_opt: "Ofrecemos opciones veganas",
        lactose_opt: "Ofrecemos opciones sin lactosa",
        gluten_opt: "Tenemos opciones sin gluten",
        parking: "Estacionamiento",
        own_parking: "Tiene estacionamiento propio",
        parking_nearby: "Estacionamiento cercano",
        wallet_parking: "Tiene estacionamiento con vale",
        easy_parking: "¿Fácil de estacionar?",
        wheel_access: "Ofrecemos acceso para sillas de ruedas",
        pet_friendly: "Aceptamos mascotas",
        delivery: "Entrega a domicilio",
        deliver_home: "Realizamos entregas a domicilio",
        update: "Actualizar",
        plan_name: "Nombre del plan",
        voice_assistant: "Asistente de Voz",
        plan_cost: "Costo del Plan",
        date_activated: "Fecha de activación",
        add_on: "Adicional",
        survey: "Encuesta",
        booking_reminder: "Recordatorio de Reserva",
        phone_number: "Número de teléfono",
        edit: "Editar",
        add: "Añadir",
        call_to: "Llamada a",
        group_booking: "Reserva de grupo",
        search_name: "Busca un nombre",
        voice: "Voz",
        pets: "Mascotas",
        open: "Abierto",
        to: "a",
        type_link_here: "escribe aqui el link",
        type_number_here: "escribe aqui el número",
        day: "día",
        schedule: "horario",
        voice_channel_setup: "Configuración de Canal de Voz",
        phone_provider: "Proveedor de Teléfono",
        step1_description1: "Para implementar un bot de voz de manera fluida en las operaciones de su empresa, un paso inicial crucial implica establecer la integración con su proveedor de teléfono.",
        step1_description2: "Si no encuentra su proveedor en la lista o no está seguro, lo descubriremos por usted.",
        select_phone_provider: "Seleccione su proveedor de teléfono",
        provider_no_centralita: "Algunos proveedores sin opción de centralita",
        other: "Otro",
        dont_have_provider: "Aún no tengo un proveedor",
        use_centralita: "¿Utiliza su centralita?",
        yes: "Sí",
        no: "No",
        unsure: "No estoy seguro",
        provide_company_details: "Proporcione detalles de la empresa",
        type_name: "escriba el nombre",
        contact_person: "Persona de contacto",
        contact_number: "Número de contacto",
        type_number: "escriba el número",
        type_email: "escriba el correo electrónico",
        step2_description: "Enrute las llamadas al recurso apropiado, ya sea un representante de soporte al cliente, un departamento específico o un sistema automatizado.",
        when_trigger_bot: "¿Cuándo desea que se active el bot?",
        when_unavailable: "Cuando no esté disponible",
        when_line_busy: "Cuando la línea esté ocupada o el restaurante no responda",
        by_schedules: "Por horarios",
        by_ivr: "Después de una selección en el IVR actual",
        step3_description: "Enrute las llamadas al recurso apropiado, ya sea un representante de soporte al cliente, un departamento específico o un sistema automatizado.",
        where_clients_call: "¿Dónde quiere que sus clientes lo llamen?",
        bot_number: "Número del bot",
        recommended: "Recomendado",
        restaurant_number: "Número del restaurante",
        important: "¡Importante!",
        further_setup: "Esta opción podría requerir una configuración adicional para la línea de transferencia.",
        // another_number: "Otro número",
        another_number: "Número propio",
        handover: "Transferencia",
        handover_step_description: "La llamada no puede transferirse de nuevo al número que el cliente está llamando. Necesitamos implementar una línea diferente para evitar la creación de bucles infinitos.",
        where_transfer: "Si un cliente lo solicita, ¿a dónde quiere que se transfiera la llamada?",
        different_number: "A un número diferente",
        add_destinations: "Agregar destinos",
        queue: "Cola",
        add_more: "Agregar más",
        never_transfer_calls: "Nunca transferir llamadas",
        some_provider: "Algunos proveedores",
        no_provider: "Sin proveedor",
        setup_summary: "Resumen de Configuración",
        summary_step_description: "Una vez que envíe la información, aún puede editarla más tarde desde el portal del cliente.",
        below_info_provided: "A continuación, se muestra un resumen de la información proporcionada",
        setup_successful: "Configuración exitosa",
        pending_tech_review: "Pendiente de revisión técnica.",
        tech_review_description: "Un agente de soporte al cliente se pondrá en contacto cuando el canal de voz esté activo.",
        centralita: "Centralita",
        direct: "Directo",
        go_to_portal: "Ir al portal",
        all_channels: "Todos los canales",
        pending: "Pendiente",
        accepted: "Aceptado",
        rejected: "Rechazado",
        archived: "Archivado",
        booking: "Reserva",
        requests: "Solicitudes",
        all: "Todos",
        custom: "Personalizado",
        filter_by: "Filtrar por",
        channel: "Canal",
        status: "Estado",
        request_type: "Tipo de solicitud",
        clear: "Limpiar",
        order_by: "Ordenar por",
        booking_type: "Tipo de reserva",
        from: "Desde",
        pax: "PAX",
        time: "Hora",
        received_on: "Recibido el",
        restaurant_name: "Nombre del restaurante",
        client_details: "Detalles del cliente",
        see_client_record: "Ver registro del cliente",
        request_details: "Detalles de la solicitud",
        comments: "Comentarios",
        accept: "Aceptar",
        reject: "Rechazar",
        call_details: "Detalles de la llamada",
        summary: "Resumen",
        timeline: "Cronología",
        transcript: "Transcripción",
        call_start: "Inicio de la llamada",
        call_end: "Fin de la llamada",
        call_duration: "Duración de la llamada",
        reason_identified: "Motivo identificado",
        booking_details: "Detalles de la reserva",
        confirmation: "Confirmación",
        sent: "Enviado",
        incoming_call: "Llamada entrante",
        call_ended: "Llamada finalizada",
        search_by_phone_number: "Buscar por número de teléfono",
        in_review: "En revisión",
        cross_selling: "venta cruzada",
        reminders: "recordatorios",
        products: "Productos",
        add_new: "Añadir nuevo",
        my_products: "Mis productos",
        search_by_name: "Buscar asistente por nombre",
        booking_management: "Gestión de reservas",
        date: "Fecha",
        booking_confirmed: "Reserva confirmada",
        main: "Principal",
        add_ons: "Complementos",
        collapse_all: "Contraer todo",
        expand_all: "Expandir todo",
        personality: "Personalidad",
        personality_description: "Dale personalidad con un nombre, rasgos y una cara",
        avatar: "Avatar",
        avatar_description: "Elige una foto de avatar para tu asistente o sube la tuya",
        traits: "Rasgos",
        traits_description: "Elige algunos rasgos de personalidad que te gustaría que tu asistente tenga",
        playful: "juguetón",
        serious: "serio",
        funny: "divertido",
        friendly: "amigable",
        calm: "tranquilo",
        sarcastic: "sarcástico",
        rude: "grosero",
        kinky: "pícaro",
        gentle: "gentil",
        sonofagun: "granuja",
        excited: "emocionado",
        languages_and_voice: "Idiomas y Voz",
        languages_description: "Haz que hable los idiomas que quieras y con la voz que te guste",
        default_language: "idioma predeterminado",
        default_voice: "voz predeterminada",
        activate_language_detention: "Activar detección de idioma",
        how_detect_language: "¿Cómo quieres que se detecte el idioma?",
        by_phone_prefix: "Por prefijo telefónico",
        by_telling_assistant: "Diciéndole explícitamente al asistente que quieren hablar en un determinado idioma",
        add_more_languages: "Agregar más idiomas",
        greeting: "Saludo",
        greeting_description: "El primer contacto causará una impresión",
        turn_greeting_options: "Activar opciones de saludo que deben aplicarse a tu restaurante",
        different_greeting: "Me gustaría que el asistente use un saludo diferente para cada momento del día",
        formal_greeting: "Quiero que mi asistente se dirija de manera formal",
        name_greeting: "Me gustaría que mi asistente salude a los clientes por su nombre",
        introduce_greeting: "Quiero que mi asistente se presente",
        preview: "Vista previa",
        reservation_policy: "Política de reservas",
        diners_policy: "Política para comensales",
        diners_description: "Gestiona las reservas de manera diferente dependiendo del número de comensales",
        max_diners: "Máx. comensales por reserva",
        transfer_calls_more_diners: "Transferir llamadas si hay más comensales",
        transfer_to: "transferir a",
        custom_message_transfer: "mensaje personalizado para transferencia",
        bc_number_diners_transfer: "Debido al número de comensales, te comunicaré con un agente",
        contract_group_booking: "Contratar gestión de reservas para grupos",
        contract_group_booking_description: "Contrata el complemento de reservas para grupos para facilitar las reservas para grupos grandes, sin tener que transferir al restaurante",
        time_policy: "Política de tiempo",
        time_policy_description: "Mantén al cliente informado sobre la política de tiempo para evitar malentendidos",
        booking_warranty: "Tiempo de garantía de reserva (min)",
        notify_about_warranty: "Notificar sobre el tiempo de garantía de reserva",
        custom_message_warranty: "mensaje personalizado para tiempo de garantía",
        coming_late_no_guarantee: "Ten en cuenta que si llegas más tarde de 15 minutos, ya no podemos garantizar la mesa para ti",
        table_availability: "tiempo de disponibilidad de mesa (min)",
        notify_about_availability: "Notificar sobre el tiempo de disponibilidad de mesa",
        custom_message_availability: "mensaje personalizado para tiempo de disponibilidad de mesa",
        enjoy_table_limit_time: "Te recordamos amablemente que puedes disfrutar de la mesa por no más de 1h30",
        others: "Otros",
        other_possible_settings: "Otras configuraciones posibles",
        zones: "zonas",
        select_zones_venue: "Selecciona zonas que apliquen a tu local",
        inside: "Interior",
        terrace: "Terraza",
        patio: "Patio",
        garden: "Jardín",
        bar: "Bar",
        other_zones: "otras zonas",
        type_more_zones: "Escribe más zonas, p.ej. sala VIP, sótano",
        credit_card_policy: "Política de tarjeta de crédito",
        have_credit_card_policy: "Tengo una política de tarjeta de crédito",
        first_contact_make_impression: "El primer contacto causará una impresión",
        enable_cross_selling: "Habilitar venta cruzada",
        venues_recommend_ifnot_available: "Selecciona los locales que te gustaría recomendar en caso de que el inicial no esté disponible",
        enable_group_bookings: "Habilitar reservas para grupos",
        max_days_advanced: "máx. días por adelantado",
        what_else: "¿Qué más?",
        group_bookings: "Reserva de grupo",
        input_example_diners: "Debido al número de comensales, te comunicaré con un agente que puede gestionar tu reserva a partir de ahora",
        handover_schedule: "Horario de transferencia",
        handover_schedule_description: "Horarios cuando las llamadas se transfieren a este número",
        whatsapp_channel_setup: "configuración del canal de WhatsApp",
        venue: "Local",
        apply_same_to_all_venues: "Puede aplicar la misma cuenta de WhatsApp a varios locales.",
        specific_or_multiple_venues: "¿La cuenta de WhatsApp es para un local específico o varios?",
        specific: "Específico",
        select_a_venue: "Seleccionar local",
        multiple: "Múltiples",
        select_venues_that_apply: "Seleccione los locales que aplican",
        whatsapp_step2_description: "Use el mismo nombre que usa para que los clientes llamen a su restaurante o use uno diferente. De cualquier manera, podemos configurarlo por usted.",
        which_phone_number_use: "¿Qué número de teléfono le gustaría usar?",
        new_number: "Número nuevo",
        we_provide_and_assign: "Le proporcionamos y asignamos un número de WhatsApp",
        use_same_number_as_bot: "Puede usar el mismo número que el bot de voz",
        venue_number: "Número del local",
        taken_from_google: "tomado de Google",
        type_phone_number: "Escriba el número de teléfono",
        whatsapp_account: "Cuenta de WhatsApp",
        whatsapp_step3_description: "Para poder desplegar un asistente de WhatsApp necesita usar WhatsApp API. Elija la opción que aplica y podemos guiarlo a través de cada paso del proceso.",
        does_number_have_account: "¿Este número ya tiene una cuenta de WhatsApp?",
        whatsapp_personal_use: "Sí, WhatsApp App (para uso personal)",
        personal_app: "WhatsApp es la app para usuarios privados, la que siempre hemos tenido.",
        whatsapp_business: "Sí, WhatsApp Business App",
        business_app: "WhatsApp Business es la aplicación oficial para negocios pequeños sin complejidad excesiva.",
        whats_business_api: "Sí, WhatsApp Business API",
        business_api_description: "WhatsApp Business API es la interfaz que permite la creación de experiencias y servicios complejos como el chatbot.",
        no_whats_this_number: "No tengo WhatsApp vinculado a este número",
        not_sure_need_guide: "No estoy seguro",
        // not_sure_need_guide: "No estoy seguro, ¿pueden guiarme?",
        book_a_meeting: "Agendar una reunión",
        with_support_team: "con nuestro equipo de soporte.",
        api_provider: "Proveedor de API",
        whats_step_4_description: "Para poder desplegar un asistente de WhatsApp necesita usar WhatsApp API. Elija la opción que aplica y podemos guiarlo a través de cada paso del proceso.",
        whats_your_provider: "¿Cuál es su proveedor?",
        whats_summary_step_description: "Su solicitud fue enviada y el equipo de soporte se pondrá en contacto con usted con más información",
        request_submitted: "Solicitud enviada",
        can_receive_sms: "Puedo recibir SMS o llamadas telefónicas en este número.",
        whats_step_4_bis_description: "Para poder desplegar un asistente de WhatsApp necesita usar WhatsApp API. Elija la opción que aplica y podemos guiarlo a través de cada paso del proceso.",
        need_delete_account: "Necesita eliminar su cuenta de WhatsApp",
        if_delete_number: "Tenga en cuenta que si elimina su número no podrá",
        send_receive_messages: "enviar o recibir nuevos mensajes en la app",
        cant_see_chat_history: "no puede ver el historial de chat",
        ready_to_reset: "Entendido, estoy listo para reiniciarla",
        not_sure_need_help: "No estoy seguro, ¿pueden ayudarme?",
        personal: "Personal",
        no_wa_lnked: "No hay WhatsApp vinculado a este número",
        ready_api_onb: "Listo para el onboarding de API",
        proceed_api_onb: "Ahora puede proceder con el onboarding de API conectándose a 360Dialog.",
        book_meeting_support_team: "¿Tiene dudas? Agende una reunión con nuestro equipo de soporte.",
        agent_get_in_touch: "Un agente de soporte al cliente se pondrá en contacto lo antes posible con los siguientes pasos.",
        type_provider_name: "Escriba el nombre del proveedor",
        type_name_here: "Escriba el nombre aquí",
        campaigns: "Campañas",
        summary_description: "Éste es un resumen de la información que has proporcionado, verifica que todo esté correcto antes de crear tu asistente o puedes regresar y hacer los cambios que necesites.",
        customer: "Cliente",
        booking_manager: "Libro de reservas",
        add_other_restaurants: "¿Quieres añadir otros restaurantes?",
        do_you_have_switchboard: "¿Tienes centralita?",
        when_should_assistant_start: "¿Cuando quieres que salte el asistente?",
        selected_languages: "Idiomas seleccionados",
        and: " y ",
        assistant_gender: "Género del asistente",
        female: "Femenino",
        male: "Masculino",
        formality_preference: "¿Qué trato prefieres darle al cliente?",
        notify_warranty_time: "¿Quiéres avisar del tiempo de garantía de reserva?",
        minutes: "minutos",
        ask_anything_else: "¿Quiéres que el asistente pregunte algo más?",
        send_confirmation: "¿Quiéres enviar una confirmación de la reserva?",
        add_anything_else: "¿Quiéres añadir algo más?",
        create: "Crear",
      },
    },
    ca: {
      translation: {
        tech_review_1: "Un agent d'atenció al client es posarà en contacte el més aviat possible amb els següents passos. Si tens qualsevol dubte o pregunta, ",
        delete_photo: "Esborrar foto",
        select_photo: "Seleccioneu o deixeu anar la foto per penjar-la com a imatge de perfil",
        industry: "Indústria",
        address: "Adreça",
        description: "Descripció",
        support: "suport",
        about: "Sobre",
        done: "fet",
        no_wa_linked: "No hi ha WhatsApp vinculat a aquest número",
        clients: "Clients",
        account_settings: "Configuracions",
        activity: "Activitat",
        live_calls: "Trucades en viu",
        business_experience_start: "L'experiència al teu negoci comença en",
        first_contact: "el primer contacte.",
        welcome_to_bookline: "Benvingut a Bookline, ",
        assistant_setup_intro: "Per assegurar-nos que l'assistent estigui perfectament adaptat i configurat segons les teves preferències i necessitats específiques, et demanem que dediquis uns minuts a completar aquest qüestionari.",
        assistant_setup_intro_continued: "Entenem que puguis estar ocupat; així que, si no aconsegueixes acabar-ho ara, no hi ha problema. Pots pausar i reprendre el procés on el vas deixar en qualsevol moment.",
        start: "Començar",
        write_here: "Escriu aquí",
        ask_conf_notif: "Vols enviar una confirmació de la reserva al client?",
        yes_mkt: "Sí, amb WhatsApp amb publicitat de bookline",
        free: "gratuït",
        yes_paid: "Sí, amb WhatsApp sense publicitat",
        yes_sms: "Sí, amb SMS",
        dont_want: "No vull",
        anything_else: "Alguna cosa més que ens vulguis indicar?",
        anything_else2: "Si no estàs segur podràs indicar-ho més endavant",
        ask_warranty: "Vols indicar el temps de garantia de la reserva?",
        warranty_placeholder: "Indica el temps de garantia en minuts",
        extra_q: "Vols que l'assistent pregunti o digui alguna cosa més?",
        extra_q2: "Si no estàs segur podràs indicar-ho més endavant",
        alergies: "Qualsevol comentari addicional com al·lèrgies, intoleràncies o peticions especials que vulguis indicar?",
        extra_highchair: "En cas de venir amb nens i necessitar trones, si us plau especifiqueu quantes necessiteu.",
        ask_how_know_us: "Com ens has conegut?",
        ask_disca: "Ve alguna persona amb mobilitat reduïda?",
        extra_placeholder: "Indica què més vols que digui l'assistent. Pots afegir diverses frases o preguntes.",
        ask_warranty_time: "Indica el temps de garantia en minuts",
        ask_formality: "Quin tracte prefereixes donar al client?",
        you: "Tu",
        you2: "Vostè",
        assistant_title: "Ara configurem el teu assistent, tria els idiomes que vols que parli el teu assistent",
        assistant_title2: "Pots seleccionar l'idioma predeterminat més endavant",
        spanish: "Espanyol",
        english: "Anglès",
        catalan: "Català",
        french: "Francès",
        italian: "Italià",
        german: "Alemany",
        portuguese: "Portuguès",
        lang_detection: "Com vols que es faci la detecció de l'idioma?",
        by_prefix: "Per prefix telefònic del client",
        by_command: "Dient explícitament a l'assistent que vols parlar en un idioma determinat",
        default_lang: "Tria l'idioma predeterminat del teu assistent",
        default_lang2: "Per a aquest idioma podràs configurar el tipus de veu",
        assistant_voice: "Tria la veu del teu assistent",
        assistant_voice2: "Personalitza l'experiència auditiva per als teus clients",
        phone_placeholder: "Introduïu el vostre número de telèfon",

        ask_centralita: "Tens centraleta?",
        ask_centralita2: "Indica si comptes amb un sistema de comunicació integrat, en cas contrari indica la teva companyia telefònica",
        ask_phone_provider: "Selecciona el teu proveïdor de telefonia",
        ask_centralita_provider: "Selecciona el teu proveïdor",
        idk: "No ho sé",
        when_answer: "Quan vols que salti l'assistent?",
        ask_2nd_line: "Si us plau, indiqueu-nos una segona línia per transferir la trucada al restaurant. En cas de no comptar-ne amb una, deixeu la casella en blanc i un agent us contactarà després per oferir-vos una solució a aquest tema.",
        always: "Sempre",
        if_no_answer: "Si no contesteu al restaurant",
        franjas: "Franges horàries",
        confirm_data: "Confirma les dades del restaurant",
        verify: "Verifica que tota la informació sigui correcta abans de continuar",
        tel: "Telèfon",
        other_rests: "Tens altres restaurants que t'agradaria afegir?",
        add_others: "Si us plau, escriu els noms separats per una coma",
        ask_bm: "Quin llibre de reserves utilitzes?",
        ask_bm2: "La gestió de les reserves és molt més senzilla amb una plataforma dedicada. Tanmateix, si no en disposes, no és un inconvenient, sabem treballar sense ella!",
        clinic: "Clínica",
        taxi: "Emissora de taxi",
        club: "Club esportiu",
        select_b: "Seleccioneu el vostre tipus de negoci",
        ask_restaurant: "Quin és el teu restaurant?",
        rest_placeholder: "Escriu el nom del teu restaurant",

        search_restaurant: "Busquem el teu restaurant",
        cant_find: "No ho pots trobar?",
        title: "Aplicació en diversos idiomes",
        venues: "Locals",
        diversion_instructions: "Instruccions de desviament",
        show_stats: "Mostra estadístiques",
        edit_bot: "Editar Bot",
        last_call: "La darrera trucada va ser",
        phone_settings: "Configuració del telèfon",
        close: "Tancar",
        billing: "Facturació",
        account: "Compte",
        create_account: "Crear compte",
        create_new_account: "Crear un compte nou",
        legal_information: "Informació legal",
        company_name: "Nom de l'empresa",
        tax_id: "CIF",
        partner_name: "Nom del soci",
        partner_id: "NIF del soci",
        email: "Correu electrònic",
        tax_address: "Domicili fiscal",
        city: "Ciutat",
        postal_code: "Codi postal",
        payment_method: "Mètode de pagament",
        payment_type: "Tipus de pagament",
        direct_debit: "Dèbit directe",
        bank: "Banc",
        account_holder: "Nom del titular del compte",
        account_number: "Número de compte",
        currency: "Moneda",
        update_payment_method: "Actualitzar mètode de pagament",
        payment_settings: "Configura el teu mètode de pagament configurant el Dèbit Directe a través de",
        cancel: "Cancel·lar",
        field_mandatory: "Tots els camps són obligatoris",
        not_valid_email: "Correu no vàlid",
        team: "Equip",
        invite_user: "Convidar usuari",
        name: "Nom",
        phone: "Número de telèfon",
        role: "Rol",
        copy_link: "Copiar enllaç",
        send: "Enviar",
        assistants: "Assistents",
        add_new_bot: "Afegir nou bot",
        restaurant: "Restaurant",
        online: "En línia",
        offline: "Fora de línia",
        calls: "Trucades",
        save: "Desar",
        share: "Compartir",
        add_new_ticket: "Afegir un nou tiquet",
        notifications: "Notificacions",
        whats_description: "Solucions de Missatgeria Mòbil i Automatització Mòbil i API · Missatgeria WhatsApp",
        reservations: "Reserves",
        cover_description: "Automatitza la facturació dels teus clients amb un sistema de pagament a taula. Estalvia temps i augmenta la rendibilitat del teu negoci.",
        restoo_description: "El teu Llibre de Reserves Intel·ligent. Sense comissions. Gestiona les teves reserves i planifica serveis fàcilment. Coneix les preferències de tots els teus clients i construeix la seva fidelitat automàticament.",
        dentalink_description:
          "Dentalink és el programari ideal per a la gestió de consultoris dentals. Simplifica les teves tasques diàries, enforteix la relació amb els teus pacients i incrementa la rendibilitat de la teva clínica. Diu adéu al paper!",
        colzent_description: "Sistema integral de gestió de flotes de vehicles amb gestió de conductors, reserves i serveis, geolocalització en temps real de vehicles i serveis, serveis de veu, assistència a conductors i/o clients i molt més...",
        golf_description: "Golfmanager és l'únic programari 100% basat en el núvol que permet gestionar totes les necessitats operatives d'un club de golf en un sol programa. Disseny modern i una experiència de reserva sense igual.",
        geolocation: "Geolocalització",
        google_description: "Navega pel món més fàcil i ràpidament amb Google Maps. Hi ha mapes de més de 220 països i territoris amb milions de negocis!",
        netelip_description: "Telefonia IP per a empreses de netelip. Operador líder de Veu IP o VoIP a Espanya i Llatinoamèrica amb una àmplia gamma de solucions.",
        twilio_description: "Twilio potencia interaccions personalitzades i comunicacions globals fiables per connectar amb els clients.",
        sendgrid_description: "SendGrid entrega els teus correus electrònics transaccionals i de màrqueting a través de la plataforma de lliurament de correu electrònic basada a la núvol més gran del món. Envia amb confiança.",
        hubspot_description:
          "La plataforma CRM de HubSpot inclou totes les eines i integracions que necessites per a màrqueting, vendes, gestió de continguts, servei al client i operacions. Cada producte és potent per si mateix; junts, són incomparables.",
        select_customer: "Si us plau, selecciona un client",
        install: "Instal·lar",
        already_installed: "Això ja està instal·lat",
        integrations: "Integracions",
        installed: "Instal·lat",
        add_new_integration: "Afegir nova integració",
        company: "Empresa",
        extra_data: "Dades addicionals",
        user: "Usuari",
        list: "Llista",
        new_notification: "Nova notificació",
        frequency: "Freqüència",
        general: "General",
        conversations: "Converses",
        conversation: "Converse",
        chats: "Xats",
        marketplace: "Mercat",
        settings: "Configuració",
        search_number: "Cerca un número exacte",
        show: "Mostra",
        previous: "Anterior",
        page: "Pàgina",
        of: "de",
        filter: "Filtrar",
        clean: "Netejar",
        no_calls: "No hi ha trucades per mostrar",
        assistant: "Assistent",
        client: "Client",
        today: "Avui",
        yesterday: "Ahir",
        more: "Més",
        no_number: "El número que estàs cercant no existeix",
        next: "Següent",
        no_chats: "No hi ha xats per mostrar",
        select_whats: "Si us plau, selecciona el teu compte de WhatsApp Business",
        send_whats: "Envia un WhatsApp",
        call: "Trucada",
        search: "Cercar",
        help_support: "Centre de Suport d'Ajuda",
        search_help: "Cerca qualsevol pregunta o tema d'ajuda",
        getting_started: "Començant",
        download: "Descarregar",
        installation: "Instal·lació",
        accessibility: "Accessibilitat",
        supported_devices: "Dispositius Compatibles",
        npm_installation: "Instal·lació de NPM",
        common_errors: "Errors Comuns en la Instal·lació",
        npm_windows: "NPM per a Windows",
        cannot_install: "No es pot instal·lar NPM a Mac",
        not_recognize: "NPM no es reconeix localment",
        npm_version: "La versió de NPM no es mostra en executar la comanda",
        bootstrap_ins: "Instal·lació de Bootstrap",
        migrating: "Migració des de Bootstrap 3",
        webpack: "Bootstrap utilitzant Webpack",
        react_components: "Components de Bootstrap per a React",
        example_bootstrap: "Exemple de pàgina Bootstrap amb barra de navegació",
        sass_custom: "Personalització de SASS",
        min_sass: "Minimitza SASS a CSS sense Bootstrap",
        more_sass: "Afegir classes d'utilitat addicionals en SASS",
        pricing_billing: "Preus i Facturació",
        features: "Característiques",
        search_people: "Cercar persones",
        help_center: "Centre d'Ajuda",
        find_integrations: "Descobreix les nostres integracions",
        preferences: "Preferències",
        language: "Idioma",
        select_or_drop: "Selecciona o arrossega la foto per pujar-la",
        select_venue: "Selecciona un local",
        business_details: "Detalls del negoci",
        channel_setup: "Configuració del canal",
        subscriptions: "Subscripcions",
        active: "Actiu",
        phone_lines: "Línies telefòniques",
        primary_line: "Línia principal",
        this_is_main_line: "Aquesta és la teva línia telefònica principal del restaurant perquè els clients es posin directament en contacte",
        number: "Número",
        provider: "Proveïdor",
        type: "Tipus",
        bot_line: "Línia Bot",
        number_asigned_bot: "Aquest és el número de telèfon assignat al bot",
        handover_line: "Línia de transferència",
        handover_description: "Aquest és el telèfon utilitzat en cas que el client vulgui ser transferit al restaurant",
        label: "Etiqueta",
        call_routing: "Encaminament de trucades",
        call_routing_rule: "Aquestes són les regles d'encaminament de trucades",
        last_call_was: "La darrera trucada va ser",
        transfer_to_bot: "Transfereix al bot",
        call_assigned_bot: "La trucada està assignada a aquest bot",
        trigger: "Disparador",
        when_bot_activates: "Quan s'activa el bot",
        try_it: "Prova-ho",
        inactive: "Inactiu",
        whatsapp_settings_description: "Per tenir el teu assistent operatiu, necessitem configurar els teus canals de comunicació. Comença a configurar-ho aquí, o posa't en contacte amb el teu agent de suport si no estàs segur.",
        copy_settings: "Copia configuracions",
        setup: "Configura",
        basic_info: "Informació bàsica",
        info_from_google: "Aquesta informació es pren de Google",
        website: "Lloc web",
        timezone: "Fus horari",
        booking_software: "Programari de reserves",
        schedules: "Horaris",
        sync_google: "Sincronitza amb Google Maps",
        updates_at_1pm: "L'horari s'actualitza cada dia a la 1 del migdia.",
        closed: "Tancat",
        same_every_day: "El mateix cada dia",
        apply_to_all: "Aplica a tots",
        add_slot: "Afegeix franja",
        all_days: "Tots els dies",
        media: "Mitjans",
        upload_pic: "Puja la foto del teu restaurant i utilitza-la com a foto de perfil o la foto de WhatsApp",
        use_profile: "Utilitza com a foto de perfil",
        use_whatsapp: "Utilitza com a foto de WhatsApp",
        contact_channels: "Canals de contacte",
        info_send_notifications: "Utilitzem aquesta informació per enviar notificacions",
        alternative_phone_number: "Número de telèfon alternatiu",
        social_networks: "Xarxes socials",
        news_email: "Rep butlletí per correu electrònic",
        marketing_whatsapp: "Rep missatges de màrqueting de bookline via WhatsApp",
        advanced: "Avançat",
        personalized_exp: "Proporcionar aquesta informació addicional ajudarà a millorar l'experiència personalitzada",
        options_restaurant: "Selecciona opcions que apliquen al teu restaurant",
        food_tolerance: "Preferència alimentària i tolerància",
        vegetarian_opt: "Oferim opcions vegetarianes",
        vegan_opt: "Oferim opcions veganes",
        lactose_opt: "Oferim opcions sense lactosa",
        gluten_opt: "Tenim opcions sense gluten",
        parking: "Aparcament",
        own_parking: "Tenim aparcament propi",
        parking_nearby: "Aparcament a prop",
        valet_parking: "Tenim servei de valet",
        easy_parking: "Fàcil d'aparcar?",
        wheel_access: "Oferim accés per a cadira de rodes",
        pet_friendly: "Acceptem animals",
        delivery: "Lliurament",
        deliver_home: "Fem lliuraments a domicili",
        update: "Actualitza",
        plan_name: "Nom del pla",
        voice_assistant: "Assistent de veu",
        plan_cost: "Cost del pla",
        date_activated: "Data d'activació",
        add_on: "Extensió",
        survey: "Enquesta",
        booking_reminder: "Recordatori de reserva",
        phone_number: "Número de telèfon",
        edit: "Edita",
        add: "Afegeix",
        call_to: "Trucada a",
        group_booking: "Reserva de grup",
        search_name: "Cerca un nom",
        voice: "Veu",
        pets: "Mascotes",
        open: "Obert",
        to: "a",
        type_link_here: "escriu l'enllaç aquí",
        type_number_here: "escriu el número aquí",
        day: "dia",
        schedule: "horari",
        voice_channel_setup: "Configuració del Canal de Veu",
        phone_provider: "Proveedor de Telèfon",
        step1_description1: "Per implementar un bot de veu de manera perfecta a les operacions del seu negoci, un pas inicial crucial implica establir la integració amb el seu proveïdor de telèfon.",
        step1_description2: "Si no troba el seu proveïdor a la llista o no està segur, ho descobrirem per vostè.",
        select_phone_provider: "Seleccioneu el vostre proveïdor de telèfon",
        provider_no_centralita: "Alguns proveïdors sense opció de centralita",
        other: "Altres",
        dont_have_provider: "Encara no tinc un proveïdor",
        use_centralita: "Utilitza la seva centralita?",
        yes: "Sí",
        no: "No",
        unsure: "No estic segur",
        provide_company_details: "Proporcioni detalls de l'empresa",
        type_name: "escriure el nom",
        contact_person: "Persona de contacte",
        contact_number: "Número de contacte",
        type_number: "escriure el número",
        type_email: "escriure el correu electrònic",
        step2_description: "Rutegi les trucades al recurs adequat, ja sigui un representant de suport al client, un departament específic o un sistema automatitzat.",
        when_trigger_bot: "Quan vol que s'activi el bot?",
        when_unavailable: "Quan no estigui disponible",
        when_line_busy: "Quan la línia estigui ocupada o el restaurant no respongui",
        by_schedules: "Per horaris",
        by_ivr: "Després d'una selecció a l'IVR actual",
        step3_description: "Rutegi les trucades al recurs adequat, ja sigui un representant de suport al client, un departament específic o un sistema automatitzat.",
        where_clients_call: "On vol que els seus clients el truquin?",
        bot_number: "Número del bot",
        recommended: "Recomanat",
        restaurant_number: "Número del restaurant",
        important: "Important!",
        further_setup: "Aquesta opció podria requerir més configuració per a la línia de transferència.",
        another_number: "Número propi",
        handover: "Transferència",
        handover_step_description: "La trucada no es pot transferir de tornada al número que el client està trucant. Hem de desplegar una línia diferent per evitar la creació de bucles infinits.",
        where_transfer: "Si un client ho demana, on vol que es transfereixi la trucada?",
        different_number: "A un número diferent",
        add_destinations: "Afegir destinacions",
        queue: "Cua",
        add_more: "Afegir més",
        never_transfer_calls: "Mai transferir trucades",
        some_provider: "Alguns proveïdors",
        no_provider: "Sense proveïdor",
        setup_summary: "Resum de Configuració",
        summary_step_description: "Un cop enviat, encara podrà editar-ho més endavant des del portal del client.",
        below_info_provided: "A continuació, es mostra un resum de la informació proporcionada",
        setup_successful: "Configuració amb èxit",
        pending_tech_review: "Pendent de revisió tècnica.",
        tech_review_description: "Un agent de suport al client es posarà en contacte quan el canal de veu estigui actiu.",
        centralita: "Centralita",
        direct: "Directe",
        go_to_portal: "Anar al portal",
        all_channels: "Tots els canals",
        pending: "Pendent",
        accepted: "Acceptat",
        rejected: "Rebutjat",
        archived: "Arxivat",
        booking: "Reserva",
        requests: "Sol·licituds",
        all: "Tots",
        custom: "Personalitzat",
        filter_by: "Filtrar per",
        channel: "Canal",
        status: "Estat",
        request_type: "Tipus de sol·licitud",
        clear: "Netejar",
        order_by: "Ordenar per",
        booking_type: "Tipus de reserva",
        from: "Des de",
        pax: "PAX",
        time: "Hora",
        received_on: "Rebut el",
        restaurant_name: "Nom del restaurant",
        client_details: "Detalls del client",
        see_client_record: "Veure registre del client",
        request_details: "Detalls de la sol·licitud",
        comments: "Comentaris",
        accept: "Acceptar",
        reject: "Rebutjar",
        call_details: "Detalls de la trucada",
        summary: "Resum",
        timeline: "Cronologia",
        transcript: "Transcripció",
        call_start: "Inici de la trucada",
        call_end: "Fi de la trucada",
        call_duration: "Durada de la trucada",
        reason_identified: "Motiu identificat",
        booking_details: "Detalls de la reserva",
        confirmation: "Confirmació",
        sent: "Enviat",
        incoming_call: "Trucada entrant",
        call_ended: "Trucada finalitzada",
        search_by_phone_number: "Cercar per número de telèfon",
        in_review: "En revisió",
        cross_selling: "venda creuada",
        reminders: "recordatoris",
        products: "Productes",
        add_new: "Afegir nou",
        my_products: "Els meus productes",
        search_by_name: "Cercar assistent per nom",
        booking_management: "Gestió de reserves",
        date: "Data",
        booking_confirmed: "Reserva confirmada",
        main: "Principal",
        add_ons: "Complements",
        collapse_all: "Col·lapsar tot",
        expand_all: "Expandir tot",
        personality: "Personalitat",
        personality_description: "Dóna-li personalitat amb un nom, trets i una cara",
        avatar: "Avatar",
        avatar_description: "Tria una foto d’avatar per al teu assistent o puja la teva",
        traits: "Trets",
        traits_description: "Tria uns quants trets de personalitat que t’agradaria que el teu assistent tingués",
        playful: "juganer",
        serious: "seriós",
        funny: "divertit",
        friendly: "amigable",
        calm: "calmat",
        sarcastic: "sarcàstic",
        rude: "groller",
        kinky: "picant",
        gentle: "suau",
        sonofagun: "fill de gos",
        excited: "emocionat",
        languages_and_voice: "Llengües i Veu",
        languages_description: "Fes que parli els idiomes que vulguis i amb la veu que t’agradi",
        default_language: "idioma predeterminat",
        default_voice: "veu predeterminada",
        activate_language_detention: "Activa la detecció d’idioma",
        how_detect_language: "Com vols que es detecti l’idioma?",
        by_phone_prefix: "Per prefix telefònic",
        by_telling_assistant: "Dient explícitament a l’assistent que volen parlar en un cert idioma",
        add_more_languages: "Afegir més idiomes",
        greeting: "Salutació",
        greeting_description: "El primer contacte farà una impressió",
        turn_greeting_options: "Activa opcions de salutació que haurien d’aplicar-se al teu restaurant",
        different_greeting: "M’agradaria que l’assistent utilitzés una salutació diferent per a cada moment del dia",
        formal_greeting: "Vull que el meu assistent s’adreci de manera formal",
        name_greeting: "M’agradaria que el meu assistent saludés els clients pel seu nom",
        introduce_greeting: "Vull que el meu assistent es presenti",
        preview: "Vista prèvia",
        reservation_policy: "Política de reserves",
        diners_policy: "Política per a comensals",
        diners_description: "Gestiona reserves de manera diferent depenent del nombre de comensals",
        max_diners: "Màx. comensals per reserva",
        transfer_calls_more_diners: "Transfereix trucades si hi ha més comensals",
        transfer_to: "transferir a",
        custom_message_transfer: "missatge personalitzat per a transferència",
        bc_number_diners_transfer: "A causa del nombre de comensals, et posaré en contacte amb un agent",
        contract_group_booking: "Contractar gestió de reserves per a grups",
        contract_group_booking_description: "Contracta l’addició de reserves per a grups per facilitar les reserves per a grups grans, sense haver de transferir al restaurant",
        time_policy: "Política de temps",
        time_policy_description: "Mantén el client informat sobre la política de temps per evitar malentesos",
        booking_warranty: "Temps de garantia de reserva (min)",
        notify_about_warranty: "Notifica sobre el temps de garantia de reserva",
        custom_message_warranty: "missatge personalitzat per al temps de garantia",
        coming_late_no_guarantee: "Tingues en compte que si arribes més tard de 15 minuts, ja no podem garantir la taula per a tu",
        table_availability: "temps de disponibilitat de taula (min)",
        notify_about_availability: "Notifica sobre el temps de disponibilitat de taula",
        custom_message_availability: "missatge personalitzat per al temps de disponibilitat de taula",
        enjoy_table_limit_time: "T’recordem amablement que pots gaudir de la taula per no més de 1h30",
        others: "Altres",
        other_possible_settings: "Altres configuracions possibles",
        zones: "zones",
        select_zones_venue: "Selecciona zones que s’apliquen al teu local",
        inside: "Interior",
        terrace: "Terrassa",
        patio: "Pati",
        garden: "Jardí",
        bar: "Bar",
        other_zones: "altres zones",
        type_more_zones: "Escriu més zones, p. ex. sala VIP, soterrani",
        credit_card_policy: "Política de targeta de crèdit",
        have_credit_card_policy: "Tinc una política de targeta de crèdit",
        first_contact_make_impression: "El primer contacte farà una impressió",
        enable_cross_selling: "Habilita la venda creuada",
        venues_recommend_ifnot_available: "Selecciona els locals que t’agradaria recomanar en cas que l’inicial no estigui disponible",
        enable_group_bookings: "Habilita reserves per a grups",
        max_days_advanced: "màx. dies d’antelació",
        what_else: "Què més?",
        group_bookings: "Reserva de grup",
        input_example_diners: "A causa del nombre de comensals, et posaré en contacte amb un agent que pot gestionar la teva reserva a partir d’ara",
        handover_schedule: "Horari de transferència",
        handover_schedule_description: "Horaris quan les trucades es transfereixen a aquest número",
        whatsapp_channel_setup: "configuració del canal de WhatsApp",
        venue: "Local",
        apply_same_to_all_venues: "Pots aplicar el mateix compte de WhatsApp a diversos locals.",
        specific_or_multiple_venues: "És el compte de WhatsApp per a un local específic o diversos?",
        specific: "Específic",
        select_a_venue: "Selecciona un local",
        multiple: "Múltiples",
        select_venues_that_apply: "Selecciona els locals que apliquen",
        whatsapp_step2_description: "Utilitza el mateix nom que fas servir perquè els clients truquin al teu restaurant o usa'n un d'altre. De qualsevol manera, podem configurar-ho per tu.",
        which_phone_number_use: "Quin número de telèfon t'agradaria utilitzar?",
        new_number: "Número nou",
        we_provide_and_assign: "Et proporcionem i assignem un número de WhatsApp",
        use_same_number_as_bot: "Pots utilitzar el mateix número que el bot de veu",
        venue_number: "Número del lloc",
        taken_from_google: "presa de Google",
        type_phone_number: "Escriu el número de telèfon",
        whatsapp_account: "Compte de WhatsApp",
        whatsapp_step3_description: "Per poder desplegar un assistent de WhatsApp necessites utilitzar WhatsApp API. Tria l'opció que aplica i podem guiar-te a través de cada pas del procés.",
        does_number_have_account: "Aquest número ja té un compte de WhatsApp?",
        whatsapp_personal_use: "Sí, WhatsApp App (per a ús personal)",
        personal_app: "WhatsApp és l'app per a usuaris privats, la que sempre hem tingut.",
        whatsapp_business: "Sí, WhatsApp Business App",
        business_app: "WhatsApp Business és l'aplicació oficial per a negocis petits sense complexitat excessiva.",
        whats_business_api: "Sí, WhatsApp Business API",
        business_api_description: "WhatsApp Business API és la interfície que permet la creació d'experiències i serveis complexos com el chatbot.",
        no_whats_this_number: "No tinc WhatsApp vinculat a aquest número",
        not_sure_need_guide: "No estic segur",
        // not_sure_need_guide: "No estic segur, podeu guiar-me?",
        book_a_meeting: "Reserva una reunió",
        with_support_team: "amb el nostre equip de suport.",
        api_provider: "Proveïdor d'API",
        whats_step_4_description: "Per poder desplegar un assistent de WhatsApp necessites utilitzar WhatsApp API. Tria l'opció que aplica i podem guiar-te a través de cada pas del procés.",
        whats_your_provider: "Quin és el teu proveïdor?",
        whats_summary_step_description: "La teva sol·licitud ha estat enviada i l'equip de suport es posarà en contacte amb tu amb més informació",
        request_submitted: "Sol·licitud enviada",
        can_receive_sms: "Puc rebre SMS o trucades telefòniques en aquest número.",
        whats_step_4_bis_description: "Per poder desplegar un assistent de WhatsApp necessites utilitzar WhatsApp API. Tria l'opció que aplica i podem guiar-te a través de cada pas del procés.",
        need_delete_account: "Necessites eliminar el teu compte de WhatsApp",
        if_delete_number: "Tingues en compte que si elimines el teu número no podràs",
        send_receive_messages: "enviar o rebre nous missatges a l'app",
        cant_see_chat_history: "no pots veure l'historial de xat",
        ready_to_reset: "Entès, estic llest per reiniciar-la",
        not_sure_need_help: "No estic segur, podeu ajudar-me?",
        personal: "Personal",
        no_wa_lnked: "No hi ha WhatsApp vinculat a aquest número",
        ready_api_onb: "Llest per a l'onboarding d'API",
        proceed_api_onb: "Ara pots procedir amb l'onboarding d'API connectant-te a 360Dialog.",
        book_meeting_support_team: "Tens dubtes? Reserva una reunió amb el nostre equip de suport.",
        agent_get_in_touch: "Un agent de suport al client es posarà en contacte tan aviat com sigui possible amb els següents passos.",
        type_provider_name: "Escriu el nom del proveïdor",
        type_name_here: "Escriu el nom aquí",

        summary_description: "Aquest és un resum de la informació que has proporcionat, verifica que tot estigui correcte abans de crear el teu assistent o pots tornar enrere i fer els canvis que necessitis.",
        customer: "Client",

        booking_manager: "Llibre de reserves",
        add_other_restaurants: "Vols afegir altres restaurants?",

        do_you_have_switchboard: "Tens centraleta?",
        when_should_assistant_start: "Quan vols que salti l'assistent?",

        selected_languages: "Idiomes seleccionats",
        and: " i ",

        assistant_gender: "Gènere de l'assistent",
        female: "Femení",
        male: "Masculí",

        formality_preference: "Quin tracte prefereixes donar al client?",

        notify_warranty_time: "Vols avisar del temps de garantia de la reserva?",

        minutes: "minuts",
        ask_anything_else: "Vols que l'assistent pregunti alguna cosa més?",
        send_confirmation: "Vols enviar una confirmació de la reserva?",
        add_anything_else: "Vols afegir alguna cosa més?",
        create: "Crear",
      },
    },
    it: {
      translation: {
        tech_review_1: "Un agente dell'assistenza clienti ti contatterà al più presto con i passaggi successivi. Se hai dubbi o domande, ",
        delete_photo: "Elimina foto",
        select_photo: "Seleziona o rilascia la foto da caricare come immagine del profilo",
        industry: "Industria",
        address: "Indirizzo",
        description: "Descrizione",
        about: "A proposito",
        done: "Fatto",
        clients: "Clienti",
        support: "Supporto",
        no_wa_linked: "Non c'è WhatsApp collegato a questo numero",
        account_settings: "Impostazioni",
        activity: "Attività",
        live_calls: "Chiamate dal vivo",

        business_experience_start: "L'esperienza nel tuo business inizia al",
        first_contact: "primo contatto.",
        welcome_to_bookline: "Benvenuto su Bookline, ",
        assistant_setup_intro: "Per garantire che l'assistente sia perfettamente adattato e configurato in base alle tue preferenze e necessità specifiche, ti chiediamo di dedicare qualche minuto a completare questo questionario.",
        assistant_setup_intro_continued: "Capire che potresti essere occupato; quindi, se non riesci a finirlo ora, nessun problema. Puoi mettere in pausa e riprendere il processo da dove l'hai lasciato in qualsiasi momento.",
        start: "Iniziare",
        write_here: "Scrivi qui",
        ask_conf_notif: "Vuoi inviare una conferma della prenotazione al cliente?",
        yes_mkt: "Sì, con WhatsApp con pubblicità di bookline",
        free: "gratuito",
        yes_paid: "Sì, con WhatsApp senza pubblicità",
        yes_sms: "Sì, con SMS",
        dont_want: "Non voglio",
        anything_else: "Qualcos'altro che vuoi dirci?",
        anything_else2: "Se non sei sicuro, puoi indicarlo più tardi",
        ask_warranty: "Vuoi indicare il tempo di garanzia della prenotazione?",
        warranty_placeholder: "Indica il tempo di garanzia in minuti",
        extra_q: "Vuoi che l'assistente chieda o dica qualcos'altro?",
        extra_q2: "Se non sei sicuro, puoi indicarlo più tardi",
        alergies: "Qualche commento aggiuntivo come allergie, intolleranze o richieste speciali che vuoi indicare?",
        extra_highchair: "Se vieni con bambini e hai bisogno di seggioloni, ti preghiamo di specificare quanti ne hai bisogno.",
        ask_how_know_us: "Come ci hai conosciuto?",
        ask_disca: "Viene qualcuno con mobilità ridotta?",
        extra_placeholder: "Indica cos'altro vuoi che l'assistente dica. Puoi aggiungere più frasi o domande.",
        ask_warranty_time: "Indica il tempo di garanzia in minuti",
        ask_formality: "Quale formalità preferisci usare con il cliente?",
        you: "Tu",
        you2: "Lei",
        assistant_title: "Ora configuriamo il tuo assistente, scegli le lingue che vuoi che il tuo assistente parli",
        assistant_title2: "Puoi selezionare la lingua predefinita in seguito",
        spanish: "Spagnolo",
        english: "Inglese",
        catalan: "Catalano",
        french: "Francese",
        italian: "Italiano",
        german: "Tedesco",
        portuguese: "Portoghese",
        lang_detection: "Come vuoi che venga rilevata la lingua?",
        by_prefix: "Per prefisso telefonico del cliente",
        by_command: "Dicendo esplicitamente all'assistente di parlare in una certa lingua",
        default_lang: "Scegli la lingua predefinita del tuo assistente",
        default_lang2: "Per questa lingua puoi configurare il tipo di voce",
        assistant_voice: "Scegli la voce del tuo assistente",
        assistant_voice2: "Personalizza l'esperienza uditiva per i tuoi clienti",
        phone_placeholder: "Inserisci il tuo numero di telefono",

        ask_centralita: "Hai un centralino?",
        ask_centralita2: "Indica se hai un sistema di comunicazione integrato, altrimenti indica la tua compagnia telefonica",
        ask_phone_provider: "Seleziona il tuo fornitore di telefonia",
        ask_centralita_provider: "Seleziona il tuo fornitore",
        idk: "Non lo so",
        when_answer: "Quando vuoi che inizi l'assistente?",
        ask_2nd_line: "Per favore, fornisci una seconda linea per trasferire la chiamata al ristorante. Se non ne hai una, lascia il riquadro vuoto e un agente ti contatterà in seguito per offrirti una soluzione.",
        always: "Sempre",
        if_no_answer: "Se non rispondi al ristorante",
        franjas: "Fasce orarie",
        confirm_data: "Conferma i dati del ristorante",
        verify: "Assicurati che tutte le informazioni siano corrette prima di continuare",
        tel: "Telefono",
        other_rests: "Hai altri ristoranti che vorresti aggiungere?",
        add_others: "Per favore, scrivi i nomi separati da una virgola",
        ask_bm: "Quale sistema di prenotazione usi?",
        ask_bm2: "Gestire le prenotazioni è molto più semplice con una piattaforma dedicata. Tuttavia, se non ne hai una, non è un problema, sappiamo come lavorare senza!",
        clinic: "Clinica",
        taxi: "Stazione dei taxi",
        club: "Club sportivo",
        ask_restaurant: "Qual è il tuo ristorante?",
        search_restaurant: "Troviamo il tuo ristorante",
        cant_find: "Non riesci a trovarlo?",
        rest_placeholder: "Scrivi il nome del tuo ristorante",

        title: "Applicazione in diverse lingue",
        venues: "Locali",
        diversion_instructions: "Istruzioni di deviazione",
        show_stats: "Mostra statistiche",
        edit_bot: "Modifica Bot",
        last_call: "L'ultima chiamata è stata",
        phone_settings: "Impostazioni del telefono",
        close: "Chiudi",
        billing: "Fatturazione",
        account: "Account",
        create_account: "Crea account",
        create_new_account: "Crea un nuovo account",
        legal_information: "Informazioni legali",
        company_name: "Nome dell'azienda",
        tax_id: "P. IVA",
        partner_name: "Nome del partner",
        partner_id: "C.F. del partner",
        email: "Email",
        tax_address: "Indirizzo fiscale",
        city: "Città",
        postal_code: "Codice postale",
        payment_method: "Metodo di pagamento",
        payment_type: "Tipo di pagamento",
        direct_debit: "Addebito diretto",
        bank: "Banca",
        account_holder: "Nome del titolare del conto",
        account_number: "Numero di conto",
        currency: "Valuta",
        update_payment_method: "Aggiorna metodo di pagamento",
        payment_settings: "Configura il tuo metodo di pagamento configurando il Direct Debit tramite",
        cancel: "Annulla",
        field_mandatory: "Tutti i campi sono obbligatori",
        not_valid_email: "Email non valida",
        team: "Squadra",
        invite_user: "Invita utente",
        name: "Nome",
        phone: "Numero di telefono",
        role: "Ruolo",
        copy_link: "Copia link",
        send: "Invia",
        assistants: "Assistenti",
        add_new_bot: "Aggiungi nuovo bot",
        restaurant: "Ristorante",
        online: "Online",
        offline: "Offline",
        calls: "Chiamate",
        save: "Salva",
        share: "Condividi",
        add_new_ticket: "Aggiungi un nuovo biglietto",
        notifications: "Notifiche",
        whats_description: "Soluzioni di Messaggistica Mobile e Automazione Mobile e API · Messaggistica WhatsApp",
        reservations: "Prenotazioni",
        cover_description: "Automatizza la fatturazione dei tuoi clienti con un sistema di pagamento al tavolo. Risparmia tempo ed aumenta la redditività del tuo business.",
        restoo_description: "Il tuo Libro delle Prenotazioni Intelligente. Senza commissioni. Gestisci facilmente le prenotazioni e pianifica i servizi. Scopri le preferenze di tutti i tuoi clienti e costruisci la loro fedeltà automaticamente.",
        dentalink_description: "Dentalink è il software ideale per la gestione degli studi dentistici. Semplifica le tue attività quotidiane, rafforza la relazione con i tuoi pazienti e aumenta la redditività della tua clinica. Addio alla carta!",
        colzent_description:
          "Sistema integrato di gestione delle flotte veicolari con gestione dei conducenti, prenotazioni e servizi, geolocalizzazione in tempo reale di veicoli e servizi, servizi vocali, assistenza ai conducenti e/o clienti e molto altro...",
        golf_description: "Golfmanager è l'unico software al 100% basato su cloud che consente di gestire tutte le esigenze operative di un club di golf in un unico programma. Design moderno e un'esperienza di prenotazione senza pari.",
        geolocation: "Geolocalizzazione",
        google_description: "Naviga nel mondo in modo più facile e veloce con Google Maps. Ci sono mappe di oltre 220 paesi e territori con milioni di attività!",
        netelip_description: "Telefonia IP per le aziende di netelip. Operatore leader di Voce IP o VoIP in Spagna e America Latina con una vasta gamma di soluzioni.",
        twilio_description: "Twilio potenzia interazioni personalizzate e comunicazioni globali affidabili per connetterti con i clienti.",
        sendgrid_description: "SendGrid consegna le tue email transazionali e di marketing attraverso la piattaforma di consegna email basata su cloud più grande al mondo. Invia con fiducia.",
        hubspot_description:
          "La piattaforma CRM di HubSpot include tutte le strumenti e le integrazioni di cui hai bisogno per il marketing, le vendite, la gestione dei contenuti, il servizio clienti e le operazioni. Ciascun prodotto è potente da solo; insieme, sono incomparabili.",
        select_customer: "Seleziona un cliente",
        install: "Installa",
        already_installed: "È già installato",
        integrations: "Integrazioni",
        installed: "Installato",
        add_new_integration: "Aggiungi nuova integrazione",
        company: "Azienda",
        extra_data: "Dati aggiuntivi",
        user: "Utente",
        list: "Elenco",
        new_notification: "Nuova notifica",
        frequency: "Frequenza",
        general: "Generale",
        conversations: "Conversazioni",
        conversation: "Conversazione",
        chats: "Chat",
        marketplace: "Mercato",
        settings: "Impostazioni",
        search_number: "Cerca un numero esatto",
        show: "Mostra",
        previous: "Precedente",
        page: "Pagina",
        of: "di",
        filter: "Filtro",
        clean: "Pulisci",
        no_calls: "Nessuna chiamata da mostrare",
        assistant: "Assistente",
        client: "Cliente",
        today: "Oggi",
        yesterday: "Ieri",
        more: "Di più",
        no_number: "Il numero che stai cercando non esiste",
        next: "Successivo",
        no_chats: "Nessuna chat da mostrare",
        select_whats: "Seleziona il tuo account WhatsApp Business",
        send_whats: "Invia un messaggio WhatsApp",
        call: "Chiamata",
        search: "Cerca",
        help_support: "Centro di Supporto e Assistenza",
        search_help: "Cerca domande o argomenti di assistenza",
        getting_started: "Primi Passi",
        download: "Scarica",
        installation: "Installazione",
        accessibility: "Accessibilità",
        supported_devices: "Dispositivi Supportati",
        npm_installation: "Installazione NPM",
        common_errors: "Errori Comuni durante l'Installazione",
        npm_windows: "NPM per Windows",
        cannot_install: "Impossibile installare NPM su Mac",
        not_recognize: "NPM non riconosce in locale",
        npm_version: "La versione di NPM non viene visualizzata quando si esegue il comando",
        bootstrap_ins: "Installazione di Bootstrap",
        migrating: "Migrazione da Bootstrap 3",
        webpack: "Bootstrap utilizzando Webpack",
        react_components: "Componenti Bootstrap per React",
        example_bootstrap: "Esempio di pagina Bootstrap con barra di navigazione",
        sass_custom: "Personalizzazione di SASS",
        min_sass: "Minimizza SASS in CSS senza Bootstrap",
        more_sass: "Aggiunta di classi di utilità aggiuntive in SASS",
        pricing_billing: "Prezzi e Fatturazione",
        features: "Caratteristiche",
        search_people: "Cercare persone",
        help_center: "Centro di Assistenza",
        find_integrations: "Scopri le nostre integrazioni",
        preferences: "Preferenze",
        language: "Lingua",
        select_or_drop: "Seleziona o trascina la foto da caricare",
        select_venue: "Seleziona un locale",
        business_details: "Dettagli dell'attività",
        channel_setup: "Impostazione del canale",
        subscriptions: "Abbonamenti",
        active: "Attivo",
        phone_lines: "Linee telefoniche",
        primary_line: "Linea principale",
        this_is_main_line: "Questa è la tua linea telefonica principale del ristorante per i clienti per mettersi direttamente in contatto",
        number: "Numero",
        provider: "Fornitore",
        type: "Tipo",
        bot_line: "Linea Bot",
        number_asigned_bot: "Questo è il numero di telefono assegnato al bot",
        handover_line: "Linea di trasferimento",
        handover_description: "Questo è il telefono utilizzato nel caso in cui il cliente voglia essere trasferito al ristorante",
        label: "Etichetta",
        call_routing: "Inoltro delle chiamate",
        call_routing_rule: "Queste sono le regole di inoltro delle chiamate",
        last_call_was: "L'ultima chiamata era",
        transfer_to_bot: "Trasferisci al bot",
        call_assigned_bot: "La chiamata è assegnata a questo bot",
        trigger: "Innesco",
        when_bot_activates: "Quando il bot viene attivato",
        try_it: "Provalo",
        inactive: "Inattivo",
        whatsapp_settings_description: "Per avere il tuo assistente operativo, dobbiamo configurare i tuoi canali di comunicazione. Inizia a configurarlo qui, o mettiti in contatto con il tuo agente di supporto se non sei sicuro.",
        copy_settings: "Copia impostazioni",
        setup: "Configura",
        basic_info: "Informazioni di base",
        info_from_google: "Queste informazioni sono prese da Google",
        website: "Sito web",
        timezone: "Fuso orario",
        booking_software: "Software di prenotazione",
        schedules: "Orari",
        sync_google: "Sincronizza con Google Maps",
        updates_at_1pm: "L'orario viene aggiornato ogni giorno alle 13:00.",
        closed: "Chiuso",
        same_every_day: "Uguale ogni giorno",
        apply_to_all: "Applica a tutti",
        add_slot: "Aggiungi slot",
        all_days: "Tutti i giorni",
        media: "Media",
        upload_pic: "Carica la foto del tuo ristorante e usala come foto del profilo o la foto di WhatsApp",
        use_profile: "Usa come foto del profilo",
        use_whatsapp: "Usa come foto di WhatsApp",
        contact_channels: "Canali di contatto",
        info_send_notifications: "Utilizziamo queste informazioni per inviare notifiche",
        alternative_phone_number: "Numero di telefono alternativo",
        social_networks: "Reti sociali",
        news_email: "Ricevi newsletter via e-mail",
        marketing_whatsapp: "Ricevi messaggi di marketing di bookline via WhatsApp",
        advanced: "Avanzato",
        personalized_exp: "Fornire queste informazioni aggiuntive aiuterà a migliorare l'esperienza personalizzata",
        options_restaurant: "Seleziona le opzioni applicabili al tuo ristorante",
        food_tolerance: "Preferenze alimentari e tolleranze",
        vegetarian_opt: "Offriamo opzioni vegetariane",
        vegan_opt: "Offriamo opzioni vegane",
        lactose_opt: "Offriamo opzioni senza lattosio",
        gluten_opt: "Abbiamo opzioni senza glutine",
        parking: "Parcheggio",
        own_parking: "Parcheggio proprio",
        parking_nearby: "Parcheggio nelle vicinanze",
        wallet_parking: "Parcheggio con valet",
        easy_parking: "Facile da parcheggiare?",
        wheel_access: "Accesso per sedie a rotelle",
        pets: "Animali domestici",
        pet_friendly: "Siamo amici degli animali",
        delivery: "Consegna",
        deliver_home: "Consegna a domicilio",
        update: "Aggiorna",
        plan_name: "Nome del piano",
        voice_assistant: "Assistente vocale",
        plan_cost: "Costo del piano",
        date_activated: "Data di attivazione",
        add_on: "Componente aggiuntivo",
        survey: "Sondaggio",
        booking_reminder: "Promemoria di prenotazione",
        phone_number: "Numero di telefono",
        edit: "Modifica",
        add: "Aggiungi",
        call_to: "Chiama",
        group_booking: "Prenotazione di gruppo",
        search_name: "Cerca un nome",
        voice: "Voce",
        open: "Aperto",
        to: "a",
        type_link_here: "digita il link qui",
        type_number_here: "digita il numero qui",
        day: "giorno",
        schedule: "programma",
        voice_channel_setup: "Configurazione del Canale Vocale",
        phone_provider: "Fornitore Telefonico",
        step1_description1: "Per implementare un bot vocale in modo impeccabile nelle operazioni aziendali, un passo iniziale cruciale consiste nell'instaurare l'integrazione con il fornitore telefonico.",
        step1_description2: "Se non trovi il tuo fornitore nell'elenco o non sei sicuro, lo scopriremo per te.",
        select_phone_provider: "Seleziona il tuo fornitore telefonico",
        provider_no_centralita: "Alcuni fornitori senza opzione centralita",
        other: "Altro",
        dont_have_provider: "Non ho ancora un fornitore",
        use_centralita: "Utilizzi la loro centralita?",
        yes: "Sì",
        no: "No",
        unsure: "Non sono sicuro",
        provide_company_details: "Fornisci i dettagli dell'azienda",
        type_name: "inserisci il nome",
        contact_person: "Persona di contatto",
        contact_number: "Numero di contatto",
        type_number: "inserisci il numero",
        type_email: "inserisci l'email",
        step2_description: "Indirizza le chiamate alla risorsa appropriata, che sia un rappresentante del supporto clienti, un dipartimento specifico o un sistema automatizzato.",
        when_trigger_bot: "Quando vuoi che il bot venga attivato?",
        when_unavailable: "Quando non disponibile",
        when_line_busy: "Quando la linea è occupata o il ristorante non risponde",
        by_schedules: "Per orari",
        by_ivr: "Dopo una selezione sull'IVR corrente",
        step3_description: "Indirizza le chiamate alla risorsa appropriata, che sia un rappresentante del supporto clienti, un dipartimento specifico o un sistema automatizzato.",
        where_clients_call: "Dove vuoi che i tuoi clienti ti chiamino?",
        bot_number: "Numero del Bot",
        recommended: "Consigliato",
        restaurant_number: "Numero del Ristorante",
        important: "Importante!",
        further_setup: "Questa opzione potrebbe richiedere ulteriori configurazioni per la linea di passaggio.",
        another_number: "Proprio numero",
        handover: "Passaggio",
        handover_step_description: "La chiamata non può essere trasferita al numero che il cliente sta chiamando. Abbiamo bisogno di implementare una linea diversa per evitare la creazione di loop infiniti.",
        where_transfer: "Se un cliente lo richiede, dove vuoi che la chiamata venga trasferita?",
        different_number: "A un numero diverso",
        add_destinations: "Aggiungi destinazioni",
        queue: "Coda",
        add_more: "Aggiungi altro",
        never_transfer_calls: "Mai trasferire le chiamate",
        some_provider: "Alcuni fornitori",
        no_provider: "Nessun fornitore",
        setup_summary: "Riepilogo della Configurazione",
        summary_step_description: "Una volta inviato, potrai comunque modificarlo successivamente dal portale clienti.",
        below_info_provided: "Di seguito è riportato un riepilogo delle informazioni fornite",
        setup_successful: "Configurazione riuscita",
        pending_tech_review: "In attesa di revisione tecnica.",
        tech_review_description: "Un agente di supporto clienti si metterà in contatto quando il canale vocale sarà attivo.",
        centralita: "Centralita",
        direct: "Diretto",
        go_to_portal: "Vai al portale",
        all_channels: "Tutti i canali",
        pending: "In attesa",
        accepted: "Accettato",
        rejected: "Rifiutato",
        archived: "Archiviato",
        booking: "Prenotazione",
        requests: "Richieste",
        all: "Tutti",
        custom: "Personalizzato",
        filter_by: "Filtra per",
        channel: "Canale",
        status: "Stato",
        request_type: "Tipo di richiesta",
        clear: "Cancella",
        order_by: "Ordina per",
        booking_type: "Tipo di prenotazione",
        from: "Da",
        pax: "PAX",
        time: "Tempo",
        received_on: "Ricevuto il",
        restaurant_name: "Nome del ristorante",
        client_details: "Dettagli del cliente",
        see_client_record: "Vedi scheda cliente",
        request_details: "Dettagli della richiesta",
        comments: "Commenti",
        accept: "Accetta",
        reject: "Rifiuta",
        call_details: "Dettagli della chiamata",
        summary: "Sommario",
        timeline: "Cronologia",
        transcript: "Trascrizione",
        call_start: "Inizio chiamata",
        call_end: "Fine chiamata",
        call_duration: "Durata della chiamata",
        reason_identified: "Motivo identificato",
        booking_details: "Dettagli della prenotazione",
        confirmation: "Conferma",
        sent: "Inviato",
        incoming_call: "Chiamata in arrivo",
        call_ended: "Chiamata terminata",
        search_by_phone_number: "Cerca per numero di telefono",
        in_review: "In revisione",
        cross_selling: "vendita incrociata",
        reminders: "promemoria",
        products: "Prodotti",
        add_new: "Aggiungi nuovo",
        my_products: "I miei prodotti",
        search_by_name: "Cerca assistente per nome",
        booking_management: "Gestione prenotazioni",
        Date: "data",
        booking_confirmed: "Prenotazione confermata",
        main: "Principale",
        add_ons: "Componenti aggiuntivi",
        collapse_all: "Chiudi tutto",
        expand_all: "Espandi tutto",
        personality: "Personalità",
        personality_description: "Dagli personalità con un nome, tratti e un volto",
        avatar: "Avatar",
        avatar_description: "Scegli una foto avatar per il tuo assistente o carica la tua",
        traits: "Tratti",
        traits_description: "Scegli alcuni tratti di personalità che vorresti avesse il tuo assistente",
        playful: "giocoso",
        serious: "serio",
        funny: "divertente",
        friendly: "amichevole",
        calm: "calmo",
        sarcastic: "sarcastico",
        rude: "scortese",
        kinky: "piccante",
        gentle: "gentile",
        sonofagun: "figlio di buona donna",
        excited: "eccitato",
        languages_and_voice: "Lingue e Voce",
        languages_description: "Fagli parlare nelle lingue che desideri e con la voce che preferisci",
        default_language: "lingua predefinita",
        default_voice: "voce predefinita",
        activate_language_detention: "Attiva rilevamento lingua",
        how_detect_language: "Come vuoi che venga rilevata la lingua?",
        by_phone_prefix: "Per prefisso telefonico",
        by_telling_assistant: "Dicendo esplicitamente all’assistente che vogliono parlare in una certa lingua",
        add_more_languages: "Aggiungi altre lingue",
        greeting: "Saluto",
        greeting_description: "Il primo contatto farà impressione",
        turn_greeting_options: "Attiva le opzioni di saluto che dovrebbero applicarsi al tuo ristorante",
        different_greeting: "Vorrei che l’assistente usasse un saluto diverso per ogni momento della giornata",
        formal_greeting: "Voglio che il mio assistente si rivolga in modo formale",
        name_greeting: "Vorrei che il mio assistente salutasse i clienti per nome",
        introduce_greeting: "Voglio che il mio assistente si presenti",
        preview: "Anteprima",
        reservation_policy: "Politica di prenotazione",
        diners_policy: "Politica per i commensali",
        diners_description: "Gestisci prenotazioni in modo diverso a seconda del numero di commensali",
        max_diners: "Massimo di commensali per prenotazione",
        transfer_calls_more_diners: "Trasferisci chiamate se ci sono più commensali",
        transfer_to: "trasferisci a",
        custom_message_transfer: "messaggio personalizzato per il trasferimento",
        bc_number_diners_transfer: "A causa del numero di commensali, ti metterò in contatto con un operatore",
        contract_group_booking: "Contratto gestione prenotazioni di gruppo",
        contract_group_booking_description: "Contratta l’add-on di prenotazioni di gruppo per facilitare le prenotazioni per grandi gruppi, senza dover trasferire al ristorante",
        time_policy: "Politica sui tempi",
        time_policy_description: "Tieni informato il cliente sulla politica dei tempi per evitare malintesi",
        booking_warranty: "Tempo di garanzia della prenotazione (min)",
        notify_about_warranty: "Notifica sul tempo di garanzia della prenotazione",
        custom_message_warranty: "messaggio personalizzato per il tempo di garanzia",
        coming_late_no_guarantee: "Nota che se arrivi più tardi di 15 minuti, non possiamo più garantire il tavolo per te",
        table_availability: "tempo di disponibilità del tavolo (min)",
        notify_about_availability: "Notifica sul tempo di disponibilità del tavolo",
        custom_message_availability: "messaggio personalizzato per il tempo di disponibilità del tavolo",
        enjoy_table_limit_time: "Ti ricordiamo gentilmente che puoi goderti il tavolo per non più di 1h30",
        others: "Altri",
        other_possible_settings: "Altre impostazioni possibili",
        zones: "zone",
        select_zones_venue: "Seleziona le zone che si applicano al tuo locale",
        inside: "Interno",
        terrace: "Terrazza",
        patio: "Patio",
        garden: "Giardino",
        bar: "Bar",
        other_zones: "altre zone",
        type_more_zones: "Digita altre zone, es. sala VIP, seminterrato",
        credit_card_policy: "Politica della carta di credito",
        have_credit_card_policy: "Ho una politica della carta di credito",
        first_contact_make_impression: "Il primo contatto farà impressione",
        enable_cross_selling: "Abilita la vendita incrociata",
        venues_recommend_ifnot_available: "Seleziona i locali che vorresti raccomandare nel caso in cui quello iniziale non sia disponibile",
        enable_group_bookings: "Abilita prenotazioni di gruppo",
        max_days_advanced: "massimo di giorni in anticipo",
        what_else: "Cosa altro?",
        group_bookings: "Prenotazione di gruppo",
        input_example_diners: "A causa del numero di commensali, ti metterò in contatto con un operatore che può gestire la tua prenotazione da ora in poi",
        handover_schedule: "Orario di passaggio",
        handover_schedule_description: "Orari in cui le chiamate vengono trasferite a questo numero",
        whatsapp_channel_setup: "configurazione del canale WhatsApp",
        venue: "Locale",
        apply_same_to_all_venues: "Puoi applicare lo stesso account WhatsApp a diversi locali.",
        specific_or_multiple_venues: "L'account WhatsApp è per un locale specifico o per più locali?",
        specific: "Specifico",
        select_a_venue: "Seleziona un locale",
        multiple: "Multipli",
        select_venues_that_apply: "Seleziona i locali che si applicano",
        whatsapp_step2_description: "Usa lo stesso nome che usi per i clienti che chiamano il tuo ristorante o usane uno diverso. In entrambi i casi, possiamo configurarlo per te.",
        which_phone_number_use: "Quale numero di telefono vorresti usare?",
        new_number: "Nuovo numero",
        we_provide_and_assign: "Forniamo e assegniamo a te un numero WhatsApp",
        use_same_number_as_bot: "Puoi usare lo stesso numero del bot vocale",
        venue_number: "Numero del locale",
        taken_from_google: "preso da Google",
        type_phone_number: "Digita il numero di telefono",
        whatsapp_account: "Account WhatsApp",
        whatsapp_step3_description: "Per poter implementare un assistente WhatsApp è necessario utilizzare WhatsApp API. Scegli l'opzione che si applica e possiamo guidarti attraverso ogni passo del processo.",
        does_number_have_account: "Questo numero ha già un account WhatsApp?",
        whatsapp_personal_use: "Sì, WhatsApp App (per uso personale)",
        personal_app: "WhatsApp è l'app per utenti privati, quella che abbiamo sempre avuto.",
        whatsapp_business: "Sì, WhatsApp Business App",
        business_app: "WhatsApp Business è l'app ufficiale per piccole imprese senza eccessiva complessità.",
        whats_business_api: "Sì, WhatsApp Business API",
        business_api_description: "WhatsApp Business API è l'interfaccia che permette la creazione di esperienze e servizi complessi come il chatbot.",
        no_whats_this_number: "Non ho WhatsApp collegato a questo numero",
        not_sure_need_guide: "Non sono sicuro",
        // not_sure_need_guide: "Non sono sicuro, potete guidarmi?",
        book_a_meeting: "Prenota un incontro",
        with_support_team: "con il nostro team di supporto.",
        api_provider: "Fornitore API",
        whats_step_4_description: "Per poter implementare un assistente WhatsApp è necessario utilizzare WhatsApp API. Scegli l'opzione che si applica e possiamo guidarti attraverso ogni passo del processo.",
        whats_your_provider: "Qual è il tuo fornitore?",
        whats_summary_step_description: "La tua richiesta è stata inviata e il team di supporto ti contatterà con ulteriori informazioni",
        request_submitted: "Richiesta inviata",
        can_receive_sms: "Posso ricevere SMS o chiamate telefoniche a questo numero.",
        whats_step_4_bis_description: "Per poter implementare un assistente WhatsApp è necessario utilizzare WhatsApp API. Scegli l'opzione che si applica e possiamo guidarti attraverso ogni passo del processo.",
        need_delete_account: "È necessario eliminare il tuo account WhatsApp",
        if_delete_number: "Nota che se elimini il tuo numero non potrai",
        send_receive_messages: "inviare o ricevere nuovi messaggi nell'app",
        cant_see_chat_history: "non puoi vedere la cronologia delle chat",
        ready_to_reset: "Capito, sono pronto per resettarlo",
        not_sure_need_help: "Non sono sicuro, potete aiutarmi?",
        personal: "Personale",
        no_wa_lnked: "Nessun WhatsApp collegato a questo numero",
        ready_api_onb: "Pronto per l'onboarding API",
        proceed_api_onb: "Ora puoi procedere con l'onboarding API collegandoti a 360Dialog.",
        book_meeting_support_team: "Hai dubbi? Prenota un incontro con il nostro team di supporto.",
        agent_get_in_touch: "Un agente di supporto al cliente si metterà in contatto il prima possibile con i prossimi passi.",
        type_provider_name: "Digita il nome del fornitore",
        type_name_here: "Digita qui il nome",

        summary_description: "Questo è un riepilogo delle informazioni che hai fornito, assicurati che tutto sia corretto prima di creare il tuo assistente oppure puoi tornare indietro e apportare le modifiche necessarie.",
        customer: "Cliente",

        booking_manager: "Gestore delle prenotazioni",
        add_other_restaurants: "Vuoi aggiungere altri ristoranti?",

        do_you_have_switchboard: "Hai un centralino?",
        when_should_assistant_start: "Quando vuoi che inizi l'assistente?",

        selected_languages: "Lingue selezionate",
        and: " e ",

        assistant_gender: "Genere dell'assistente",
        female: "Femminile",
        male: "Maschile",

        formality_preference: "Quale formalità preferisci usare con il cliente?",

        notify_warranty_time: "Vuoi avvisare del tempo di garanzia della prenotazione?",

        minutes: "minuti",
        ask_anything_else: "Vuoi che l'assistente chieda qualcos'altro?",
        send_confirmation: "Vuoi inviare una conferma della prenotazione?",
        add_anything_else: "Vuoi aggiungere qualcos'altro?",
        create: "Creare",
      },
    },
    pt: {
      translation: {
        tech_review_1: "Um agente de suporte ao cliente entrará em contato o mais rápido possível para informar as próximas etapas. Se você tiver alguma dúvida ou pergunta, ",
        delete_photo: "Deletar foto",
        select_photo: "Selecione ou solte a foto para carregá-la como foto de perfil",
        industry: "Indústria",
        address: "Endereço",
        description: "Descrição",
        about: "Sobre",
        done: "Feito",
        clients: "Clientes",
        support: "Suporte",
        no_wa_linked: "Nenhum WhatsApp vinculado a este número",
        account_settings: "Configurações",
        activity: "Atividade",
        live_calls: "Chamadas ao vivo",
        business_experience_start: "A experiência no seu negócio começa no",
        first_contact: "primeiro contato.",
        welcome_to_bookline: "Bem-vindo ao Bookline, ",
        assistant_setup_intro: "Para garantir que o assistente esteja perfeitamente adaptado e configurado de acordo com suas preferências e necessidades específicas, pedimos que reserve alguns minutos para preencher este questionário.",
        assistant_setup_intro_continued: "Entendemos que você pode estar ocupado; então, se não conseguir terminar agora, não tem problema. Você pode pausar e retomar o processo de onde parou a qualquer momento.",
        start: "Começar",
        write_here: "Escreva aqui",
        ask_conf_notif: "Você quer enviar uma confirmação da reserva para o cliente?",
        yes_mkt: "Sim, com WhatsApp com publicidade do bookline",
        free: "gratuito",
        yes_paid: "Sim, com WhatsApp sem publicidade",
        yes_sms: "Sim, com SMS",
        dont_want: "Não quero",
        anything_else: "Algo mais que você queira nos dizer?",
        anything_else2: "Se você não tiver certeza, poderá indicar isso mais tarde",
        ask_warranty: "Você quer indicar o tempo de garantia da reserva?",
        warranty_placeholder: "Indique o tempo de garantia em minutos",
        extra_q: "Você quer que o assistente pergunte ou diga mais alguma coisa?",
        extra_q2: "Se você não tiver certeza, poderá indicar isso mais tarde",
        alergies: "Algum comentário adicional como alergias, intolerâncias ou pedidos especiais que queira indicar?",
        extra_highchair: "Se você vier com crianças e precisar de cadeirinhas, por favor, especifique quantas você precisa.",
        ask_how_know_us: "Como você nos conheceu?",
        ask_disca: "Há alguém com mobilidade reduzida vindo?",
        extra_placeholder: "Indique o que mais você quer que o assistente diga. Você pode adicionar várias frases ou perguntas.",
        ask_warranty_time: "Indique o tempo de garantia em minutos",
        ask_formality: "Que formalidade prefere usar com o cliente?",
        you: "Você (informal)",
        you2: "Senhor/Senhora (formal)",
        assistant_title: "Agora vamos configurar o seu assistente, escolha os idiomas que você quer que o seu assistente fale",
        assistant_title2: "Você pode selecionar o idioma padrão mais tarde",
        spanish: "Espanhol",
        english: "Inglês",
        catalan: "Catalão",
        french: "Francês",
        italian: "Italiano",
        german: "Alemão",
        portuguese: "Português",
        lang_detection: "Como você quer que a detecção de idioma seja feita?",
        by_prefix: "Por prefixo telefônico do cliente",
        by_command: "Dizendo explicitamente ao assistente para falar em determinado idioma",
        default_lang: "Escolha o idioma padrão do seu assistente",
        default_lang2: "Para este idioma, você pode configurar o tipo de voz",
        assistant_voice: "Escolha a voz do seu assistente",
        assistant_voice2: "Personalize a experiência auditiva para seus clientes",
        phone_placeholder: "Digite seu número de telefone",

        ask_centralita: "Você tem uma central?",
        ask_centralita2: "Indique se você tem um sistema de comunicação integrado, caso contrário indique a sua companhia telefônica",
        ask_phone_provider: "Selecione o seu fornecedor de telefonia",
        ask_centralita_provider: "Selecione o seu fornecedor",
        idk: "Não sei",
        when_answer: "Quando você quer que o assistente inicie?",
        ask_2nd_line: "Por favor, forneça uma segunda linha para transferir a chamada para o restaurante. Se você não tiver uma, deixe a caixa em branco e um agente entrará em contato com você mais tarde para oferecer uma solução.",
        always: "Sempre",
        if_no_answer: "Se não responder no restaurante",
        franjas: "Faixas horárias",
        confirm_data: "Confirme os dados do restaurante",
        verify: "Certifique-se de que todas as informações estão corretas antes de continuar",

        tel: "Telefone",
        other_rests: "Tem outros restaurantes que gostaria de adicionar?",
        add_others: "Por favor, escreva os nomes separados por uma vírgula",
        ask_bm: "Qual sistema de reservas você usa?",
        ask_bm2: "Gerenciar reservas é muito mais fácil com uma plataforma dedicada. No entanto, se você não tiver uma, não é um problema, sabemos como trabalhar sem ela!",
        clinic: "Clínica",
        taxi: "Ponto de taxi",
        club: "Clube esportivo",
        select_b: "Selecione seu tipo de negócio",
        ask_restaurant: "Qual é o seu restaurante?",
        search_restaurant: "Vamos encontrar seu restaurante",
        cant_find: "Não consegue encontrar?",
        rest_placeholder: "Escreva o nome do seu restaurante",
        title: "Aplicação em vários idiomas",
        venues: "Locais",
        diversion_instructions: "Instruções de desvio",
        show_stats: "Mostrar estatísticas",
        edit_bot: "Editar Bot",
        last_call: "A última chamada foi",
        phone_settings: "Configurações do telefone",
        close: "Fechar",
        billing: "Faturação",
        account: "Conta",
        create_account: "Criar conta",
        create_new_account: "Criar uma nova conta",
        legal_information: "Informação legal",
        company_name: "Nome da empresa",
        tax_id: "CIF",
        partner_name: "Nome do parceiro",
        partner_id: "NIF do parceiro",
        email: "Email",
        tax_address: "Morada fiscal",
        city: "Cidade",
        postal_code: "Código postal",
        payment_method: "Método de pagamento",
        payment_type: "Tipo de pagamento",
        direct_debit: "Débito direto",
        bank: "Banco",
        account_holder: "Nome do titular da conta",
        account_number: "Número de conta",
        currency: "Moeda",
        update_payment_method: "Atualizar método de pagamento",
        payment_settings: "Configura o teu método de pagamento configurando o Débito Direto através de",
        cancel: "Cancelar",
        field_mandatory: "Todos os campos são obrigatórios",
        not_valid_email: "Email inválido",
        team: "Equipe",
        invite_user: "Convidar usuário",
        name: "Nome",
        phone: "Número de telefone",
        role: "Função",
        copy_link: "Copia link",
        send: "Enviar",
        assistants: "Assistentes",
        add_new_bot: "Adicionar novo bot",
        restaurant: "Restaurante",
        online: "Online",
        offline: "Offline",
        calls: "Chamadas",
        save: "Salvar",
        share: "Compartilhar",
        add_new_ticket: "Adicionar um novo bilhete",
        notifications: "Notificações",
        whats_description: "Soluções de Mensagens Móveis e Automação Móvel e API · Mensagens WhatsApp",
        reservations: "Reservas",
        cover_description: "Automatiza a faturação dos teus clientes com um sistema de pagamento à mesa. Poupa tempo e aumenta a rentabilidade do teu negócio.",
        restoo_description: "O teu Livro de Reservas Inteligente. Sem comissões. Gerencia as tuas reservas e planeia os serviços facilmente. Conhece as preferências de todos os teus clientes e constrói a fidelidade deles automaticamente.",
        dentalink_description: "O Dentalink é o software ideal para a gestão de consultórios dentários. Simplifica as tuas tarefas diárias, fortalece a relação com os teus pacientes e aumenta a rentabilidade da tua clínica. Adeus ao papel!",
        colzent_description:
          "Sistema integral de gestão de frotas de veículos com gestão de condutores, reservas e serviços, geolocalização em tempo real de veículos e serviços, serviços de voz, assistência a condutores e/ou clientes e muito mais...",
        golf_description: "O Golfmanager é o único software 100% baseado na nuvem que permite gerir todas as necessidades operacionais de um clube de golfe num único programa. Design moderno e uma experiência de reserva sem igual.",
        geolocation: "Geolocalização",
        google_description: "Navega pelo mundo de forma fácil e rápida com o Google Maps. Existem mapas de mais de 220 países e territórios com milhões de negócios!",
        netelip_description: "Telefonia IP para empresas da netelip. Operador líder de Voz IP ou VoIP em Espanha e América Latina com uma ampla gama de soluções.",
        twilio_description: "A Twilio potencia interações personalizadas e comunicações globais confiáveis para te conectares com os clientes.",
        sendgrid_description: "O SendGrid entrega os teus emails transacionais e de marketing através da maior plataforma de entrega de email baseada na nuvem do mundo. Envia com confiança.",
        hubspot_description:
          "A plataforma CRM da HubSpot inclui todas as ferramentas e integrações de que precisas para marketing, vendas, gestão de conteúdo, atendimento ao cliente e operações. Cada produto é poderoso por si só; juntos, são incomparáveis.",
        select_customer: "Por favor, seleciona um cliente",
        install: "Instalar",
        already_installed: "Isso já está instalado",
        integrations: "Integrações",
        installed: "Instalado",
        add_new_integration: "Adicionar nova integração",
        company: "Empresa",
        extra_data: "Dados adicionais",
        user: "Usuário",
        list: "Lista",
        new_notification: "Nova notificação",
        frequency: "Frequência",
        general: "Geral",
        conversations: "Conversas",
        conversation: "Conversação",
        chats: "Chats",
        marketplace: "Mercado",
        settings: "Configurações",
        search_number: "Procurar por um número exato",
        show: "Mostrar",
        previous: "Anterior",
        page: "Página",
        of: "de",
        filter: "Filtrar",
        clean: "Limpar",
        no_calls: "Não há chamadas para mostrar",
        assistant: "Assistente",
        client: "Cliente",
        today: "Hoje",
        yesterday: "Ontem",
        more: "Mais",
        no_number: "O número que você está procurando não existe",
        next: "Próximo",
        no_chats: "Não há chats para mostrar",
        select_whats: "Por favor, selecione sua conta do WhatsApp Business",
        send_whats: "Enviar um WhatsApp",
        call: "Chamada",
        search: "Pesquisar",
        help_support: "Centro de Suporte de Ajuda",
        search_help: "Pesquisar por perguntas ou tópicos de ajuda",
        getting_started: "Primeiros Passos",
        download: "Download",
        installation: "Instalação",
        accessibility: "Acessibilidade",
        supported_devices: "Dispositivos Suportados",
        npm_installation: "Instalação do NPM",
        common_errors: "Erros Comuns na Instalação",
        npm_windows: "NPM para Windows",
        cannot_install: "Não é possível instalar o NPM no Mac",
        not_recognize: "O NPM não é reconhecido localmente",
        npm_version: "A versão do NPM não aparece ao executar o comando",
        bootstrap_ins: "Instalação do Bootstrap",
        migrating: "Migração do Bootstrap 3",
        webpack: "Bootstrap usando Webpack",
        react_components: "Componentes Bootstrap para React",
        example_bootstrap: "Exemplo de página Bootstrap com barra de navegação",
        sass_custom: "Personalização do SASS",
        min_sass: "Minimizar o SASS para CSS sem Bootstrap",
        more_sass: "Adicionar mais classes utilitárias no SASS",
        pricing_billing: "Preços e Faturação",
        features: "Recursos",
        search_people: "Buscar pessoas",
        help_center: "Centro de Ajuda",
        find_integrations: "Descubra nossas integrações",
        preferences: "Preferências",
        language: "Idioma",
        select_or_drop: "Selecione ou arraste a foto para fazer upload",
        select_venue: "Selecione um local",
        business_details: "Detalhes do Negócio",
        channel_setup: "Configuração do Canal",
        subscriptions: "Assinaturas",
        active: "Ativo",
        phone_lines: "Linhas Telefônicas",
        primary_line: "Linha Principal",
        this_is_main_line: "Esta é a sua linha telefônica principal do restaurante para os clientes entrarem diretamente em contato",
        number: "Número",
        provider: "Fornecedor",
        type: "Tipo",
        bot_line: "Linha do Bot",
        number_asigned_bot: "Este é o número de telefone atribuído ao bot",
        handover_line: "Linha de Transferência",
        handover_description: "Este é o telefone usado em caso de o cliente querer ser transferido para o restaurante",
        label: "Rótulo",
        call_routing: "Encaminhamento de Chamadas",
        call_routing_rule: "Estas são as regras de encaminhamento de chamadas",
        last_call_was: "A última chamada foi",
        transfer_to_bot: "Transferir para o bot",
        call_assigned_bot: "A chamada está atribuída a este bot",
        trigger: "Gatilho",
        when_bot_activates: "Quando o bot é ativado",
        try_it: "Experimente",
        inactive: "Inativo",
        whatsapp_settings_description: "Para ter o seu assistente operacional, precisamos configurar os seus canais de comunicação. Comece a configurar aqui, ou entre em contato com o seu agente de suporte se não tiver certeza.",
        copy_settings: "Copiar configurações",
        setup: "Configurar",
        basic_info: "Informações Básicas",
        info_from_google: "Esta informação é retirada do Google",
        website: "Website",
        timezone: "Fuso Horário",
        booking_software: "Software de Reservas",
        schedules: "Horários",
        sync_google: "Sincronizar com o Google Maps",
        updates_at_1pm: "O horário é atualizado todos os dias às 13h.",
        closed: "Fechado",
        same_every_day: "Igual todos os dias",
        apply_to_all: "Aplicar a todos",
        add_slot: "Adicionar slot",
        all_days: "Todos os dias",
        media: "Mídia",
        upload_pic: "Faça upload da foto do seu restaurante e use como foto de perfil ou foto do WhatsApp",
        use_profile: "Usar como foto de perfil",
        use_whatsapp: "Usar como foto do WhatsApp",
        contact_channels: "Canais de Contato",
        info_send_notifications: "Usamos essas informações para enviar notificações",
        alternative_phone_number: "Número de telefone alternativo",
        social_networks: "Redes Sociais",
        news_email: "Receber newsletter por e-mail",
        marketing_whatsapp: "Receber mensagens de marketing do bookline via WhatsApp",
        advanced: "Avançado",
        personalized_exp: "Fornecer estas informações adicionais ajudará a melhorar a experiência personalizada",
        options_restaurant: "Selecione opções que se aplicam ao seu restaurante",
        food_tolerance: "Preferência alimentar e tolerância",
        vegetarian_opt: "Oferecemos opções vegetarianas",
        vegan_opt: "Oferecemos opções veganas",
        lactose_opt: "Oferecemos opções sem lactose",
        gluten_opt: "Temos opções sem glúten",
        parking: "Estacionamento",
        own_parking: "Possui estacionamento próprio",
        parking_nearby: "Estacionamento nas proximidades",
        valet_parking: "Possui serviço de valet",
        easy_parking: "Fácil de estacionar?",
        wheel_access: "Oferecemos acesso para cadeira de rodas",
        pet_friendly: "Aceitamos animais de estimação",
        delivery: "Entrega",
        deliver_home: "Realizamos entregas em domicílio",
        update: "Atualizar",
        plan_name: "Nome do Plano",
        voice_assistant: "Assistente de Voz",
        plan_cost: "Custo do Plano",
        date_activated: "Data de Ativação",
        add_on: "Adicional",
        survey: "Pesquisa",
        booking_reminder: "Lembrete de Reserva",
        phone_number: "Número de telefone",
        edit: "Editar",
        add: "Adicionar",
        call_to: "Ligar para",
        group_booking: "Reserva de Grupo",
        search_name: "Pesquisar um nome",
        voice: "Voz",
        pets: "Animais de estimação",
        open: "Aberto",
        to: "para",
        type_link_here: "digite o link aqui",
        type_number_here: "digite o número aqui",
        day: "dia",
        schedule: "horário",
        voice_channel_setup: "Configuração do Canal de Voz",
        phone_provider: "Fornecedor de Telefone",
        step1_description1: "Para implementar um bot de voz de forma perfeita nas operações do seu negócio, um passo inicial crucial envolve estabelecer integração com o seu fornecedor de telefone.",
        step1_description2: "Se não encontrar o seu fornecedor na lista ou não tiver certeza, nós descobriremos para você.",
        select_phone_provider: "Selecione o seu fornecedor de telefone",
        provider_no_centralita: "Alguns fornecedores sem opção de centralita",
        other: "Outro",
        dont_have_provider: "Eu ainda não tenho um fornecedor",
        use_centralita: "Você usa a centralita deles?",
        yes: "Sim",
        no: "Não",
        unsure: "Não tenho certeza",
        provide_company_details: "Forneça detalhes da empresa",
        type_name: "digite o nome",
        contact_person: "Pessoa de contato",
        contact_number: "Número de contato",
        type_number: "digite o número",
        type_email: "digite o email",
        step2_description: "Roteie as chamadas para o recurso apropriado, seja um representante de suporte ao cliente, um departamento específico ou um sistema automatizado.",
        when_trigger_bot: "Quando você quer que o bot seja acionado?",
        when_unavailable: "Quando indisponível",
        when_line_busy: "Quando a linha está ocupada ou o restaurante não responde",
        by_schedules: "Por horários",
        by_ivr: "Após uma seleção no IVR atual",
        step3_description: "Roteie as chamadas para o recurso apropriado, seja um representante de suporte ao cliente, um departamento específico ou um sistema automatizado.",
        where_clients_call: "Onde você quer que seus clientes liguem?",
        bot_number: "Número do Bot",
        recommended: "Recomendado",
        restaurant_number: "Número do Restaurante",
        important: "Importante!",
        further_setup: "Essa opção pode exigir configurações adicionais para a linha de transferência.",
        another_number: "Número próprio",
        handover: "Transferência",
        handover_step_description: "A chamada não pode ser transferida de volta para o número que o cliente está ligando. Precisamos implantar uma linha diferente para evitar a criação de loops infinitos.",
        where_transfer: "Se um cliente pedir, para onde você quer que a chamada seja transferida?",
        different_number: "Para um número diferente",
        add_destinations: "Adicionar destinos",
        queue: "Fila",
        add_more: "Adicionar mais",
        never_transfer_calls: "Nunca transferir chamadas",
        some_provider: "Alguns fornecedores",
        no_provider: "Sem fornecedor",
        setup_summary: "Resumo da Configuração",
        summary_step_description: "Depois de enviar, você ainda pode editar posteriormente no portal do cliente.",
        below_info_provided: "Abaixo está um resumo das informações fornecidas",
        setup_successful: "Configuração bem-sucedida",
        pending_tech_review: "Aguardando revisão técnica.",
        tech_review_description: "Um agente de suporte ao cliente entrará em contato quando o canal de voz estiver ativo.",
        centralita: "Centralita",
        direct: "Direto",
        go_to_portal: "Ir para o portal",
        all_channels: "Todos os canais",
        pending: "Pendente",
        accepted: "Aceito",
        rejected: "Rejeitado",
        archived: "Arquivado",
        booking: "Reserva",
        requests: "Pedidos",
        all: "Todos",
        custom: "Personalizado",
        filter_by: "Filtrar por",
        channel: "Canal",
        status: "Estado",
        request_type: "Tipo de pedido",
        clear: "Limpar",
        order_by: "Ordenar por",
        booking_type: "Tipo de reserva",
        from: "de",
        pax: "PAX",
        time: "Tempo",
        received_on: "Recebido em",
        restaurant_name: "Nome do restaurante",
        client_details: "Detalhes do cliente",
        see_client_record: "Ver registro do cliente",
        request_details: "Detalhes do pedido",
        comments: "Comentários",
        accept: "Aceitar",
        reject: "Rejeitar",
        call_details: "Detalhes da chamada",
        summary: "Resumo",
        timeline: "Linha do tempo",
        transcript: "Transcrição",
        call_start: "Início da chamada",
        call_end: "Fim da chamada",
        call_duration: "Duração da chamada",
        reason_identified: "Motivo identificado",
        booking_details: "Detalhes da reserva",
        confirmation: "Confirmação",
        sent: "Enviado",
        incoming_call: "Chamada recebida",
        call_ended: "Chamada encerrada",
        search_by_phone_number: "Buscar por número de telefone",
        in_review: "Em revisão",
        cross_selling: "venda cruzada",
        reminders: "lembretes",
        products: "Produtos",
        add_new: "Adicionar novo",
        my_products: "Meus produtos",
        search_by_name: "Buscar assistente por nome",
        booking_management: "Gestão de reservas",
        date: "Data",
        booking_confirmed: "Reserva confirmada",
        main: "Principal",
        add_ons: "Complementos",
        collapse_all: "Colapsar tudo",
        expand_all: "Expandir tudo",
        personality: "Personalidade",
        personality_description: "Dê personalidade com um nome, traços e um rosto",
        avatar: "Avatar",
        avatar_description: "Escolha uma foto de avatar para o seu assistente ou faça o upload da sua",
        traits: "Traços",
        traits_description: "Escolha alguns traços de personalidade que você gostaria que seu assistente tivesse",
        playful: "brincalhão",
        serious: "sério",
        funny: "engraçado",
        friendly: "amigável",
        calm: "calmo",
        sarcastic: "sarcástico",
        rude: "rude",
        kinky: "atrevido",
        gentle: "gentil",
        sonofagun: "espertalhão",
        excited: "excitado",
        languages_and_voice: "Línguas e Voz",
        languages_description: "Faça-o falar nos idiomas que desejar e com a voz que gostar",
        default_language: "idioma padrão",
        default_voice: "voz padrão",
        activate_language_detention: "Ativar detecção de idioma",
        how_detect_language: "Como você quer que o idioma seja detectado?",
        by_phone_prefix: "Por prefixo telefônico",
        by_telling_assistant: "Dizendo explicitamente ao assistente que querem falar em determinado idioma",
        add_more_languages: "Adicionar mais idiomas",
        greeting: "Saudação",
        greeting_description: "O primeiro contato causará impressão",
        turn_greeting_options: "Ativar opções de saudação que devem se aplicar ao seu restaurante",
        different_greeting: "Gostaria que o assistente usasse uma saudação diferente para cada momento do dia",
        formal_greeting: "Quero que meu assistente se dirija de forma formal",
        name_greeting: "Gostaria que meu assistente cumprimentasse os clientes pelo nome",
        introduce_greeting: "Quero que meu assistente se apresente",
        preview: "Pré-visualização",
        reservation_policy: "Política de reserva",
        diners_policy: "Política para os comensais",
        diners_description: "Gerencie reservas de forma diferente dependendo do número de comensais",
        max_diners: "Máx. comensais por reserva",
        transfer_calls_more_diners: "Transferir chamadas se houver mais comensais",
        transfer_to: "transferir para",
        custom_message_transfer: "mensagem personalizada para transferência",
        bc_number_diners_transfer: "Devido ao número de comensais, vou transferi-lo para um atendente",
        contract_group_booking: "Contratar gestão de reservas de grupo",
        contract_group_booking_description: "Contrate o complemento de reservas de grupo para facilitar reservas para grandes grupos, sem necessidade de transferir para o restaurante",
        time_policy: "Política de tempo",
        time_policy_description: "Mantenha o cliente informado sobre a política de tempo para evitar mal-entendidos",
        booking_warranty: "Tempo de garantia da reserva (min)",
        notify_about_warranty: "Notificar sobre o tempo de garantia da reserva",
        custom_message_warranty: "mensagem personalizada para o tempo de garantia",
        coming_late_no_guarantee: "Note que se chegar mais tarde que 15 minutos, não podemos mais garantir a mesa para você",
        table_availability: "tempo de disponibilidade da mesa (min)",
        notify_about_availability: "Notificar sobre o tempo de disponibilidade da mesa",
        custom_message_availability: "mensagem personalizada para o tempo de disponibilidade da mesa",
        enjoy_table_limit_time: "Lembramos gentilmente que você pode desfrutar da mesa por no máximo 1h30",
        others: "Outros",
        other_possible_settings: "Outras configurações possíveis",
        zones: "zonas",
        select_zones_venue: "Selecione zonas que se aplicam ao seu estabelecimento",
        inside: "Interior",
        terrace: "Terraço",
        patio: "Pátio",
        garden: "Jardim",
        bar: "Bar",
        other_zones: "outras zonas",
        type_more_zones: "Digite mais zonas, ex. sala VIP, porão",
        credit_card_policy: "Política de cartão de crédito",
        have_credit_card_policy: "Tenho uma política de cartão de crédito",
        first_contact_make_impression: "O primeiro contato causará impressão",
        enable_cross_selling: "Habilitar venda cruzada",
        venues_recommend_ifnot_available: "Selecione os locais que você gostaria de recomendar caso o inicial não esteja disponível",
        enable_group_bookings: "Habilitar reservas de grupo",
        max_days_advanced: "máx. dias de antecedência",
        what_else: "O que mais?",
        group_bookings: "Reserva de grupo",
        input_example_diners: "Devido ao número de comensais, vou transferi-lo para um atendente que pode gerenciar sua reserva a partir de agora",
        handover_schedule: "Agenda de transferência",
        handover_schedule_description: "Horários em que as chamadas são transferidas para este número",
        whatsapp_channel_setup: "configuração do canal do WhatsApp",
        venue: "Local",
        apply_same_to_all_venues: "Você pode aplicar a mesma conta do WhatsApp a vários locais.",
        specific_or_multiple_venues: "A conta do WhatsApp é para um local específico ou múltiplos?",
        specific: "Específico",
        select_a_venue: "Selecionar local",
        multiple: "Múltiplos",
        select_venues_that_apply: "Selecione os locais que se aplicam",
        whatsapp_step2_description: "Use o mesmo nome que você usa para os clientes chamarem seu restaurante ou use um diferente. De qualquer forma, podemos configurá-lo para você.",
        which_phone_number_use: "Qual número de telefone você gostaria de usar?",
        new_number: "Número novo",
        we_provide_and_assign: "Nós fornecemos e atribuímos a você um número do WhatsApp",
        use_same_number_as_bot: "Você pode usar o mesmo número que o bot de voz",
        venue_number: "Número do local",
        taken_from_google: "retirado do Google",
        type_phone_number: "Digite o número de telefone",
        whatsapp_account: "Conta do WhatsApp",
        whatsapp_step3_description: "Para poder implantar um assistente do WhatsApp você precisa usar o WhatsApp API. Escolha a opção que se aplica e podemos guiá-lo através de cada etapa do processo.",
        does_number_have_account: "Este número já possui uma conta do WhatsApp?",
        whatsapp_personal_use: "Sim, WhatsApp App (para uso pessoal)",
        personal_app: "WhatsApp é o app para usuários privados, o que sempre tivemos.",
        whatsapp_business: "Sim, WhatsApp Business App",
        business_app: "WhatsApp Business é o aplicativo oficial para pequenos negócios sem complexidade excessiva.",
        whats_business_api: "Sim, WhatsApp Business API",
        business_api_description: "WhatsApp Business API é a interface que permite a criação de experiências e serviços complexos como o chatbot.",
        no_whats_this_number: "Não tenho WhatsApp vinculado a este número",
        not_sure_need_guide: "Não tenho certeza",
        // not_sure_need_guide: "Não tenho certeza, podem me guiar?",
        book_a_meeting: "Agendar uma reunião",
        with_support_team: "com nossa equipe de suporte.",
        api_provider: "Provedor de API",
        whats_step_4_description: "Para poder implantar um assistente do WhatsApp você precisa usar o WhatsApp API. Escolha a opção que se aplica e podemos guiá-lo através de cada etapa do processo.",
        whats_your_provider: "Qual é o seu provedor?",
        whats_summary_step_description: "Seu pedido foi submetido e a equipe de suporte entrará em contato com você com mais informações",
        request_submitted: "Pedido submetido",
        can_receive_sms: "Posso receber SMS ou chamadas telefônicas neste número.",
        whats_step_4_bis_description: "Para poder implantar um assistente do WhatsApp você precisa usar o WhatsApp API. Escolha a opção que se aplica e podemos guiá-lo através de cada etapa do processo.",
        need_delete_account: "Você precisa deletar sua conta do WhatsApp",
        if_delete_number: "Note que se você deletar o seu número você não poderá",
        send_receive_messages: "enviar ou receber novas mensagens no app",
        cant_see_chat_history: "não pode ver o histórico de conversas",
        ready_to_reset: "Entendi, estou pronto para resetar",
        not_sure_need_help: "Não tenho certeza, podem me ajudar?",
        personal: "Pessoal",
        no_wa_lnked: "Nenhum WhatsApp vinculado a este número",
        ready_api_onb: "Pronto para integração via API",
        proceed_api_onb: "Agora você pode proceder com a integração via API conectando-se a 360Dialog.",
        book_meeting_support_team: "Tem dúvidas? Agende uma reunião com nossa equipe de suporte.",
        agent_get_in_touch: "Um agente de suporte ao cliente entrará em contato o mais rápido possível com os próximos passos.",
        type_provider_name: "Digite o nome do provedor",
        type_name_here: "Digite o nome aqui",

        summary_description: "Este é um resumo das informações que você forneceu, certifique-se de que tudo está correto antes de criar seu assistente ou você pode voltar e fazer as alterações necessárias.",
        customer: "Cliente",

        booking_manager: "Gerente de reservas",
        add_other_restaurants: "Você quer adicionar outros restaurantes?",

        do_you_have_switchboard: "Você tem uma central?",
        when_should_assistant_start: "Quando você quer que o assistente inicie?",

        selected_languages: "Idiomas selecionados",
        and: " e ",

        assistant_gender: "Gênero do assistente",
        female: "Feminino",
        male: "Masculino",

        formality_preference: "Que formalidade prefere usar com o cliente?",

        notify_warranty_time: "Você quer avisar sobre o tempo de garantia da reserva?",

        minutes: "minutos",
        ask_anything_else: "Você quer que o assistente pergunte mais alguma coisa?",
        send_confirmation: "Você quer enviar uma confirmação da reserva?",
        add_anything_else: "Você quer adicionar mais alguma coisa?",
        create: "Criar",
      },
    },
  },
});

export default i18n;

/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from "react";
import OnboardingContainer from "src/containers/onboarding-v2";
import clinicImage from "@doar/shared/images/bussiness-market/clinic.png";
import hotelImage from "@doar/shared/images/bussiness-market/hotel.png";
import otherImage from "@doar/shared/images/bussiness-market/other.png";
import restaurantImage from "@doar/shared/images/bussiness-market/restaurant.png";
import taxiImage from "@doar/shared/images/bussiness-market/taxi.png";
import clubImage from "@doar/shared/images/bussiness-market/club.png";

import logo from "@doar/shared/images/bookline_v7_transparent_white.png";
import { Button, Card } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import { Nav, NavLink } from "@doar/components";
import { LogOut } from "react-feather";
import { logout } from "src/config/firebase";
import { CustomerContext, UserContext } from "src/config/userContext";
import i18n from "src/i18n";
import "./styles.css";

const Onboarding: React.FC = () => {
  // const [onboardingDiv, setOnboardingDiv] = useState(<div />);
  const [vertical, setVertical] = useState("");
  const [type, setType] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("ES");
  // const [customerUtil1, setCustomerUtil1] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { user, userUpdate } = useContext(UserContext);
  const { customerUpdate } = useContext(CustomerContext);

  function navigateTo(e: string) {
    navigate(e);
  }
  function handleChangeLang(e: any) {
    i18n.changeLanguage(e);
    localStorage.setItem("selectedLanguage", e);
    setSelectedLanguage(e);
  }
  function handleLogout(e: any) {
    e.preventDefault();
    logout();
    navigate("/signin");

    userUpdate({
      auth_uid: "defaultUid",
      email: "default@email.com",
      id: "defaultId",
      name: "defaultName",
      permissions: [],
      phone: {},
      role: "CLIENT",
      sip_settings: {},
    });
    customerUpdate({
      id: "defaultCustomer",
      name: "defaultName",
      accounts: [{}],
    });
    sessionStorage.removeItem("customerSelected");
  }

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      navigate("/thank-you");
    }

    const verticalUtil = searchParams.get("vertical");
    if (verticalUtil) {
      setVertical(verticalUtil);
    }
    const typeUtil = searchParams.get("type");
    if (typeUtil) {
      setType(typeUtil);
    }
    // const customerUtil = searchParams.get("customer");
    // if (customerUtil) {
    //   setCustomerUtil1(customerUtil);
    // }
  }, []);

  // useEffect(() => {
  //   i18n.changeLanguage(selectedLanguage);
  // }, [selectedLanguage]);
  useEffect(() => {
    console.log("LANGUAGE", i18n.language);

    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="onboardingFullPage">
      <div
        className="displayNone"
        style={{
          backgroundColor: "black",
          height: "70px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "stretch",
        }}
      >
        <img style={{ marginLeft: "20px" }} height="45px" src={logo} alt="Bookline Solutions SL logo" />
        <Nav>
          <div className="langItemsContainer">
            <div onClick={() => handleChangeLang("es")} className={`langItem hoverIt ${selectedLanguage == "es" ? "selectedLang" : ""}`}>
              ES
            </div>
            <div className="langItem">|</div>
            <div onClick={() => handleChangeLang("en")} className={`langItem hoverIt ${selectedLanguage == "en" ? "selectedLang" : ""}`}>
              EN
            </div>
            <div className="langItem">|</div>
            <div onClick={() => handleChangeLang("ca")} className={`langItem hoverIt ${selectedLanguage == "ca" ? "selectedLang" : ""}`}>
              CA
            </div>
            <div className="langItem">|</div>
            <div onClick={() => handleChangeLang("it")} className={`langItem hoverIt ${selectedLanguage == "it" ? "selectedLang" : ""}`}>
              IT
            </div>
            <div className="langItem">|</div>
            <div onClick={() => handleChangeLang("pt")} className={`langItem hoverIt ${selectedLanguage == "pt" ? "selectedLang" : ""}`}>
              PT
            </div>
          </div>
          <NavLink onClick={(e: any) => handleLogout(e)} path="/signin">
            <LogOut color="white" />
          </NavLink>
        </Nav>
      </div>

      <OnboardingContainer vertical={vertical} navigateTo={navigateTo} type={type} handleLogout={handleLogout} />
    </div>
  );
};

export default Onboarding;

/* eslint-disable */
import styled, { device, themeGet, css } from "@doar/shared/styled";
import { Anchor } from "@doar/components";

export const StyledLogo = styled(({ ...rest }) => <Anchor {...rest} />)`
    letter-spacing: -1px;
    margin-right: 10px;
    margin-left: 10px;

    img {
        height: 30px;
    }
`;

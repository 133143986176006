/* eslint-disable */

import { Checkbox, Col, Row } from "antd";
import { Button, Input } from "antd";

import { FC, useState } from "react";
import "../styles.css";
import { useGoCardlessDropin, GoCardlessDropinOptions, GoCardlessDropinOnSuccess } from "@gocardless/react-dropin";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { sendActivateMail } from "../utils/create-bot";

interface IProps {
  prefill?: any;
  handleNext?: any;
  handleBack?: any;
  handleChange?: any;
}

export const RestLegal0: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    handleChange("newsletter", e.target.checked ? "yes" : "no");
  };
  return (
    <div>
      <h2 className="businessH2">Ya casi terminamos... Por favor completa los siguientes datos</h2>
      <h5 style={{ marginTop: "70px", marginLeft: "10px" }}>Información legal</h5>
      <Row>
        <Col span={16}>
          <Input className="inputLegal" placeholder="Nombre de la empresa" value={prefill?.legal_name} onChange={(e: any) => handleChange("legal_name", e.target.value)} />
        </Col>
        <Col span={8}>
          <Input className="inputLegal" placeholder="CIF" value={prefill?.legal_cif} onChange={(e: any) => handleChange("legal_cif", e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Input className="inputLegal" placeholder="Nombre completo del socio" value={prefill?.legal_partner} onChange={(e: any) => handleChange("legal_partner", e.target.value)} />
        </Col>
        <Col span={8}>
          <Input className="inputLegal" placeholder="NIF Socio" value={prefill?.legal_partner_cif} onChange={(e: any) => handleChange("legal_partner_cif", e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input className="inputLegal" style={{ maxWidth: "100%" }} placeholder="Email para el envío de facturas" value={prefill?.legal_email} onChange={(e: any) => handleChange("legal_email", e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Checkbox style={{ margin: "5px 10px" }} onChange={onChangeCheckbox} checked={prefill?.newsletter === "yes"}>
            <div className="newsletterCheck">Quiero suscribirme al newsletter</div>
          </Checkbox>
        </Col>
      </Row>

      <h5 style={{ marginTop: "50px", marginLeft: "10px" }}>Domicilio fiscal</h5>

      <Row>
        <Col className="gutter-row" span={24}>
          <Input className="inputLegal" placeholder="Dirección" value={prefill?.legal_address} onChange={(e: any) => handleChange("legal_address", e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={16}>
          <Input className="inputLegal" placeholder="Ciudad" value={prefill?.legal_city} onChange={(e: any) => handleChange("legal_city", e.target.value)} />
        </Col>
        <Col className="gutter-row" span={8}>
          <Input className="inputLegal" placeholder="Codigo postal" value={prefill?.legal_cp} onChange={(e: any) => handleChange("legal_cp", e.target.value)} />
        </Col>
        {/* <Col className="gutter-row" span={8}>
          <Input className="inputLegal" placeholder="Other" value={prefill?.legal_other} onChange={(e: any) => handleChange("legal_other", e.target.value)} />
        </Col> */}
      </Row>
      <div>
        <svg className="backButton" onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
            fill="#333F64"
            stroke="#333F64"
            strokeWidth="1.15217"
          />
          <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
        </svg>
        <Button
          type="primary"
          className="nextButton"
          disabled={!prefill?.legal_name || !prefill?.legal_cif || !prefill?.legal_partner || !prefill?.legal_partner_cif || !prefill?.legal_address || !prefill?.legal_city || !prefill?.legal_cp}
          onClick={() => {
            sendActivateMail(prefill);
            handleNext("rest-legal-0", "onb-end-0");
          }}
        >
          {/* <Button type="primary" className="nextButton" onClick={() => handleNext("rest-legal-0", "rest-legal-1")}> */}
          Next
        </Button>
      </div>
    </div>
  );
};
export const RestLegal1: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const DropinButton = (options: GoCardlessDropinOptions) => {
    const { open } = useGoCardlessDropin({ ...options });

    return (
      <button type="button" onClick={() => open()}>
        Connect with GoCardless{" "}
      </button>
    );
  };

  const [flowID, setFlowID] = useState<string | null>(null);

  // Build your backend with an API endpoint that:
  //
  //   1. Creates a Billing Request for the resources you wish to create
  //   2. Create a Billing Request Flow against (1)
  //   3. Return the ID from (2)
  //
  // See an example of this at Taking an Instant Bank Payment:
  // https://developer.gocardless.com/getting-started/billing-requests/taking-an-instant-bank-payment/

  // React.useEffect(() => {
  //   async function createFlow() {
  //     // Expecting a JSON body like:
  //     // {
  //     //   "flow_id": "BRF123456"
  //     // }
  //     let response = await fetch("/api/flows", {
  //       method: "POST",
  //     });
  //     const { flow_id } = await response.json();
  //     setFlowID(flow_id);
  //   }
  //   createFlow();
  // }, []);

  return (
    <div>
      <h2 className="businessH2">Time for formalities. Please fill in with legal info.</h2>
      <h5>Direct debit</h5>

      {/* <DropinButton billingRequestFlowID={flowID} environment={"live"} /> */}

      <div>
        <svg className="backButton" onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
            fill="#333F64"
            stroke="#333F64"
            strokeWidth="1.15217"
          />
          <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
        </svg>
        <Button type="primary" className="nextButton" onClick={() => handleNext("rest-legal-1", "onb-end-0")}>
          Next
        </Button>
      </div>
    </div>
  );
};

/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ResetPasswordImg from "../../../../components/src/static/portal_resetpass.svg";
import { confirmPasswordResetURL } from "src/config/firebase";

import { Button, Input, Label } from "@doar/components";
import image from "@doar/shared/images/passReset.jpeg";
import "./styles.css";

import { StyledDesc, StyledImg, StyledTitle, StyledWrap } from "./style";
import { StyledSignin } from "../forgot-password-test/style";

const params = new URL(document.baseURI).searchParams;
const code = params.get("oobCode");
const ResetPasswordContainer: FC = () => {
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const handleInputChange = (event: { target: { value: SetStateAction<string> } }) => {
    setPass(event.target.value);
    setShowEmailError(false);
  };
  const handleInputChange2 = (event: { target: { value: SetStateAction<string> } }) => {
    setConfirmPass(event.target.value);
    setShowEmailError(false);
  };
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (pass === confirmPass) {
      if (code) {
        confirmPasswordResetURL(code, pass)
          .then((res) => {
            if (res === true) {
              addToast("Password updated!", {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 3000,
              });
              navigate("/signin");
            } else {
              addToast(res, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
              });
            }
          })
          .catch((err) => {});
      }
    } else {
      setShowEmailError(true);
      // addToast("Passwords don't match!", {
      //     appearance: "error",
      //     autoDismiss: true,
      //     autoDismissTimeout: 3000,
      // });
    }
  };

  return (
    <div className="pageContainer">
      <div className="left-side-forgot"></div>
      <div className="right-side">
        <StyledSignin>
          <StyledTitle>Reset your password</StyledTitle>
          <StyledDesc>Enter a new password for your Bookline account.</StyledDesc>
          <form action="#" onSubmit={handleSubmit} noValidate>
            <div style={{ marginTop: "20px", width: "300px" }}>
              <Label htmlFor="confirmPass">New password</Label>
              <Input onChange={handleInputChange} type="password" id="pass" name="pass" placeholder="Enter the new password" width={[null, "100%"]} feedbackText="Passwords don't match!" state="error" showState={showEmailError} />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Label htmlFor="confirmPass">Confirm new password</Label>
              <Input onChange={handleInputChange2} type="password" id="confirmPass" name="confirmPass" placeholder="Confirm the new password" width={[null, "100%"]} state="error" showState={showEmailError} />
            </div>
            <div style={{ marginTop: "40px" }}>
              <Button type="submit" color="brand2" mt={["10px", 0]} fullwidth>
                Reset Password
              </Button>
            </div>
          </form>
        </StyledSignin>
      </div>
    </div>
  );
};

export default ResetPasswordContainer;

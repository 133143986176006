/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { themeGet, tinycolor, css } from "@doar/shared/styled";
import { Anchor } from "../anchor";

export const StyledNavbar = styled.ul`
  margin: 25px 0px 0px 0px;
  padding: 0;
  list-style: none;
`;

type NavItemProps = {
  // $hasSubmenu: boolean;
  $bottom?: boolean;
  $bottom2?: boolean;
  $submenu?: boolean;
  $expanded?: boolean;
  $show?: boolean;
};

export const StyledNavitem = styled.li<NavItemProps>`
  ${({ $submenu }) =>
    $submenu == true &&
    css`
      padding-left: 20px;
    `};

  ${({ $expanded }) =>
    $expanded == true &&
    css`
      margin-left: -10px;
      // margin-left: 30px;
    `};

  ${({ $bottom }) =>
    $bottom == true &&
    css`
      position: absolute;
      bottom: 0px;
      width: 90%;
    `};

  ${({ $bottom2 }) =>
    $bottom2 == true &&
    css`
      position: absolute;
      bottom: 40px;
      width: 90%;
    `};
`;

export const StyledNavlink = styled(({ NavItemProps, ...rest }) => <Anchor {...rest} />)`

  ${({ $submenu }) =>
    $submenu == true &&
    css`
      padding: 10px 0px;
    `};
  ${({ $expanded }) =>
    $expanded == true &&
    css`
      padding: 10px 0px 10px 10px;
    `};
    
  ${({ $expanded }) =>
    $expanded == false &&
    css`
      padding: 10px 0px;
    `};



  color: #718096 !important;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-top:-10px;

  font-size: 16px;
  font-family: Lato;
  font-feature-settings: "clig" off, "liga" off;
  font-style: normal;
  background-color: transparent;
  margin-bottom: 15px;
  display: flex;
  transition: all 0.25s;

  
    &.active {
      color: #5479f7 !important;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 125% */
      
      // background-color: #f8f8ff;
      border-radius: 10px;
              ${({ $expanded }) =>
                $expanded == true &&
                css`
                  background-color: #f8f8ff;
                `};
   

  ${(props) =>
    props.theme.name !== "dark" &&
    css`
      color: ${themeGet("colors.text")};
    `}
  ${(props) =>
    props.theme.name === "dark" &&
    css`
      color: #fff;
    `}
    .aside-navbar-label {
    pointer-events: none;
  }
`;

export const StyledSubmenu = styled.ul`
  &.mega-submenu {
    padding-left: 34px;
    opacity: 0;
    visibility: visible;
    height: 0;
    &.open {
      padding-bottom: 10px;
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
`;

export const StyledSubNavItem = styled.li<NavItemProps>`
  ${({ $expanded }) =>
    $expanded == true &&
    css`
      padding: 10px;
    `};
  ${({ $expanded }) =>
    $expanded == false &&
    css`
      padding: 10px 0px;
    `};

  margin-bottom: 5px;
  background-color: transparent;

    & > a {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    &.active {
      color: #5479f7 !important;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 125% */
      border-radius: 10px;
        ${({ $expanded }) =>
          $expanded == true &&
          css`
            background-color: #f8f8ff !important;
          `};
    }
  }
`;

export const StyledSubNavlink = styled(({ ...rest }) => <Anchor {...rest} />)`
  color: #718096 !important;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  // margin-left: 40px;
    background-color: transparent;


  transition: all 0.25s;
  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: -25px;
    bottom: 2px;
    right: -25px;
    border-left: 3px solid ${themeGet("colors.text3")};
    opacity: 0;
    visibility: visible;
  }
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.theme.name !== "dark" &&
    css`
      color: ${tinycolor(themeGet("colors.text2")(props)).setAlpha(0.9).toString()};
    `}
  ${(props) =>
    props.theme.name === "dark" &&
    css`
      color: ${themeGet("colors.gray500")};

      &:hover,
      &:focus {
        color: #fff;
      }
    `}
  &.active {
      color: #5479f7 !important;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 125% */
      // background-color: #f8f8ff;
      border-radius: 10px;
              ${({ $expanded }) =>
                $expanded == true &&
                css`
                  background-color: #f8f8ff !important;
                `};
     
    }

  }
`;

/* eslint-disable */
import { FC } from "react";
import logo from "@doar/shared/images/bookline_v7_transparent_white.png";
import { StyledLogo } from "./style";

const AsideLogo: FC = () => {
    return (
        <StyledLogo className="aside-logo" path="/">
            <img src={logo} alt="Bookline Solutions SL logo" />
        </StyledLogo>
    );
};

export default AsideLogo;

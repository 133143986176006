/* eslint-disable */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lonely-if */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable prefer-template */
/* eslint-disable operator-assignment */

import { DefaultEventRootComponent, TimeGridScheduler, classes } from "@remotelock/react-week-scheduler";
import Tippy from "@tippy.js/react";
import { FC, useEffect, useState } from "react";
import "resize-observer-polyfill/dist/ResizeObserver.global";
// import demoClasses from "@remotelock/react-week-scheduler/demo/index.module.scss";
import "@remotelock/react-week-scheduler/index.css";
import { EventRootProps } from "@remotelock/react-week-scheduler/src/types";
import { Trash2 } from "react-feather";

import { Button, Switch } from "antd";
import moment from "moment";
import React from "react";
import "./styles.css";

interface IProps {
  isRestaurant: boolean;
  prefill: any;
  submit: any;
}

const EventRoot = React.forwardRef<any, EventRootProps>(function EventRoot({ handleDelete, disabled, ...props }, ref) {
  return (
    <Tippy
      arrow
      interactive
      enabled={!disabled}
      hideOnClick={false}
      //   className={demoClasses.tooltip}
      content={
        <button className="deleteButton" type="button" disabled={disabled} onClick={handleDelete}>
          <Trash2 />
        </button>
      }
    >
      <DefaultEventRootComponent handleDelete={handleDelete} disabled={disabled} {...props} ref={ref} />
    </Tippy>
  );
});

export const SchedulerComp: FC<IProps> = (props) => {
  const { prefill } = props;
  const { isRestaurant } = props;
  const { submit } = props;
  const [schedule, setSchedule] = useState<any[]>([]);
  const [lastChange, setLastChange] = useState<any>([]);

  const alwaysOn = [
    ["2023-05-01 00:00", "2023-05-01 23:59"],
    ["2023-05-02 00:00", "2023-05-02 23:59"],
    ["2023-05-03 00:00", "2023-05-03 23:59"],
    ["2023-05-04 00:00", "2023-05-04 23:59"],
    ["2023-05-05 00:00", "2023-05-05 23:59"],
    ["2023-05-06 00:00", "2023-05-06 23:59"],
    ["2023-05-07 00:00", "2023-05-07 23:59"],
  ];

  const dayOfWeekR: any = {
    Monday: "1",
    Tuesday: "2",
    Wednesday: "3",
    Thursday: "4",
    Friday: "5",
    Saturday: "6",
    Sunday: "7",
  };
  const dayOfWeekF: any = {
    monday: "1",
    tuesday: "2",
    wednesday: "3",
    thursday: "4",
    friday: "5",
    saturday: "6",
    sunday: "7",
  };

  function mergeDateTime(time: string, day: string) {
    if (isRestaurant) {
      return `2023-05-0${dayOfWeekR[day] as string} ${time}`;
    }
    return `2023-05-0${dayOfWeekF[day] as string} ${time}`;
  }

  function convertScheduleToArrays(schedMod: any) {
    let arrUtil: any = [];

    if (isRestaurant) {
      if (schedMod?.always_enabled?.value) {
        arrUtil = [
          ["2023-05-01 00:00", "2023-05-01 23:59"],
          ["2023-05-02 00:00", "2023-05-02 23:59"],
          ["2023-05-03 00:00", "2023-05-03 23:59"],
          ["2023-05-04 00:00", "2023-05-04 23:59"],
          ["2023-05-05 00:00", "2023-05-05 23:59"],
          ["2023-05-06 00:00", "2023-05-06 23:59"],
          ["2023-05-07 00:00", "2023-05-07 23:59"],
        ];
      } else {
        Object.keys(dayOfWeekR).forEach((day) => {
          if (schedMod?.days[day]) {
            schedMod.days[day].shifts.forEach((shift: any) => {
              arrUtil.push([mergeDateTime(shift.start_time, day), mergeDateTime(shift.end_time, day)]);
            });
          }
        });
      }
    } else {
      //

      Object.keys(dayOfWeekF).forEach((day) => {
        schedMod[day].forEach((shift: any) => {
          //
          arrUtil.push([`2023-05-0${dayOfWeekF[day]} ${("0" + shift.start.hour).slice(-2)}:${("0" + shift.start.minute).slice(-2)}`, `2023-05-0${dayOfWeekF[day]} ${("0" + shift.end.hour).slice(-2)}:${("0" + shift.end.minute).slice(-2)}`]);
        });
      });
    }

    return arrUtil;
  }

  function handleChange(e: any) {
    // setLastChange(e);
    if (schedule.toString() !== e.toString()) {
      //

      const shifsArr: any[] = [...e];

      let scheduleModelR: any = {
        days: {
          Monday: { shifts: [] },
          Tuesday: { shifts: [] },
          Wednesday: { shifts: [] },
          Thursday: { shifts: [] },
          Friday: { shifts: [] },
          Saturday: { shifts: [] },
          Sunday: { shifts: [] },
        },
        exceptions: prefill?.exceptions ? prefill?.exceptions : [],
        always_enabled: {
          value: false,
        },
      };
      let scheduleModelF: any = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };

      shifsArr.forEach((shift: any) => {
        if (isRestaurant) {
          const dayUtil = moment(shift[0]).format("dddd");

          scheduleModelR.days[dayUtil].shifts.push({
            start_time: moment(shift[0]).format("HH:mm"),
            end_time: moment(shift[1]).format("HH:mm"),
          });
        } else {
          const dayUtilF = moment(shift[0]).format("dddd").toLowerCase();
          //

          scheduleModelF[dayUtilF].push({
            start: { hour: moment(shift[0]).format("HH"), minute: moment(shift[0]).format("mm") },
            end: { hour: moment(shift[1]).format("HH"), minute: moment(shift[1]).format("mm") },
          });
        }
      });

      if (isRestaurant) {
        submit(scheduleModelR);
      } else {
        submit(scheduleModelF);
      }
    }
  }

  function transformShifts(schedule: any) {
    // Recorrer cada día en el objeto de días
    for (const day in schedule.days) {
      let shifts = schedule.days[day].shifts;
      // Filtrar y transformar los turnos
      schedule.days[day].shifts = shifts
        .filter((shift: any) => !(shift.start_time === "00:00" && shift.end_time === "00:00")) // Eliminar turnos vacíos
        .map((shift: any) => {
          if (shift.end_time === "00:00") {
            shift.end_time = "23:59"; // Cambiar "00:00" a "23:59"
          }
          return shift;
        });
    }
    return schedule; // Devolver el objeto de horario actualizado
  }

  useEffect(() => {
    if (prefill) {
      const scheduleConvertedToArrays = convertScheduleToArrays(transformShifts(prefill));

      setSchedule(scheduleConvertedToArrays);
    }
    return () => {
      setSchedule([]);
    };
  }, [prefill]);

  useEffect(() => {
    classes["day-column"] = classes["day-column"] + " dayColumm";
    classes["day-hours"] = classes["day-hours"] + " dayHours";
  }, []);
  return (
    <div className="schedulerContainer">
      <TimeGridScheduler
        verticalPrecision={5}
        classes={classes}
        originDate={new Date("2023-05-01")}
        schedule={schedule}
        className={`timeGridSchedulerStyle ${classes.cell}`}
        onChange={(e: any) => {
          handleChange(e);
        }}
        eventRootComponent={EventRoot}
      />

      <div className="controllers">
        <Button type="primary" ghost onClick={() => handleChange([])}>
          Clear schedule
        </Button>
        <span style={{ display: "flex", alignItems: "center" }}>
          Always on
          <Switch style={{ marginLeft: "5px" }} checked={schedule.toString() === alwaysOn.toString()} onChange={() => handleChange(alwaysOn)} />
        </span>
      </div>
    </div>
  );
};

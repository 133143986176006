/* eslint-disable */
/* eslint-disable import/no-unresolved */
import React from "react";
import AsideLogo from "src/components/aside-layout/logo-white";
import ResetPasswordContainer from "src/containers/reset-password";

import ContentBody from "../layouts/layout-02/content-body";

const ResetPassword: React.FC = () => {
  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "black",
          height: "9%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "stretch",
        }}
      >
        <AsideLogo />
      </div>
      {/* <div
                style={{
                    height: "91%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {" "}
                <div
                    style={{
                        height: "70%",
                    }}
                > */}
      {/* <ContentBody> */}
      <ResetPasswordContainer />
      {/* </ContentBody> */}
    </div>
    //     </div>
    // </div>
  );
};

export default ResetPassword;

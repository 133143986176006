/* eslint-disable */

import { Checkbox, Modal, RadioChangeEvent, Select } from "antd";
import SecondLine from "../../../../../components/src/static/second_line.svg";
import { Button, Input, Radio, Space, Steps, message, theme } from "antd";

import React, { FC, useContext, useEffect, useState } from "react";
import { SchedulerComp } from "src/components/inputs/scheduler";
import "../styles.css";

import movistarLogo from "../utils/tel-providers/movistar.png";
import orangeLogo from "../utils/tel-providers/orange.png";
import vodafoneLogo from "../utils/tel-providers/vodafone.png";
import whatsappGuide from "../utils/avatars-svg/whatsappGuide.png";
import whenAnswerAlways from "../utils/avatars-svg/when-answer-always.svg";
import whenAnswerfourTones from "../utils/avatars-svg/when-answer-fourTones.svg";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";

interface IProps {
  prefill?: any;
  handleNext?: any;
  handleBack?: any;
  handleChange?: any;
}

export const RestChannel0: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("assistant_type", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-channel-0", null);
    // }, 700);
  };
  useEffect(() => {
    setValue(prefill?.assistant_type);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Qué tipo de asistente quieres?</h2>
      <p className="pUnderTitle">Te guiaremos a través de cada paso del proceso</p>
      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Radio value="voice">Teléfono</Radio>
          <Radio value="whatsapp">WhatsApp</Radio>
          <Radio value="both">Ambos</Radio>
        </Space>
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.assistant_type} onClick={() => handleNext("rest-channel-0", null)}></Button>
        </div>
      </div>
    </div>
  );
};

export const RestChannel1: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("tel_to_use", e.target.value);
  };
  useEffect(() => {
    if (!prefill?.tel_to_use && prefill?.google?.formatted_phone_number) {
      setValue("google");
    } else setValue(prefill?.tel_to_use);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Qué teléfono quieres utilizar?</h2>
      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          {prefill?.google?.formatted_phone_number && (
            <Radio value="google">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{prefill?.google?.formatted_phone_number}</div>
                <p style={{ fontSize: "13px", fontStyle: "italic" }}>Obtenido de Google Maps</p>
              </div>
            </Radio>
          )}
          <Radio value="other">Un número del que ya dispongo</Radio>
          {value === "other" ? <Input className="otherRadioInput" placeholder="Dinos el numero de telefono" value={prefill?.other_tel} onChange={(e: any) => handleChange("other_tel", e.target.value)} /> : null}
          <Radio value="new">
            <div style={{ display: "flex", flexDirection: "column" }}>
              Quiero uno nuevo
              <p style={{ fontSize: "13px", fontStyle: "italic" }}>No tiene coste añadido, te daremos un número nuevo con el prefijo de tu ciudad</p>
            </div>
          </Radio>

          {value === "new" ? <Input className="otherRadioInput" placeholder="Dinos el prefijo que quieres para tu teléfono" value={prefill?.new_tel_prefix} onChange={(e: any) => handleChange("new_tel_prefix", e.target.value)} /> : null}
        </Space>
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={value === "" || (prefill?.tel_to_use === "other" && !prefill?.other_tel) || (prefill?.tel_to_use === "new" && !prefill?.new_tel_prefix)} onClick={() => handleNext("rest-channel-1", "rest-channel-2")}>
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestChannel2: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const [valueTel, setValueTel] = useState("");
  const [showCustomCentralita, setShowCustomCentralita] = useState(false);
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("has_centralita", e.target.value);
  };

  const onChangeCentr = (e: any) => {
    console.log("centralita provider", e);

    handleChange("centralita_provider", e);
  };
  const onChangeCentr1 = (e: any) => {
    console.log("search", e);
  };
  const onChangeRadioTel = (e: RadioChangeEvent) => {
    handleChange("tel_provider", e.target.value);
  };

  const knownCentralitas = [
    { label: "Praytel", value: "Praytel" },
    { label: "Evertel", value: "Evertel" },
    { label: "Sotroni", value: "Sotroni" },
    { label: "Voiper", value: "Voiper" },
    { label: "Telefonica", value: "Telefonica" },
    { label: "Soltecon", value: "Soltecon" },
    { label: "Teleconcept", value: "Teleconcept" },
    { label: "Numeros Imaginarios", value: "Numeros Imaginarios" },
    { label: "Electronica Martinez", value: "Electronica Martinez" },
    { label: "R Comunicaciones", value: "R Comunicaciones" },
    { label: "Nexo Telecom", value: "Nexo Telecom" },
    { label: "Fonemia Vodafone", value: "Fonemia Vodafone" },
    { label: "Premium number", value: "Premium number" },
    { label: "Voz Telecom", value: "Voz Telecom" },
    { label: "Voz", value: "Voz" },
    { label: "Fon Virtual", value: "Fon Virtual" },
    { label: "Neotel", value: "Neotel" },
    { label: "Ene Soluciones", value: "Ene Soluciones" },
    { label: "Telmar", value: "Telmar" },
    { label: "DeteinGroup", value: "DeteinGroup" },
    { label: "Soditel", value: "Soditel" },
    { label: "Mediterrani Telecom", value: "Mediterrani Telecom" },
    { label: "Folgatel 1998 SL", value: "Folgatel 1998 SL" },
    { label: "Rioja Telecom", value: "Rioja Telecom" },
    { label: "SmartGroup", value: "SmartGroup" },
    { label: "INNUBO", value: "INNUBO" },
    { label: "Antenas en Guadalajara", value: "Antenas en Guadalajara" },
    { label: "VILMA GRUP", value: "VILMA GRUP" },
    { label: "Asnet", value: "Asnet" },
    { label: "TELEMIRA", value: "TELEMIRA" },
    { label: "PBX Comunicacions 2011", value: "PBX Comunicacions 2011" },
    { label: "Midgard", value: "Midgard" },
    { label: "GTV Comunicaciones", value: "GTV Comunicaciones" },
    { label: "Cridatel", value: "Cridatel" },
    { label: "Tictel Grup", value: "Tictel Grup" },
    { label: "Cylex", value: "Cylex" },
    { label: "Panafonic", value: "Panafonic" },
    { label: "Electrónica Tres Torres S.L.", value: "Electrónica Tres Torres S.L." },
    { label: "Hiper Usera", value: "Hiper Usera" },
    { label: "Ricardo Vega Rodríguez", value: "Ricardo Vega Rodríguez" },
    { label: "MÒBIL TECHNICS", value: "MÒBIL TECHNICS" },
    { label: "Movildata", value: "Movildata" },
    { label: "Sozias", value: "Sozias" },
    { label: "ADT Alarmas", value: "ADT Alarmas" },
    { label: "Onedirect", value: "Onedirect" },
    { label: "BMV Connect", value: "BMV Connect" },
    { label: "Project Telecom", value: "Project Telecom" },
    { label: "ICOM SPAIN", value: "ICOM SPAIN" },
    { label: "TuriaTEL", value: "TuriaTEL" },
    { label: "Grupo Symsa", value: "Grupo Symsa" },
    { label: "Informática y Telefonía 2004 S.L", value: "Informática y Telefonía 2004 S.L" },
    { label: "Isp Esp Comunicacions S L", value: "Isp Esp Comunicacions S L" },
    { label: "INW Comunicacions", value: "INW Comunicacions" },
    { label: "Zebratec", value: "Zebratec" },
    { label: "LOVATEL", value: "LOVATEL" },
    { label: "Telecat", value: "Telecat" },
    { label: "Grupo Telecon", value: "Grupo Telecon" },
    { label: "Allcom", value: "Allcom" },
    { label: "Infotelecom", value: "Infotelecom" },
    { label: "Euromadrid Telecom", value: "Euromadrid Telecom" },
    { label: "RubiCom", value: "RubiCom" },
    { label: "Electrotel", value: "Electrotel" },
    { label: "Thot IP Comunicacions", value: "Thot IP Comunicacions" },
    { label: "Areavoip", value: "Areavoip" },
    { label: "Pana Ret Telefonía", value: "Pana Ret Telefonía" },
    { label: "Instec Telecom", value: "Instec Telecom" },
    { label: "GATCOM", value: "GATCOM" },
    { label: "TECICOM", value: "TECICOM" },
    { label: "Isip", value: "Isip" },
    { label: "Netoptima", value: "Netoptima" },
    { label: "Criteria Sistemas", value: "Criteria Sistemas" },
    { label: "KAREL", value: "KAREL" },
    { label: "Insnet", value: "Insnet" },
    { label: "RIM Telecomunicaciones", value: "RIM Telecomunicaciones" },
    { label: "TU CENTRALITA IP", value: "TU CENTRALITA IP" },
    { label: "Técnicas Profesionales", value: "Técnicas Profesionales" },
    { label: "TELECOMPC", value: "TELECOMPC" },
    { label: "Gigavoz", value: "Gigavoz" },
  ];

  useEffect(() => {
    setValue(prefill?.has_centralita);
    setValueTel(prefill?.tel_provider);
  }, [prefill]);

  const handleButtonClick = (option: string) => {
    setValueTel(option);
    handleChange("tel_provider", option);
  };

  return (
    <div>
      <h2 className="businessH2">
        {t("ask_centralita")}
        {/* ¿Tienes centralita? */}
      </h2>
      <p className="pUnderTitle">
        {t("ask_centralita2")}
        {/* Indica si cuentas con un sistema de comunicación integrado, en caso contrario indica tu compañía telefónica */}
      </p>
      <div>
        <Radio.Group className="radioGroupStyle hasCentralitaRadio" onChange={onChangeRadio} value={value}>
          <Space className="hasCentralita" direction="vertical">
            <Radio value="no">{t("no")}</Radio>
            <Radio style={{ width: "500px" }} value="idk">
              {t("idk")}
            </Radio>
            <Radio value="yes">{t("yes")}</Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className="doesHaveCentralita">
        {value === "yes" && (
          <Space className="spaceMargin" direction="vertical">
            <Select
              className="yesCentralita"
              // style={{ width: "400px", height: "" }}
              showSearch
              allowClear
              defaultValue={prefill?.centralita_provider}
              placeholder={t("ask_centralita_provider")}
              optionFilterProp="children"
              onChange={onChangeCentr}
              onSearch={onChangeCentr1}
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              options={knownCentralitas}
            />

            <p
              className="notInList"
              style={{ float: "right" }}
              onClick={() => {
                setShowCustomCentralita(!showCustomCentralita);
              }}
            >
              {t("cant_find")}
              {/* ¿No lo encuentras? */}
            </p>
            {(prefill?.centralita_provider_custom || showCustomCentralita) && (
              <Input placeholder="Dinos que proveedor tienes" value={prefill?.centralita_provider_custom} onChange={(e: any) => handleChange("centralita_provider_custom", e.target.value)} />
            )}
          </Space>
        )}
      </div>
      {(value === "no" || value === "idk") && (
        <div>
          <div className="phoneCompanies">
            <div>
              <h5>
                {t("ask_phone_provider")}
                {/* Selecciona tu proveedor de telefonía */}
              </h5>
            </div>
            <div className="divButtonGroup">
              <Space wrap size={16}>
                <Button className={`buttonTel ${valueTel === "movistar" ? "valueTelSelected" : ""}`} value="movistar" onClick={() => handleButtonClick("movistar")}>
                  <img src={movistarLogo} alt="movistar" />
                </Button>
                <Button className={`buttonTel ${valueTel === "vodafone" ? "valueTelSelected" : ""}`} value="vodafone" onClick={() => handleButtonClick("vodafone")}>
                  <img src={vodafoneLogo} alt="vodafone" />
                </Button>
                <Button className={`buttonTel ${valueTel === "orange" ? "valueTelSelected" : ""}`} value="orange" onClick={() => handleButtonClick("orange")}>
                  <img src={orangeLogo} alt="orange" />
                </Button>
                <Button className={`buttonTel ${valueTel === "idk" ? "valueTelSelected" : ""}`} value="idk" onClick={() => handleButtonClick("idk")}>
                  <div>No lo sé</div>
                </Button>
                <Button className={`buttonTel ${valueTel === "other" ? "valueTelSelected" : ""}`} value="other" onClick={() => handleButtonClick("other")}>
                  <div>Otro</div>
                </Button>
                {valueTel === "other" ? <Input className="otherRadioInput" placeholder="Dinos que proveedor tienes" value={prefill?.tel_provider_custom} onChange={(e: any) => handleChange("tel_provider_custom", e.target.value)} /> : null}
              </Space>
            </div>
          </div>
        </div>
      )}

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/     2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button
            type="primary"
            disabled={
              !prefill?.has_centralita ||
              (prefill?.has_centralita === "yes" && !prefill?.centralita_provider && !prefill?.centralita_provider_custom) ||
              (prefill?.has_centralita !== "yes" && prefill?.tel_provider === "other" && !prefill?.tel_provider_custom)
            }
            onClick={() => handleNext("rest-channel-2", "rest-channel-3")}
          >
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestChannel3: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  let scheduleModelR: any = {
    days: {
      Monday: { shifts: [] },
      Tuesday: { shifts: [] },
      Wednesday: { shifts: [] },
      Thursday: { shifts: [] },
      Friday: { shifts: [] },
      Saturday: { shifts: [] },
      Sunday: { shifts: [] },
    },
    exceptions: [],
    always_enabled: {
      value: false,
    },
  };
  const [schedule, setSchedule] = useState(scheduleModelR);

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("when_answer", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-channel-3", "rest-channel-4");
    // }, 700);
  };
  useEffect(() => {
    setValue(prefill?.when_answer);
    setSchedule(prefill?.when_answer_schedule);
  }, [prefill]);

  return (
    <>
      <div>
        <div>
          <h2 className="businessH2">
            {t("when_answer")}
            {/* ¿Cuando quieres que salte el asistente? */}
          </h2>
        </div>
        <div>
          <div className="contentContainer">
            <div className="RadioAndImg answerColumn">
              <Radio.Group className="radioGroupStyle" style={{ width: "50%" }} onChange={onChangeRadio} value={value}>
                <div className="stringWhenToAnswer">
                  <Space direction="vertical">
                    {value === "always" && (
                      <h6 className="otherRadioInput displayNone">
                        {t("ask_2nd_line")}
                        {/* Por favor indíquenos una segunda línea para transferir la llamada al restaurante. En caso de no contar con una, deje el casillero en blanco y un agente le contactará luego para ofrecerle una solucíon a éste tema. */}
                      </h6>
                    )}
                    {value === "always" && (
                      <div className="displayNone">
                        <PhoneInput className="phone" style={{ fontSize: "25px" }} placeholder={t("phone_placeholder")} defaultCountry="ES" value={prefill?.handover_phone} onChange={(e: any) => handleChange("handover_phone", e)} />{" "}
                      </div>
                    )}
                    <Radio value="always">{t("always")}</Radio>
                    <Radio value="when-they-not-answer" className="whenNoAnswer">
                      {t("if_no_answer")}
                      {/* Si no contestáis en el restaurante */}
                    </Radio>

                    {prefill?.has_centralita === "yes" && prefill?.advanced === true && <Radio value="scheduled">Franjas horarias</Radio>}
                    {value === "scheduled" && (
                      <h6 className="otherRadioInput">
                        {t("ask_2nd_line")}

                        {/* Por favor indíquenos una segunda línea para transferir la llamada al restaurante. En caso de no contar con una, deje el casillero en blanco y un agente le contactará luego para ofrecerle una solucíon a éste tema. */}
                      </h6>
                    )}
                    {value === "scheduled" && (
                      <div>
                        <PhoneInput className="phone" style={{ fontSize: "25px" }} placeholder={t("phone_placeholder")} defaultCountry="ES" value={prefill?.handover_phone} onChange={(e: any) => handleChange("handover_phone", e)} />{" "}
                      </div>
                    )}
                  </Space>
                </div>
              </Radio.Group>
              <div className="whenToAnswer" style={{ width: "50%" }}>
                <div style={{ width: "100vw" }}>
                  {value === "always" && <img src={whenAnswerAlways} alt="img" />}
                  {value === "when-they-not-answer" && <img src={whenAnswerfourTones} alt="img" />}
                </div>
              </div>
            </div>
            {prefill?.when_answer === "scheduled" && (
              <SchedulerComp
                prefill={schedule}
                submit={(e: any) => {
                  handleChange("when_answer_schedule", e);
                  console.log("SCHEDULE CHANGE", e);
                }}
                isRestaurant
              />
            )}
          </div>
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.when_answer} onClick={() => handleNext("rest-channel-3", null)}>
            {t("next")}
          </Button>
        </div>
      </div>
    </>
  );
};
export const RestChannel4: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  let scheduleModelR: any = {
    days: {
      Monday: { shifts: [] },
      Tuesday: { shifts: [] },
      Wednesday: { shifts: [] },
      Thursday: { shifts: [] },
      Friday: { shifts: [] },
      Saturday: { shifts: [] },
      Sunday: { shifts: [] },
    },
    exceptions: [],
    always_enabled: {
      value: false,
    },
  };
  const [schedule, setSchedule] = useState(scheduleModelR);

  function convertSchedule(mapsSchedule: any) {
    const dayIntMap = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const days: any = {
      Sunday: { shifts: [] },
      Monday: { shifts: [] },
      Tuesday: { shifts: [] },
      Wednesday: { shifts: [] },
      Thursday: { shifts: [] },
      Friday: { shifts: [] },
      Saturday: { shifts: [] },
    };
    // console.log(mapsSchedule.periods);
    const shifts = mapsSchedule.periods.map((el: any) => {
      let end_time = "23:59";
      if (el.close !== undefined) {
        if (el.close.time != "0000") {
          end_time = el.close.time.slice(0, 2) + ":" + el.close.time.slice(2, 4);
        }
      }
      return {
        day: el.open.day,
        shift: {
          start_time: el.open.time.slice(0, 2) + ":" + el.open.time.slice(2, 4),
          end_time: end_time,
        },
      };
    });
    for (const shift of shifts) {
      const dayStr = dayIntMap[shift.day];
      days[dayStr].shifts.push(shift.shift);
    }
    for (const day in days) {
      if (days[day].shifts.length === 0) {
        days[day].shifts.push({ start_time: "", end_time: "" });
      }
    }

    console.log("Schedule converted", days);

    return days;
  }
  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("when_handover", e.target.value);
  };
  useEffect(() => {
    setValue(prefill?.when_handover);
    if (prefill?.when_handover_schedule) {
      setSchedule(prefill?.when_handover_schedule);
    } else if (prefill?.google?.opening_hours) {
      setSchedule({
        days: convertSchedule(prefill?.google?.opening_hours),
        exceptions: [],
        always_enabled: {
          value: false,
        },
      });
    }
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Si el cliente lo pide, cuándo quieres que se pasen las llamadas al restaurante?</h2>
      <p className="pUnderTitle">Si no dispone de una centralita, el número al que transferiremos la llamada debe ser distinto al del restaurante</p>
      <div className="RadioAndImg">
        <div className="OnlyRadioBlock">
          <Radio.Group className="radioGroupStyle" style={{ marginBottom: "20px" }} onChange={onChangeRadio} value={value}>
            <Space direction="vertical">
              <Radio value="never">No pasar llamadas al restaurante</Radio>
              <Radio value="always">Sí, siempre</Radio>
              <Radio value="scheduled">Sí, en franjas horarias</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="OnlyImg" style={{ marginBottom: "50px" }}>
          <img className="secondLineImg" src={SecondLine} alt="SecondLineExplained" />
        </div>
      </div>

      {prefill?.when_handover === "scheduled" && (
        <SchedulerComp
          prefill={schedule}
          submit={(e: any) => {
            handleChange("when_handover_schedule", e);
            console.log("SCHEDULE CHANGE", e);
          }}
          isRestaurant
        />
      )}
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.   org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.when_handover} onClick={() => handleNext("rest-channel-4", null)}>
            {/* <Button type="primary" className="nextButton" disabled={!prefill?.when_handover} onClick={() => handleNext("rest-channel-4", "rest-channel-5")}> */}
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestChannel5: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="businessH2">ACA VA EL SUMARIO DE CHANNEL</h2>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/ 2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" onClick={() => handleNext("rest-channel-5", null)}>
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestChannel6: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("same_number", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-channel-6", "rest-channel-1");
    // }, 700);
  };
  useEffect(() => {
    setValue(prefill?.same_number);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Quieres usar el mismo número para WhatsApp y voice?</h2>
      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Radio value="yes">Sí</Radio>
          <Radio value="no">No</Radio>
        </Space>
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/ 2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" onClick={() => handleNext("rest-channel-6", "rest-channel-2")}>
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

// export const RestChannel7: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
//   const [hasWhatsapp, setHasWhatsapp] = useState("");
//   const [canCalls, setCanCalls] = useState("");
//   const [canSMS, setCanSMS] = useState("");
//   const [whatsappType, setWhatsappType] = useState("");
//   const [setupNow, setSetupNow] = useState("");
//   const [whatsappProvider, setWhatsappProvider] = useState("");

//   const onChangeRadio = (key: string, e: RadioChangeEvent) => {
//     handleChange(key, e.target.value);
//   };
//   useEffect(() => {
//     setHasWhatsapp(prefill?.have_whatsapp);
//     setCanCalls(prefill?.can_receive_calls);
//     setCanSMS(prefill?.can_receive_sms);
//     setWhatsappType(prefill?.whatsapp_type);
//     setSetupNow(prefill?.setup_now);
//     setWhatsappProvider(prefill?.whatsapp_provider);
//   }, [prefill]);

//   return (
//     <div>
//       {prefill?.assistant_type === "both" ? <h2 className="businessH2">Ahora configuremos WhatsApp. Tienes cuenta de whatsapp?</h2> : <h2 className="businessH2">Tienes cuenta de whatsapp?</h2>}
//       <Radio.Group className="radioGroupStyle" onChange={(e) => onChangeRadio("have_whatsapp", e)} value={hasWhatsapp}>
//         <Space direction="vertical">
//           <Radio value="yes">Si</Radio>
//           <Radio value="no">No</Radio>
//         </Space>
//       </Radio.Group>

//       {prefill?.have_whatsapp === "no" && (
//         <div>
//           <h2 className="businessH2">Puedes recibir llamadas?</h2>
//           <Radio.Group className="radioGroupStyle" onChange={(e) => onChangeRadio("can_receive_calls", e)} value={canCalls}>
//             <Space direction="vertical">
//               <Radio value="yes">Si</Radio>
//               <Radio value="no">No</Radio>
//             </Space>
//           </Radio.Group>
//         </div>
//       )}
//       {prefill?.have_whatsapp === "no" && prefill?.can_receive_calls === "no" && (
//         <div>
//           <h2 className="businessH2">Puedes recibir sms?</h2>
//           <Radio.Group className="radioGroupStyle" onChange={(e) => onChangeRadio("can_receive_sms", e)} value={canSMS}>
//             <Space direction="vertical">
//               <Radio value="yes">Si</Radio>
//               <Radio value="no">No</Radio>
//             </Space>
//           </Radio.Group>
//         </div>
//       )}

//       {prefill?.have_whatsapp === "no" && prefill?.can_receive_calls === "no" && prefill?.can_receive_sms === "no" && <div>Uno de nuestros agentes de atencion al cliente se comunicará contigo para completar esta parte del proceso.</div>}

//       {prefill?.have_whatsapp === "yes" && (
//         <div>
//           <h2 className="businessH2">Que tipo de whatsapp tienes?</h2>
//           <Radio.Group className="radioGroupStyle" onChange={(e) => onChangeRadio("whatsapp_type", e)} value={whatsappType}>
//             <Space direction="vertical">
//               <Radio value="default">Default</Radio>
//               <Radio value="business">Business</Radio>
//               <Radio value="api">API</Radio>
//             </Space>
//           </Radio.Group>
//         </div>
//       )}
//       {prefill?.have_whatsapp === "yes" && (prefill?.whatsapp_type === "default" || prefill?.whatsapp_type === "business") && (
//         <div>
//           <h2 className="businessH2">Remove from business or personal, move to API, instructions. Want to do it now?</h2>
//           <Radio.Group className="radioGroupStyle" onChange={(e) => onChangeRadio("setup_now", e)} value={setupNow}>
//             <Space direction="vertical">
//               <Radio value="yes">Si</Radio>
//               <Radio value="no">No</Radio>
//             </Space>
//           </Radio.Group>
//         </div>
//       )}
//       {prefill?.have_whatsapp === "yes" && (prefill?.whatsapp_type === "default" || prefill?.whatsapp_type === "business") && prefill?.setup_now === "yes" && (
//         <div>
//           <h2 className="businessH2">Instructions (if at any point you get lost, contact us)</h2>
//         </div>
//       )}
//       {prefill?.have_whatsapp === "yes" && (prefill?.whatsapp_type === "default" || prefill?.whatsapp_type === "business") && prefill?.setup_now === "no" && (
//         <div>
//           <h2 className="businessH2">Don’t forget to do it later, otherwise we won’t be able to deploy assistant</h2>
//         </div>
//       )}
//       {prefill?.have_whatsapp === "yes" && prefill?.whatsapp_type === "api" && (
//         <div>
//           <h2 className="businessH2">Which provider?</h2>
//           <Radio.Group className="radioGroupStyle" onChange={(e) => onChangeRadio("whatsapp_provider", e)} value={whatsappProvider}>
//             <Space direction="vertical">
//               <Radio value="360d">360-Dialog</Radio>
//               <Radio value="cloudAPI">Cloud API</Radio>
//               <Radio value="other">
//                 Otra
//                 {whatsappProvider === "other" && (
//                   <div>
//                     <Input className="otherRadioInput" placeholder="Dinos que proveedor tienes" defaultValue={prefill?.other_whatsapp_provider} onBlur={(e: any) => handleChange("other_whatsapp_provider", e.target.value)} />
//                     <h5>One of our customer support agents will assist you</h5>
//                   </div>
//                 )}
//               </Radio>
//             </Space>
//           </Radio.Group>
//         </div>
//       )}

//       <div>
//         <svg className="backButton" onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
//           <path
//             d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
//             fill="#333F64"
//             stroke="#333F64"
//             strokeWidth="1.15217"
//           />
//           <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
//         </svg>
//         <Button type="primary" className="nextButton" onClick={() => handleNext("rest-channel-7", "rest-channel-10")}>
//           Next
//         </Button>
//       </div>
//     </div>
//   );
// };

// export const RestChannel8: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
//   const [value, setValue] = useState("");

//   const onChangeRadio = (e: RadioChangeEvent) => {
//     handleChange("whatsapp_to_use", e.target.value);
//   };
//   useEffect(() => {
//     setValue(prefill?.whatsapp_to_use);
//   }, [prefill]);

//   return (
//     <div>
//       <h2 className="businessH2">Now let’s set up Whatsapp. Would you like to use an existing number or a new one for WApp?</h2>
//       <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
//         <Space direction="vertical">
//           <Radio value="existing">Existing</Radio>
//           <Radio value="new">
//             Uno nuevo
//             {value === "new" && <Input className="otherRadioInput" placeholder="Dinos el prefijo que quieres para tu whatsapp" defaultValue={prefill?.new_whatsapp_prefix} onBlur={(e: any) => handleChange("new_whatsapp_prefix", e.target.value)} />}
//           </Radio>
//         </Space>
//       </Radio.Group>

//       <div>
//         <svg className="backButton" onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
//           <path
//             d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
//             fill="#333F64"
//             stroke="#333F64"
//             strokeWidth="1.15217"
//           />
//           <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
//         </svg>
//         <Button type="primary" className="nextButton" onClick={() => handleNext("rest-channel-8", null)}>
//           Next
//         </Button>
//       </div>
//     </div>
//   );
// };

export const RestChannel10: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="businessH2" style={{ marginBottom: "30px" }}>
        Puedes configurar tu asistente de WhatsApp al final del onboarding. Encuéntralo en la sección de <strong>marketplace</strong>.
      </h2>
      <div className="imgwhatsapp">
        <img src={whatsappGuide} alt="whatsapp guide image" />
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/ 2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" onClick={() => handleNext("rest-channel-10", null)}>
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

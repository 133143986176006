/* eslint-disable */
import { FC } from "react";
import { Button } from "@doar/components";
import image from "../../../public/customer-support.png";
import {
    StyledWrap,
    StyledImg,
    StyledTitle,
    StyledSubTitle,
    StyledDesc,
    StyledBtnWrap,
    StyledNote,
} from "./style";

const ThankYouContainer: FC = () => {
    return (
        <StyledWrap>
            <StyledImg>
                <img src={image} alt="500" />
            </StyledImg>
            <StyledTitle>¡Gracias por registrarte!</StyledTitle>
            <StyledSubTitle>
                Uno de nuestros agentes se contactará contigo para finalizar el
                proceso de alta.
            </StyledSubTitle>
            <StyledNote>
                En caso que necesites contactarte con nosotros, por favor hazlo
                al{" "}
                <a
                    href="tel:+34933803348"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    933 803 348
                </a>
            </StyledNote>
        </StyledWrap>
    );
};

export default ThankYouContainer;

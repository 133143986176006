import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IMenu } from "@doar/shared/types";
import cn from "classnames";
import { StyledNavbar, StyledNavitem, StyledNavlink } from "./style";
import { ShowSidebarContext } from "@doar/main/src/config/userContext";
import "./styles.css";

interface IProps {
  menus: IMenu[];
}

const NavbarAside: React.FC<IProps> = ({ menus }) => {
  const location = useLocation();
  const { showSidebar, showSidebarFixed } = useContext(ShowSidebarContext);
  const [show, setShow] = useState(false);

  // const clickHandler = (e: React.MouseEvent, hasChildren: boolean) => {
  //   if (hasChildren) {
  //     e.preventDefault();
  //     let target = e.currentTarget as HTMLElement;
  //     if (target.nodeName === "A") {
  //       target = target.parentElement as HTMLElement;
  //     }
  //     const submenu = target.querySelector(".mega-submenu");

  //     const siblings = getSiblings(target);
  //     submenu?.classList.toggle("open");
  //     target.classList.toggle("open");
  //     siblings.forEach((sib) => {
  //       sib.classList.remove("open");
  //       sib.childNodes.forEach((child) => {
  //         const childHT = child as HTMLElement;
  //         childHT?.classList?.remove("open");
  //       });
  //     });
  //   }
  // };

  function checkSubPath() {
    switch (location.pathname) {
      case "/conversations":
      case "/chats":
      case "/requests":
      case "/surveys":
      case "/live-calls":
        return true;
      default:
        return false;
    }
  }

  useEffect(() => {
    setShow(checkSubPath());
  }, []);

  // useEffect(() => {
  //   console.log("showSidebar", showSidebar);
  //   console.log("showSidebarFixed", showSidebarFixed);
  // }, [showSidebar, showSidebarFixed]);

  return (
    <StyledNavbar className="aside-navbar">
      {menus?.map((nav: IMenu) => {
        const { submenu, megamenu, id: navId, label: navLabel, Icon, url } = nav;
        const hasSubmenu = !!submenu?.length;
        const hasMegamenu = !!megamenu?.length;
        const hasChildren = hasSubmenu || hasMegamenu;
        // console.log("PATH >>>>", location.pathname);
        // console.log("NAV >>>>", nav.url);

        function handleSubMenu() {
          if (hasSubmenu) {
            setShow(!show);
          }
        }
        return (
          <StyledNavitem $expanded={showSidebar == showSidebarFixed} $bottom={url === "/settings"} $bottom2={url === "/preferences"} key={navId}>
            <StyledNavlink
              $expanded={showSidebar == showSidebarFixed}
              onClick={(e: any) => {
                hasChildren && e.preventDefault();
                handleSubMenu();
              }}
              path={nav.url}
              // path={hasChildren ? "#!" : nav.url}
              className={cn((nav.url !== "/" && location.pathname.startsWith(nav.url)) || (hasSubmenu && checkSubPath()) ? "active" : "", "aside-navbar-link", nav.url == "/" && location.pathname == "/" ? "active" : "")}
            >
              {Icon && (
                <Icon
                  className="svg-icon"
                  color={(nav.url !== "/" && location.pathname.startsWith(nav.url) && location.pathname.includes(nav.url)) || (hasSubmenu && checkSubPath()) || (nav.url == "/" && location.pathname == "/") ? "#5479F7" : "#718096"}
                />
              )}
              {/* {Icon && showSidebar != showSidebarFixed && <Icon size={200} className="svg-icon" color={location.pathname.includes(nav.url) ? "#5479F7" : "#718096"} />} */}
              {showSidebar == showSidebarFixed && <div style={{ marginLeft: "20px", height: "20px" }}>{navLabel}</div>}
              {showSidebar != showSidebarFixed && <div style={{ height: "20px" }}></div>}
              {hasSubmenu && (
                <span style={{ marginLeft: "70px", display: "flex", alignItems: "baseline" }}>
                  {!show && (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="vuesax/outline/arrow-down">
                        <g id="arrow-down">
                          <path
                            id="Vector"
                            d="M7.40744 9.62856C6.97534 9.62856 6.54324 9.48071 6.21608 9.19047L2.19139 5.61999C2.01238 5.46118 2.01238 5.19833 2.19139 5.03952C2.3704 4.88071 2.6667 4.88071 2.84571 5.03952L6.8704 8.60999C7.1667 8.87285 7.64818 8.87285 7.94448 8.60999L11.9692 5.03952C12.1482 4.88071 12.4445 4.88071 12.6235 5.03952C12.8025 5.19833 12.8025 5.46118 12.6235 5.61999L8.5988 9.19047C8.27164 9.48071 7.83954 9.62856 7.40744 9.62856Z"
                            fill="#718096"
                          />
                        </g>
                      </g>
                    </svg>
                  )}
                  {show && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M7.40744 4.37144C6.97534 4.37144 6.54324 4.51929 6.21608 4.80953L2.19139 8.38001C2.01238 8.53882 2.01238 8.80167 2.19139 8.96048C2.3704 9.11929 2.6667 9.11929 2.84571 8.96048L6.8704 5.39001C7.1667 5.12715 7.64818 5.12715 7.94448 5.39001L11.9692 8.96048C12.1482 9.11929 12.4445 9.11929 12.6235 8.96048C12.8025 8.80167 12.8025 8.53882 12.6235 8.38001L8.5988 4.80953C8.27164 4.51929 7.83954 4.37144 7.40744 4.37144Z"
                        fill="#718096"
                      />
                    </svg>
                  )}
                </span>
              )}
            </StyledNavlink>
            {hasSubmenu && show && showSidebar == showSidebarFixed && (
              <div className={hasSubmenu && checkSubPath() && showSidebar == showSidebarFixed ? "showSubmenu" : "showSubmenuInactive"}>
                {submenu?.map(({ id, label, url, Icon }) => (
                  <StyledNavitem $expanded={showSidebar == showSidebarFixed} $show={show} $submenu={true} key={id}>
                    <StyledNavlink $expanded={showSidebar == showSidebarFixed} $show={show} $submenu={true} path={url} className={cn(location.pathname === url ? "active" : "", "aside-navbar-link")}>
                      {Icon && <Icon className="svg-icon" color={location.pathname.includes(nav.url) ? "#5479F7" : "#718096"} />}
                      {showSidebar == showSidebarFixed && <div style={{ marginLeft: "20px", height: "20px" }}>{label}</div>}
                      {showSidebar != showSidebarFixed && <div style={{ height: "20px" }}></div>}
                    </StyledNavlink>
                  </StyledNavitem>
                ))}
              </div>
            )}
          </StyledNavitem>
        );
      })}
    </StyledNavbar>
  );
};

NavbarAside.defaultProps = {
  menus: [],
};

export default NavbarAside;

/* eslint-disable */
/* eslint-disable import/no-unresolved */
import React from "react";
import ThankYouContainer from "src/containers/thank-you";

import logo from "@doar/shared/images/bookline_v7_transparent_white.png";


const ThankYou: React.FC = () => {
    return (
        <div style={{ height: "100vh" }}>
            <div
                style={{
                    backgroundColor: "black",
                    height: "9%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "stretch",
                }}
            >
                <img
                    style={{ marginLeft: "20px" }}
                    height="45px"
                    src={logo}
                    alt="Bookline Solutions SL logo"
                />
            </div>
            <div
                style={{
                    height: "91%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {" "}
                <div
                    style={{
                        height: "70%",
                    }}
                >
                    <ThankYouContainer />
                </div>
            </div>
        </div>
    );
};

export default ThankYou;

/* eslint-disable */
import styled, { device, themeGet } from "@doar/shared/styled";

export const StyledWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: flex-start;
`;

export const StyledImg = styled.div`
    margin-bottom: 15px;
    width: 500px;
    margin-right: 50px;
`;

export const StyledTitle = styled.h4`
    font-size: 20px;
    ${device.small} {
        font-size: 24px;
    }
`;

export const StyledDesc = styled.p`
    color: ${themeGet("colors.text3")};
    margin-bottom: 40px;
`;

export const StyledResetForm = styled.div`
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    ${device.small} {
        flex-direction: row;
    }
    input {
        flex: 1 0 auto;
    }
`;

export const StyledNote = styled.span`
    color: ${themeGet("colors.text3")};
    font-size: 12px;
`;
